import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import axios from "axios";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import white from "../../../Assets/Images/whitebg.jpg";
import CancelIcon from "@mui/icons-material/Cancel";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";
import "../Ratings/rc_chat.css";
import Swal from "sweetalert2";
import { url } from "../../../Config";
import { decryptParams } from '../encrypt_url';
import { useLocation } from 'react-router-dom';
import {encryptParams} from "../encrypt_url";
import SendIcon from '@mui/icons-material/Send';
import { Send } from "@material-ui/icons";


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    height: '80vh'
  },
  headBG: {
      backgroundColor: '#e0e0e0'
  },
  borderRight500: {
      borderRight: '1px solid #e0e0e0'
  },
  messageArea: {
    height: '70vh',
    overflowY: 'auto'
  }
});

export default function RC(props) {

  const classes = useStyles();
  const targetRef = useRef(null);

  const navigate = useNavigate();
  const [tasks, setTasks] = useState([{ id: uuidv4() }]);
  const id = localStorage.getItem("userID");
  const username = localStorage.getItem("username");
  const location = useLocation();
  const pathname = location.pathname;
  const encryptedParams = pathname.substring('/Ratings/addTask/RC/'.length);
  // Decrypt the parameters
  const { client_id, og_rating_id } = decryptParams(encryptedParams);
  const isadmin = localStorage.getItem('admin');

  const [data, setdata] = useState({});
  const [getsubtask, setGetsubtask] = useState([]);
  const [subtaskload, getsubtaskload] = useState("loading");
  const [editfile, seteditfiles] = useState([]);
  const [editview, seteditview] = useState(false);
  const [file, setfiles] = useState([]);
  const [begin, setbegin] = useState("starting");
  const [title, settitle] = useState([]);
  const [date, setdate] = useState([]);
  const [alertopen, setalertOpen] = useState(false);
  const [del, setdel] = useState(false);
  const [alertType, setalertType] = useState();
  const [AlertMessage, setalertmessage] = useState();
  const [taskinc, settaskinc] = useState(0);
  const [delinc, setdelinc] = useState(0);
  const [editinc, seteditinc] = useState(0);
  const [checkRc, setcheckRc] = useState(0);
  const [rc_status, setrc_status] = useState(false);
  const [addcomment, setaddcomment] = useState("");
  const [comment, setcomment] = useState([]);
  const [changeopinion, setchangeopinion] = useState(false);
  const [opinion, setopinion] = useState([]);
  const [getRcCovers, setgetRcCovers] = useState([]);
  const [rc_date, setrc_date] = useState(null);
  const [update_type_id, setupdate_type_id] = useState(0);
  const [isRcApproved, setisRcApproved] = useState(null);
  const [updateType, setupdateType] = useState([]);
  const [bool, setbool] = useState(false);
  const processpage = "RC";
  const [checktask, setchecktask] = useState([]);
  const [reopen, setreopen] = useState(null);


  function DateFormat(params) {
    if (params == null) {
      return "-";
    } else {
      const date = new Date(params);
      const yyyy = date.getFullYear();
      const yy = yyyy.toString();
      const y = yy.slice(2, 4);
      let mm = date.toLocaleString("default", { month: "short" });
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      return dd + "-" + mm + "-" + y;
    }
  }

  useEffect(()=>{
    if(id != "19" && id !== "42" && id !== "12"){
      const func = async ()=>{
        const res = await axios.get(`${url}/api/agreedinProcess/${id}`);
        const resp = res.data;
        const arr = [];
        for(let i in resp){
          if(resp[i].user_id == id || resp[i].lead_rc_id == id || resp[i].user_id1 == id){
            arr.push(resp[i]);
          }
        }
        setopinion(arr);
      }
      func();
    }
    else{
      const func = async ()=>{
        const resp2 = await axios.get(
          `${url}/api/myinprocess/${id}`
        );
        const data2 = resp2.data; 
        const arr2 = [];
        for(let i in data2){
          if(data2[i].user_id == id || data2[i].lead_rc_id == id || data2[i].user_id1 == id || id == "42" || id == "12"){
              arr2.push(data2[i]);
          }
        } 
        setopinion(arr2);
      }

      func();
    }

  },[]);

  useEffect(()=>{
    setcheckRc(0);
    setrc_status(false);
    const func = async () => {
      const res = await axios.get(`${url}/api/checkrc/${client_id}/${og_rating_id}`);
      setcheckRc(res.data.rc);
      setrc_date(res.data.rc_date);
      setrc_status(true);
      setgetRcCovers(res.data.rc_data);
    }
    func();
  },[changeopinion])

  useEffect(() => {
    setdata({});
    const func = async () => {
      const resp = await axios.get(
        `${url}/api/addTask/FC/${client_id}/${og_rating_id}`
      );
      const res = resp.data;

      const response = res[0];
      // const res2 = resp2.data;
      if(response.edit_module_tasks !== null){
        let arr = response.edit_module_tasks.split(',');
        setchecktask(arr);

        }
      else{
        setchecktask([]);
      }
      
      setreopen(response.reopen);

      setdata(res[0]);
      setupdate_type_id(Number(res[0].rating_update_type));

      const commentres = await axios.get(`${url}/api/getcomments/${client_id}/${og_rating_id}`);
      const commentresp = commentres.data;
      setcomment(commentresp.comments);
    };
    func();
  }, [changeopinion]);

  
  useEffect(() => {
    if(del == true){
      setGetsubtask([]);
      setdel(false);
    }
    const func = async () => {
      const getsubtasks = await axios.get(
        `${url}/api/getsubTask/${'RC'}/${client_id}/${og_rating_id}`
      );
      if(getsubtask != null){
        setGetsubtask(getsubtasks.data);
        console.log(getsubtasks.data, "data");
        if(begin == "starting"){
          seteditfiles(Array(getsubtasks.data.length).fill(null));
          setbegin("started");
        }
      }
      getsubtaskload("loaded");
    };
    func();
  }, [editview]);

  useEffect(()=>{
    setbegin("starting");
    setTasks([]);
    const func = async () => {
      const getsubtasks = await axios.get(
        `${url}/api/getsubTask/${'RC'}/${client_id}/${og_rating_id}`
      );
      if(getsubtask != null){
        setGetsubtask(getsubtasks.data);
        if(begin == "starting"){
          seteditfiles(Array(getsubtasks.data.length).fill(null));
          setbegin("started");
        }
      }
      setTasks([{ id: uuidv4() }]);
    };

    func ()
  },[changeopinion])

  useEffect(()=>{
    setGetsubtask(getsubtask);
  },[getsubtask])

  useEffect(()=>{
    const func = async ()=>{
      const res = await axios.get(`${url}/api/getRatingupdateType/${og_rating_id}`);
      const resp = res.data;
      setisRcApproved(resp.rc_upload_date);
      setupdateType(resp.update_type);
    }
    
    func();
  },[bool]);

  const changeUpdateType = async (value)=>{
    if(value !== '0'){
      setalertOpen(false);
      const res = await axios.post(`${url}/api/changeRatingupdateType`, {
        'id' : Number(og_rating_id),
        'rating_update_type' : Number(value)
      });
      const resp = res.data;
      if(resp.status == true){
          setalertType("success");
          setalertOpen(true);
          setalertmessage(resp.message);
      }
      else{
          setalertType("error");
          setalertOpen(true);
          setalertmessage(resp.message);
      }

    }

  }

  const dateandtime = (params)=>{
    const date = new Date(params);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
    const time = new Date(params);
    const formattedTime = time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    return(`${formattedTime} | ${formattedDate}`)
  }

  const editSubTasks = async (id, index) => {
    settaskinc(0);
    setdelinc(0);
    const fileData = new FormData();
    const date = document.getElementById(`editDate-${index}`).value;
    const title = document.getElementById(`editTitle-${index}`).value;
    if(date !== null && title !==null){
      seteditinc(editinc + 1);
      document.getElementById(`editicon${index}`).style.display = "none";
      document.getElementById(`editprogress${index}`).style.display = "unset";
      if (editfile[index] == null) {
        fileData.append("fileupload", null);
        fileData.append("title", title);
        fileData.append("date", date);
        fileData.append("recordid", Number(og_rating_id));
        fileData.append("id", localStorage.getItem("userID"));
        const editapi = await axios.post(
          `${url}/api/updatesubTask/${id}`,
          fileData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        document.getElementById(`editicon${index}`).style.display = "unset";
        document.getElementById(`editprogress${index}`).style.display = "none";
        if(editapi.data.status == true){
          setalertType("success");
          setalertOpen(true);
          if(editinc >= 1){
            setalertmessage(`Task Edit Succefully! (${editinc})`)
          }
          else{
            setalertmessage(`Task Edit Succefully!`)
          }
        }
        else{
          setalertType("error");
          setalertOpen(true);
          setalertmessage(`An error occure while Editing Task. Please try again!`)
        }
        
      } 
      else {
        fileData.append("fileupload", "not null");
        fileData.append("file", editfile[index]);
        fileData.append("title", title);
        fileData.append("date", date);
        fileData.append("recordid", Number(og_rating_id));
        fileData.append("id", localStorage.getItem("userID"));
  
        const editapi = await axios.post(
          `${url}/api/updatesubTask/${id}`,
          fileData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        document.getElementById(`editicon${index}`).style.display = "unset";
        document.getElementById(`editprogress${index}`).style.display = "none";
        if(editapi.data.status == true){
          seteditview(!editview);
          const neweditfile = [...editfile];
          neweditfile[index] = null;
          seteditfiles(neweditfile);
          setalertType("success");
          setalertOpen(true);
          if(editinc >= 1){
            setalertmessage(`Task Edit Succefully! ${editinc}`)
          }
          else{
            setalertmessage(`Task Edit Succefully!`)
          }
        }
        else{
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`An error occure while Editing Task. Please try again!`)
        }
      }
    }
    else{
      setalertType("error");
      setalertOpen(true);
      setalertmessage(`Task Title or Date is Empty. Check it Out!`)
    }
  };

  const deleteSubTasks = async (id, index) => {
    Swal.fire({ title: 'Do you want to delete the task?', 
    showDenyButton: false, showCancelButton: true, confirmButtonText: 'Delete'})
    .then(async(result) => {
      if (result.isConfirmed) {
        settaskinc(0);
    seteditinc(0);
    setdelinc(delinc + 1);
    document.getElementById(`deleteicon${id}`).style.display = "none";
    document.getElementById(`deleteprogress${id}`).style.display = "unset";
    const res = await axios.get(
      `${url}/api/deletesubTask/${id}/${og_rating_id}`
      );
      console.log(res, "response on delete");
      document.getElementById(`deleteicon${id}`).style.display = "unset";
      document.getElementById(`deleteprogress${id}`).style.display = "none";
      if (res.data.message == "Task Deleted!") {
      setdel(true);
      seteditview(!editview);
      const Editfiles = [...editfile];
      Editfiles.splice(index, 1)
      seteditfiles(Editfiles);
      setalertType("success");
      setalertOpen(true);
      if(delinc >= 1){
          setalertmessage(`Task deleted Succefully!(${delinc})`)
      }
      else{
          setalertmessage(`Task deleted Succefully!`)
      }
    }
    else{
      setalertType("error");
      setalertOpen(true);
      setalertmessage(`An Error Occure while deleting Task`);
    }
      }
    })
    
  };

  const addTask = async (id, index) => {
    seteditinc(0);
    setdelinc(0);
    const fileData = new FormData();
    if (date[index]&& title[index] && file[index] !== undefined && file[index] !== null) {
      document.getElementById(`saveicon${id}`).style.display = "none";
      document.getElementById(`saveprogress${id}`).style.display = "unset";
      settaskinc(taskinc + 1);
        fileData.append("fileupload", "not null");
        fileData.append("file", file[index]);
        fileData.append("taskTitle", "RC");
        fileData.append("taskName", title[index]);
        fileData.append("taskDate", date[index]);
        fileData.append("record_id", og_rating_id);
        fileData.append("opinion_id", client_id);
        fileData.append("analystid", data.analyst_id);
        fileData.append("teamleadid", data.manager_id);
        fileData.append("unitheadid", data.unit_head_id);
        fileData.append("id", localStorage.getItem("userID"));
        const res = await axios.post(
          `${url}/api/addtask`,
          fileData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res.data.status == true) {
            const Editfiles = [...editfile];
            Editfiles.push(null);
            seteditfiles(Editfiles);
            seteditview(!editview)
            if (tasks.length == 1) {
              console.log(tasks.filter((task) => task.id !== id));
              const newtitle = [...title];
              newtitle.splice(index, 1);
              settitle(newtitle);
              const newdate = [...date];
              newdate.splice(index, 1);
              setdate(newdate);
              setTasks(tasks.filter((task) => task.id !== id));
              setTasks([{ id: uuidv4() }]);
              const newFiles = [...file];
              newFiles.splice(index, 1)
              setfiles(newFiles);
              seteditview(!editview);
            } else {
              console.log(tasks.filter((task) => task.id !== id));
              const newtitle = [...title];
              newtitle.splice(index, 1);
              settitle(newtitle);
              const newdate = [...date];
              newdate.splice(index, 1);
              setdate(newdate);
              setTasks(tasks.filter((task) => task.id !== id));
              const newFiles = [...file];
              newFiles.splice(index, 1)
              setfiles(newFiles);
              seteditview(!editview);
              
            }
            setalertType("success");
            setalertOpen(true);
            if(taskinc > 1){
              setalertmessage(`Task Added Successfully And added in edit section! (${taskinc})`)
            }
            else{
              setalertmessage(`Task Added Successfully And added in edit section!`)
            }
        }
        else{
          setalertType("error");
          setalertOpen(true);
          setalertmessage(`An error occure while adding Task. Please try again!`)
          document.getElementById(`saveicon${id}`).style.display = "unset";
          document.getElementById(`saveprogress${id}`).style.display = "none";
        }
    } else {
      setalertType("error");
      setalertOpen(true);
      setalertmessage(`Check Date, Title and File field in Row ${index + 1}! It should not be empty`);
    }
  };

  const handleAddTask = () => {
    setTasks([...tasks, { id: uuidv4() }]);
  };

  const handleDeleteTask = (id, index) => {
    setTasks(tasks.filter((task) => task.id !== id));
    const newtitle = [...title];
    newtitle.splice(index, 1);
    settitle(newtitle)
    const newdate = [...date];
    newdate.splice(index, 1);
    setdate(newdate)
    const newfile = [...file];
    newfile.splice(index, 1);
    setfiles(newfile);
  };

  const AddComment = async () => {
    const res = await axios.post(`${url}/api/addcomment`, {
      "user_id": Number(id),
      "client_id": Number(client_id),
      "record_id": Number(og_rating_id),
      "comment" : addcomment
    })
    if(res.data.status == true){
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const hours = String(currentDate.getHours()).padStart(2, "0");
      const minutes = String(currentDate.getMinutes()).padStart(2, "0");
      const seconds = String(currentDate.getSeconds()).padStart(2, "0");
      const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

      let arr = comment;
      arr.push({"client_id": Number(client_id), "record_id": Number(og_rating_id), "comment" : addcomment, "created_at": formattedDateTime, "name": username, "userid": Number(id)});
      setcomment(arr);
      setaddcomment("");
      targetRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });

    }
  }

  useEffect(()=>{
    setcomment(comment);
    setaddcomment(addcomment);
    console.log("done it");
  },[comment])

  const renderTasks = () => {
    return tasks.map((task, index) => {
      const { id } = task;
      const showAddButton = index === tasks.length - 1;
      return (
        <>
          <div key={id} className="row w-100 pt-2 ps-2 pb-1" id={`task-${id}`}>
            <div className="col-lg-4 col-md-5 col-sm-5 col-7 ms-lg-5 ms-1">
              <input
                className="form-control"
                id={`title-${id}`}
                type="text"
                placeholder="Task Name"
                value={title[index]}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    const newtitle = [...title];
                    newtitle[index] = e.target.value
                    settitle(newtitle);
                  } else {
                    const newtitle = [...title];
                    newtitle[index] = null
                    settitle(newtitle);
                  }
                }}
              />
            </div>
            <div className="col-lg-2 col-md-3 col-sm-3 col-4 ms-lg-2 ms-1">
              <input
                className="form-control"
                id={`date-${id}`}
                type="date"
                value={date[index]}
                min={data.initiation_date}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    const newdate = [...date];
                    newdate[index] = e.target.value
                    setdate(newdate);
                  } else {
                    const newdate = [...date];
                    newdate[index] = null
                    setdate(newdate);
                  }
                }}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12 ms-lg-2 ms-1 mt-sm-0 mt-1 d-flex">
              <div className="col-11">
                <label className="btn btn-primary" style={{ height: "85%", width: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                <input
                  className="form-control d-none"
                  id={`file-${id}`}
                  type="file"
                  onChange={(e) => {
                    const newFiles = [...file];
                    newFiles[index] = e.target.files[0];
                    console.log(newFiles);
                    setfiles(newFiles);
                  }}
                />
                {file[index] ? (
                  <span title={file[index].name}>{file[index].name}</span>
                ) : (
                  "Upload File"
                )}
                </label>
              </div>
            </div>
            <div className="col-lg-2 col-md-1 col-sm-1 col-2 ms-lg-1 ms-1 ms-md-1 mt-lg-0 mt-1 d-flex">
              <div type="submit" onClick={() => addTask(id, index)}>
                <div className="mt-0" id={`saveicon${id}`}>
                  <SaveIcon style={{color: "#000078"}} />
                </div>
                <div className="mt-0" id={`saveprogress${id}`} style={{ display: "none" }}>
                  <CircularProgress size={15} />
                </div>
              </div>
              {tasks.length > 1 && (
                <div
                  className="ms-1"
                  onClick={() => handleDeleteTask(id, index)}
                >
                    <CancelIcon style={{color: "#dc3545"}} />
                </div>
              )}
              {showAddButton && (
                <div
                  className="ms-1"
                  onClick={handleAddTask}
                >
                    <AddCircleOutlinedIcon style={{color: "#198754"}} />
                </div>
              )}
            </div>
          </div>
        </>
      );
    });
  };

  const getSubTasksFunc = () => {
    return getsubtask.map((value, index) => {
      return (
        <>
          <div className="row w-100 pt-2 ps-2 pb-1" id={index}>
            <div className="col-lg-4 col-md-5 col-sm-5 col-7 ms-lg-5 ms-1">
              <input
                className="form-control"
                id={`editTitle-${index}`}
                defaultValue={value.title}
                type="text"
              />
            </div>
            <div className="col-lg-2 col-md-3 col-sm-3 col-4 ms-lg-2 ms-1">
              <input
                className="form-control"
                id={`editDate-${index}`}
                defaultValue={value.date}
                min={data.initiation_date}
                type="date"
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12 ms-lg-2 ms-1 d-flex mt-sm-0 mt-1">
              <div className="col-11">
              <label className="btn btn-primary" style={{ height: "85%", width: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                <input
                  className="form-control d-none"
                  id={`editUpload-${index}`}
                  type="file"
                  onChange={(e) => {
                    const newEditFiles = [...editfile];
                    newEditFiles[index] = e.target.files[0];
                    seteditfiles(newEditFiles);
                  }}
                />
                {editfile[index] ? (
                  <span title={editfile[index].name}>{editfile[index].name}</span>
                ) : (<>{value.task_file && "Change File"} {value.task_file == null && "Upload File"}</>)}
              </label>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-12 ms-lg-1 ms-1 ms-md-1 mt-lg-0 mt-1 d-flex">
              {value.task_file != null ? 
              <div className="col-1">
              {
                value.status == 'Spaces' ? 
                <a href={`${url}/api/viewsubtask_spaces/${value.task_file}`} target="_blank">
                <DescriptionIcon />
                </a> :
                <a href={`${url}/api/storage/app/storage/task_files/${value.task_file}`} target="_blank">
                <DescriptionIcon />
                </a>
              }
              
              </div>
              : null}
                <div onClick={() => editSubTasks(value.id, index)}>
                  
                <div
                  className="mt-0"
                  id={`editprogress${index}`}
                  style={{ display: "none" }}
                >
                  <CircularProgress size={15} />
                </div>
                <div className="mt-0 ms-1" id={`editicon${index}`}>
                <EditIcon style={{color: "#ffc107"}} />
                </div>

                </div>

                <div className="ms-1" onClick={() => {deleteSubTasks(value.id, index)}}>
                  <div
                    className="mt-0 ms-1"
                    id={`deleteprogress${value.id}`}
                    style={{ display: "none" }}
                  >
                    <CircularProgress size={15} />
                  </div>
                  <div className="mt-0" id={`deleteicon${value.id}`}>
                    <DeleteIcon style={{color: "#dc3545"}} />
                  </div>
                </div>

                
            </div>
            <hr />
          </div>
        </>
      );
    });
  };

  return (
    <div style={{height: "90vh"}}>
      <div
        className="fs-3 fw-bold text-center mt-2"
        style={{ color: "#000078", fontFamily: "Kalinga" }}
      >
      Rating Committee 
      </div>
      <div
        className="card m-2 shadow"
        style={{
          backgroundColor: "#e0e0e0",
          backgroundImage: `url(${white})`,
          backgroundSize: "cover",
        }}
      >
                <div className="row p-2">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw" }}
                >
                  CRO
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <input
                  className="form-control"
                  disabled
                  type="text"
                  defaultValue={data && data.lead_name}
                ></input>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw"  }}
                >
                  Team Lead
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 ms-lg-3 ms-md-3">
                <input
                  className="form-control"
                  disabled
                  type="text"
                  defaultValue={data && data.manager_name}
                ></input>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw"  }}
                >
                  {" "}
                  Analyst{" "}
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <input
                  className="form-control"
                  disabled
                  type="text"
                  defaultValue={data && data.username}
                ></input>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw"  }}
                >
                  {" "}
                  Opinion{" "}
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 ms-lg-3 ms-md-3">
                <select className="form-select" value={`${client_id},${og_rating_id}`} onChange={(e)=>{
                  const val = e.target.value;
                  const arr = val.split(",");
                  const client_id = arr[0];
                  const og_rating_id = arr[1];
                  const encryptedParams = encryptParams({client_id, og_rating_id})
                  navigate(`/Ratings/addTask/RC/${encryptedParams}`);
                  setGetsubtask([]);
                  setchangeopinion(!changeopinion);
                  setbool(!bool);
                }}>
                  {
                    opinion.map((value, index)=>{
                      return(
                        <option value={`${value.Id},${value.record_id}`}>{value.Entity}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw"  }}
                >
                  {" "}
                  Rating Update Type{" "}
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                {isRcApproved == null ?
                  <select className="form-select" onChange={(e)=>{changeUpdateType(e.target.value)}}>
                      <option value={0}>Select Review Type</option>
                        {
                          updateType.map((value, index)=>{
                            return(
                              <option value={value.id} selected={value.id == update_type_id ? true : false}>{value.title}</option>
                            );
                          })
                        }
                    </select> :
                    <input
                      className="form-control"
                      disabled
                      type="text"
                      defaultValue={data && data.ratingUpdateType_title}
                    ></input>
                }
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw"  }}
                >
                  Process
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 ms-lg-3 ms-md-3">
              <select className="form-select" defaultValue={processpage} onChange={(e)=>{
                const encryptedParams = encryptParams({client_id, og_rating_id})
                navigate(`/Ratings/addTask/${e.target.value}/${encryptedParams}`);
              }}>
                <option value={"Information"}>Information</option>
                <option value={"Assessment"}>Assessment</option>
                <option value={"SiteVisit"}>Site Visit</option>
                <option value={"MM"}>MM</option>
                <option value={"RC"}>RC</option>
                <option value={"PPL"}>PPL</option>
                <option value={"NL"}>NL</option>
                <option value={"RR"}>RR</option>
                <option value={"RED"}>RED</option>
              </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isadmin == 'true' &&
      <div className="text-center">
            <b>Record id:</b> {og_rating_id} <b>Opinion id:</b> {client_id}
      </div>}
        <Box className="mx-auto" sx={{ width: "98%" }}>
          <Collapse in={alertopen}>
            <Alert
              severity={alertType}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setalertOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {AlertMessage}
            </Alert>
          </Collapse>
        </Box>

      {
        (reopen == 'Open' && checktask.includes('RC') == true) ?
          <div
          className="card m-2 mb-2 shadow"
          style={{
            backgroundColor: "#e0e0e0",
            backgroundImage: `url(${white})`,
            backgroundSize: "cover",
          }}
        >
          
          {subtaskload == "loaded" ? (
            getSubTasksFunc()
          ) : (
            <center>
              <CircularProgress className="mt-1" />
            </center>
          )}
          {tasks.length !== 0 && renderTasks()}
          </div> :
         reopen == null ?
         <div
          className="card m-2 mb-2 shadow"
          style={{
            backgroundColor: "#e0e0e0",
            backgroundImage: `url(${white})`,
            backgroundSize: "cover",
          }}
        >
          
          {subtaskload == "loaded" ? (
            getSubTasksFunc()
          ) : (
            <center>
              <CircularProgress className="mt-1" />
            </center>
          )}
          {tasks.length !== 0 && renderTasks()}
          </div> :
        (reopen == 'Open' && checktask.includes('RC') == false) ?
        <div className="mt-3 fw-4 text-center"> Not allowed to Edit after File Closure</div> :
        <div
          className="card m-2 mb-2 shadow"
          style={{
            backgroundColor: "#e0e0e0",
            backgroundImage: `url(${white})`,
            backgroundSize: "cover",
          }}
        >
          
          {subtaskload == "loaded" ? (
            getSubTasksFunc()
          ) : (
            <center>
              <CircularProgress className="mt-1" />
            </center>
          )}
          {tasks.length !== 0 && renderTasks()}
          </div>
      }


        <div className="col-12 p-2">
          {rc_status == true ? checkRc > 0 ? <>
          <button className={`button btn btn-primary ${props.screenWidth <= 576 ? 'btn-sm' : null}`} onClick={()=>{window.open(`/Ratings/RC_Cover/Approval/${client_id}/${og_rating_id}`)}}> RC {rc_date !== null && rc_date !== "" ? `(${DateFormat(rc_date)})` : null}</button>
          {
            checkRc > 1 ?
            getRcCovers.map((value, index)=>{
              if(value.approvalIds !== null && value.approvalIds !== ""){
                return(
                  <button className= {`button btn btn-primary ${props.screenWidth <= 576 ? 'btn-sm' : null} ms-1`} onClick={()=>{window.open(`https://209.97.168.200/pacrawizpackv3/storage/app/Rcm${value.date}${og_rating_id}.pdf`)}}>{DateFormat(value.date)}</button>
                )
              }
            }) : null
          }
          </>  :
          <button className={`button btn btn-primary ${props.screenWidth <= 576 ? 'btn-sm' : null}`} onClick={()=>{window.open(`/Ratings/RC_Cover/Coverpageindex/${client_id}/${og_rating_id}`)}}> Generate RC</button> : null}
          <button className={`button btn ${comment.length > 0 ? 'comment2' : 'btn-primary'} ms-1 ${props.screenWidth <= 576 ? 'btn-sm' : null}`} data-bs-toggle="modal" data-bs-target="#exampleModal">
            RC Comment
          </button>
          <button className={`button btn btn-primary ms-1 ${props.screenWidth <= 576 ? 'btn-sm' : null}`} onClick={()=>{window.open(`/Ratings/RC/logs/${client_id}/${og_rating_id}`)}}>RC logs</button>
          
          </div>
        {/* <button className="button btn btn-primary ms-1" onClick={()=>{
          for(let i in tasks){
            addTask(tasks[i].id, i)
          }
        }}> Save </button> */}

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Comments
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
            <div class="mesgs" ref={targetRef}>
              {
                comment.map((value, index)=>{
                  return(
                    <>
                    <div class={value.userid == Number(id) ? "outgoing_msg" : "incoming_msg"}>
                      {value.userid !== Number(id) && <div class="incoming_msg_img"> <img src={value.avatar_file !== null && value.avatar_file !== "" ? `https://209.97.168.200/hr/public/users/${value.avatar_file}` : "https://ptetutorials.com/images/user-profile.png"} alt="" /> </div>}
                      <div class={value.userid == Number(id) ? "sent_msg" : "received_msg"}>
                        {value.userid == Number(id) ?  
                        <>
                        <p><b>{value.name}</b><br/>{value.comment}</p>
                            <span class="time_date"> {dateandtime(value.created_at)}</span> 
                        </>
                        : <div class="received_withd_msg">
                          <p><b>{value.name}</b><br/>{value.comment}</p>
                          <span class="time_date"> {dateandtime(value.created_at)}</span>
                        </div>}
                      </div>
                    </div> <br/> </>
                  );
                })
              }
            </div>
            <br/>
            <hr />
              <div className="row" style={{width: "100%"}}>
                <div className="col-11">
                  <textarea className="form-control" placeholder="write message here..." value={addcomment} 
                  onChange={(e)=>{
                    setaddcomment(e.target.value);
                  }} 
                  style={{height: 17, maxHeight:17}}/>
                </div>
                <div className="col-1">
                  <button className="msg_send_btn ms-1" type="button" onClick={()=>{AddComment()}}><SendIcon /></button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
