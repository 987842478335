import React,{useEffect, useState, useRef} from 'react';
import { url } from '../../../../../Config';
import axios from 'axios';
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { encryptParams } from '../../../encrypt_url';


export default function Analyst_detail(props) {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [subTabbar, setsubTabbar] = React.useState(false);  
    const [rc_to_be_done_30_days, setrc_to_be_done_30_days] = useState([]);
    const [dd_to_be_done_30_days, setdd_to_be_done_30_days] = useState([]);
    const [ic_to_be_done_30_days, setic_to_be_done_30_days] = useState([]);
    const [fc_to_be_done_30_days, setfc_to_be_done_30_days] = useState([]);
    const [support_portfolio, setsupport_portfolio] = useState([]);
    const [analyst, setanalyst] = useState({});
    const { analyst_id } = useParams();


    
  function DateFormat(params) {
    if (params == null) {
      return "-";
    } else {
      const date = new Date(params);
      const yyyy = date.getFullYear();
      const yy = yyyy.toString();
      const y = yy.slice(2, 4);
      let mm = date.toLocaleString("default", { month: "short" });
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      return dd + "-" + mm + "-" + y;
    }
  }


    const hideSubTabBar = () => {
        setsubTabbar(!subTabbar);
      };
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
        // setValue(event.target.newValue);
    };

    useEffect(()=>{

        const func = async ()=>{
            const res = await axios.get(`${url}/api/analyst_detail/${analyst_id}`);
            const resp = res.data;
            setanalyst(resp.analyst[0]);
            
            setrc_to_be_done_30_days(resp.rc_to_be_done_30_days);
            setdd_to_be_done_30_days(resp.dissemination_to_be_done_30_days);
            setic_to_be_done_30_days(resp.ic_to_be_done_30_days);
            setfc_to_be_done_30_days(resp.fc_to_be_done_30_days);
            setsupport_portfolio(resp.support_portfolio);
        }

        func();

    },[])

  return (
    <div style={{height: '90vh'}}>
        <div className="mx-auto p-3 fs-3 fw-bold mb-0 text-capitalize" style={{ width: "fit-content", color:"#000078" }}>
            Analyst Details
        </div>
        <div className='card shadow col-11 mx-auto p-2 mt-2'>
            <div className='row mb-2'>
                <div className='col-6 row'>
                    <div className='col-3 fw-bold'>Name: </div>
                    <div className='col-7'>
                        <input className='form-control' value={analyst.display_name} disabled/>
                    </div>
                </div>
                <div className='col-6 row'>
                    <div className='col-3 fw-bold'>Designation: </div>
                    <div className='col-7'>
                        <input className='form-control' value={analyst.Designation} disabled/>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-6 row'>
                    <div className='col-3 fw-bold'>Team Lead: </div>
                    <div className='col-7'>
                        <input className='form-control' value={analyst.team_lead} disabled/>
                    </div>
                </div>
                <div className='col-6 row'>
                    <div className='col-3 fw-bold'>Email: </div>
                    <div className='col-7'>
                        <input className='form-control' value={analyst.email} disabled/>
                    </div>
                </div>
            </div>
        </div>
        <div className="mx-auto p-3 fs-3 fw-bold mb-0 text-capitalize" style={{ width: "fit-content", color:"#000078" }}>
            Analyst Tasks (30 days)
        </div>
        <div className='mt-3 col-11 mx-auto'>
            <div className={`${classes.root}`}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                className={`theme_text mb-4 pb-2`}
                aria-label="scrollable auto tabs example"
            >
                <Tab label="Upcoming RC (30 Days)" {...a11yProps(0)} />
                <Tab label="Upcoming IC (30 Days)" {...a11yProps(1)} />
                <Tab label="Upcoming DD (30 Days)" {...a11yProps(2)} />
                <Tab label="Upcoming FC (30 Days)" {...a11yProps(3)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <div className='card shadow col-12 p-3'>
                    {
                        rc_to_be_done_30_days.map((value, index)=>{
                            const clientId = value.client_id;
                            const encryptedParams = encryptParams({clientId});
                            return(
                                <div>
                                    {index + 1}){"  "}<a href={`/OpinionDetails/${encryptedParams}`} target='_blank'>{value.opName}</a> | {DateFormat(value.rc_deadline)}
                                </div>
                            )
                        })
                    }
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div className='card shadow col-12 p-3'>
                    {
                        ic_to_be_done_30_days.map((value, index)=>{
                            const clientId = value.client_id;
                            const encryptedParams = encryptParams({clientId});
                            return(
                                <div>
                                    {index + 1}){"  "}<a href={`/OpinionDetails/${encryptedParams}`} target='_blank'>{value.opName}</a> | {DateFormat(value.ic_deadline)}
                                </div>
                            )
                        })
                    }
                </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <div className='card shadow col-12 p-3'>
                    {
                        dd_to_be_done_30_days.map((value, index)=>{
                            const clientId = value.client_id;
                            const encryptedParams = encryptParams({clientId});
                            return(
                                <div>
                                    {index + 1}){"  "}<a href={`/OpinionDetails/${encryptedParams}`} target='_blank'>{value.opName}</a> | {DateFormat(value.dissemination_deadline)}
                                </div>
                            )
                        })
                    }
                </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <div className='card shadow col-12 p-3'>
                    {
                        fc_to_be_done_30_days.map((value, index)=>{
                            const clientId = value.client_id;
                            const encryptedParams = encryptParams({clientId});
                            return(
                                <div>
                                    {index + 1}){"  "}<a href={`/OpinionDetails/${encryptedParams}`} target='_blank'>{value.opName}</a> | {DateFormat(value.fc_deadline)}
                                </div>
                            )
                        })
                    }
                </div>
            </TabPanel>
            
            </div>
        </div>
    </div>
  )

}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
        className={`card ${props.screenWidth > 770 ? "mt-4" : "negative_margin"}`}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    topMargin: {
      top: "50px",
    },
    leftMargin: {
      width: `calc(100% - ${240}px)`,
    },
    left: {
      width: `calc(100% - ${74}px)`,
    },
    responsiveLeft: {
      width: "100%",
    },
    topAbsolute: {
      top: "4px",
    },
  }));
