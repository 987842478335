import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import ScrollToTop from "../../../Atoms/ScrollToTop";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { url } from "../../../../Config";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function RcApproval(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { client_id, og_rating_id } = useParams();
  const id = localStorage.getItem("userID")
  const [data, setdata] = useState(null);
  const [RC_internal, setRC_internal] = useState([]);
  const [RC_internalname, setRC_internalname] = useState([]);
  const [RC_external, setRC_external] = useState([]);
  const [RC_externalname, setRC_externalname] = useState([]);
  const [RC_guest, setRC_guest] = useState([]);
  const [RC_guestname, setRC_guestname] = useState([]);
  const [SectorStudyname, setSectorStudyname] = useState([]);
  const [SectorStudy, setSectorStudy] = useState([]);
  const [Methodologyname, setMethodologyname] = useState([]);
  const [Methodology, setMethodology] = useState([]);
  const [Methodologyorder, setMethodologyorder] = useState([]);
  const [lterm_id, setlterm_id] = useState(null);
  const [lterm_id2, setlterm_id2] = useState(null);
  const [ltermPintensity, setltermPintensity] = useState(null);
  const [ltermFintensity, setltermFintensity] = useState(null);
  const [Plterm, setPlterm] = useState(null);
  const [Flterm, setFlterm] = useState(null);
  const [PSterm, setPSterm] = useState(null);
  const [FSterm, setFSterm] = useState(null);
  const [Poutlook, setPoutlook] = useState(null);
  const [Foutlook, setFoutlook] = useState(null);
  const [alertopen, setalertopen] = useState(false);
  const [AlertMessage, setAlertMessage] = useState(null);
  const [alertType, setalertType] = useState("error");
  const [isRCInternalSelected, setIsRCInternalSelected] = useState(false);
  const [isRCExternalSelected, setIsRCExternalSelected] = useState(false);
  const [isMethodologySelected, setIsMethodologySelected] = useState(false);
  const [r_updateType, setr_updateType] = useState(null);
  const [p_sterm_state, setP_sterm_state] = useState([])
  const [f_sterm_state, setF_sterm_state] = useState([])
  const [p_action, setP_action] = useState(null);
  const [f_action, setF_action] = useState(null);
  const [action_state, setaction_state] = useState(null);
  const [action_state2, setaction_state2] = useState(null);
  const [rc_chairman, setrc_chairman] = useState(null);
  const [view_chairman, setview_chairman] = useState('hideall');
  const [chairmanoptions, setchairmanoptions] = useState([]);
  const [des, setdes] = useState([{value: '-', option: '-'}, {value: 'Consensus', option: ' Consensus'}, {value: 'Majority', option: 'Majority'}]);


  const [current_r_scale, setcurrent_r_scale] = useState(null);

  function DateFormat(params) {
    if (params == null) {
      return "-";
    } else {
      const date = new Date(params);
      const yyyy = date.getFullYear();
      const yy = yyyy.toString();
      const y = yy.slice(2, 4);
      let mm = date.toLocaleString("default", { month: "short" });
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      return dd + "-" + mm + "-" + y;
    }
  }

  const Datefunc = (date) => {
    const dateString = date;
    const dateObj = new Date(dateString);
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      dateObj
    );
    const year = dateObj.getFullYear().toString().slice(-2);
    const formattedDate = `${month}-${year}`;
    return formattedDate;
  };

  const RC_internalfunc = (event) => {
    const {
      target: { value },
    } = event;
    const arr = [];
    for (let i in value) {
      for (let j in data.internal_rc_members) {
        if (value[i] == data.internal_rc_members[j].name) {
          arr.push(data.internal_rc_members[j].id);
        }
      }
    }
    setRC_internal(arr);

    setRC_internalname(typeof value === "string" ? value.split(",") : value);
    setIsRCInternalSelected(value.length > 0);
  };
  const RC_externalfunc = (event) => {
    const {
      target: { value },
    } = event;
    const arr = [];
    const arr2 = [];
    for (let i in value) {
      for (let j in data.external_rc_members) {
        if (value[i] == data.external_rc_members[j].name) {
          arr.push(data.external_rc_members[j].id);
          arr2.push({id: data.external_rc_members[j].id, name: data.external_rc_members[j].name});
        }
      }
    }

    if(arr.includes(5) == true){
      setrc_chairman(5);
      setview_chairman('amanullah_chairman')
    }
    else if(arr.length == 0){
      setview_chairman('hideall');
      setrc_chairman(null);
    }
    else if(arr.includes(5) == false && arr.length > 0){
      setrc_chairman(null);
      setview_chairman('show');
      setchairmanoptions(arr2);
    }

    setRC_external(arr);
    setRC_externalname(typeof value === "string" ? value.split(",") : value);
    setIsRCExternalSelected(value.length > 0);
  };
  const RC_guestfunc = (event) => {
    const {
      target: { value },
    } = event;
    const arr = [];
    for (let i in value) {
      for (let j in data.users) {
        if (value[i] == data.users[j].display_name) {
          arr.push(data.users[j].id);
        }
      }
    }
    console.log(arr);
    setRC_guest(arr);

    setRC_guestname(typeof value === "string" ? value.split(",") : value);
  };
  const sectorStudyfunc = (event) => {
    const {
      target: { value },
    } = event;
    const arr = [];
    for (let i in value) {
      for (let j in data.sector_study) {
        if (value[i] == data.sector_study[j].name) {
          arr.push(data.sector_study[j].id);
        }
      }
    }
    setSectorStudy(arr);

    setSectorStudyname(typeof value === "string" ? value.split(",") : value);
  };
  const Methodologyfunc = (event) => {
    const {
      target: { value },
    } = event;
    const arr = [];
    for (let i in value) {
      for (let j in data.methodology) {
        if (value[i] == data.methodology[j].name) {
          arr.push(data.methodology[j].id);
        }
      }
    }
    console.log(arr);
    setMethodology(arr);

    setMethodologyname(typeof value === "string" ? value.split(",") : value);
    setIsMethodologySelected(value.length > 0);
  };

  useEffect(() => {
    const func = async () => {
      const res = await axios.get(
        `${url}/api/RC_Cover/Coverpage/${id}/${client_id}/${og_rating_id}`
      );

      if(res.data.message == 'RC Already exist'){
        navigate(`/Ratings/RC_Cover/Approval/${client_id}/${og_rating_id}`);
      }
      else{
        const resp = res.data;
        setr_updateType(resp.rating_update_type);
        setMethodologyorder(resp.methodology.sort((a, b) => a.name.localeCompare(b.name)));
        setdata(resp);
        setcurrent_r_scale(resp.current.rating_scale_id);
        const arr = [];
        const sectorId = [];
        for (let i in resp.sector_study) {
          if (resp.sector_id == resp.sector_study[i].sector_id) {
            arr.push(resp.sector_study[i].name);
            sectorId.push(resp.sector_study[i].id);
          }
      }
      setSectorStudyname(arr);
      setSectorStudy(sectorId);

      if (resp.current.rating_scale_id == 7 || resp.current.rating_scale_id == 1 || resp.current.rating_scale_id == 9) {
        if (document.getElementById("staction") !== null) {
          document.getElementById("staction").hidden = false;
        }
        if (document.getElementById("sterm") !== null) {
          document.getElementById("sterm").hidden = false;
        }
      }

    if(resp.rating_action !== null){
      if (resp.rating_action.proposed_long_term !== null) {
        for (let i in resp.longterm) {
          if (
            resp.longterm[i].id == Number(resp.rating_action.proposed_long_term)) {
            setltermPintensity(resp.longterm[i].intensity);
            setPlterm(resp.longterm[i].id);
          }
        }
      }
        if (resp.rating_action.final_long_term !== null) {
          for (let i in resp.longterm) {
            if (resp.longterm[i].id == Number(resp.rating_action.final_long_term)) {
              setltermFintensity(resp.longterm[i].intensity);
              setFlterm(resp.longterm[i].id);
            }
          }
        }
  
        if ( resp.rating_action.proposed_short_term !== null && resp.rating_action.proposed_short_term !== "") {
          if(resp.rating_action.proposed_short_term == "0" || resp.rating_action.proposed_short_term == 0){
            setPSterm(0);
            }
            else{
              
              for (let i in resp.shortterm) {
                if (
                  resp.shortterm[i].id ==
                  Number(resp.rating_action.proposed_short_term)
                ) {
                  setPSterm(resp.shortterm[i].id);
                }
              }
            }
        }
  
        if (
          resp.rating_action.final_short_term !== null &&
          resp.rating_action.final_short_term !== ""
        ) {

          if(resp.rating_action.final_short_term == "0" || resp.rating_action.final_short_term == 0){
            setFSterm(0);
          }
          else{

            for (let i in resp.shortterm) {
              if (
                resp.shortterm[i].id == Number(resp.rating_action.final_short_term)
              ) {
                setFSterm(resp.shortterm[i].id);
              }
            }
          }
        }
  
        if (
          resp.rating_action.proposed_outlook !== null &&
          resp.rating_action.proposed_outlook !== ""
        ) {
          for (let i in resp.outlook) {
            if (
              resp.outlook[i].id == Number(resp.rating_action.proposed_outlook)
            ) {
              setPoutlook(resp.outlook[i].id);
            }
          }
        }
  
        if (
          resp.rating_action.final_outlook !== null &&
          resp.rating_action.final_outlook !== ""
        ) {
          for (let i in resp.outlook) {
            if (resp.outlook[i].id == Number(resp.rating_action.final_outlook)) {
              setFoutlook(resp.outlook[i].id);
            }
          }
        }
      }
      
      }
      
    };
    func();
  }, [document.getElementById("staction")]);

  const RcSubmit = async (event) => {
    event.preventDefault();

    if(isRCInternalSelected == false){
      setalertopen(true);
      setAlertMessage("RC Members (Internal) Require");
      setalertType("error");
      return;
    }
    if(isRCExternalSelected == false){
      setalertopen(true);
      setAlertMessage("RC Members (External) Require");
      setalertType("error");
      return;
    }
    if(isMethodologySelected == false){
      setalertopen(true);
      setAlertMessage("Methodology is Require");
      setalertType("error");
      return;
    }

    if(RC_external.length < 2){
      setalertopen(true);
      setAlertMessage("Atleast 2 RC (External) Members are required!");
      setalertType("error");
      return;
    }
    if(rc_chairman == null && view_chairman == 'show'){
      setalertopen(true);
      setAlertMessage("Please Select RC Chairman first!");
      setalertType("error");
      return;
    }

    document.getElementById('submit_load').hidden = false;
    document.getElementById('submit').hidden = true;
    const rc_internal_join = RC_internal.join(",");
    const rc_external_join = RC_external.join(",");
    const rc_guest_join = RC_guest.join(",");
    
    const sector_study_join = SectorStudy.join(",");
    const methodology_join = Methodology.join(",");
    const rcDate = document.getElementById("rcDate").value;
    const ltaction_proposed = document.getElementById("ltaction_proposed").value;
    const ltaction_final = document.getElementById("ltaction_final").value;
    const lterm1 = document.getElementById("lterm1").value;
    const lterm2 = document.getElementById("lterm2").value;
    let lterm5_1 = null;
    let lterm5_2 = null;
    if (data.rating_scale_id == 7) {
      lterm5_1 = document.getElementById("5lterm1").value;
      lterm5_2 = document.getElementById("5lterm2").value;
    }

    let staction_proposed = null;
    let staction_final = null;
    let sterm1 = null;
    let sterm2 = null;

    if (
      data.current.rating_scale_id == 7 ||
      data.current.rating_scale_id == 1 ||
      data.current.rating_scale_id == 9
    ) {
      staction_proposed = document.getElementById("staction_proposed").value;
      staction_final = document.getElementById("staction_final").value;
      if (staction_proposed == 0) {
        staction_proposed = null;
      }
      if (staction_final == 0) {
        staction_final = null;
      }
      sterm1 = document.getElementById("sterm1").value;
      sterm2 = document.getElementById("sterm2").value;
    }
    let outlook_proposed = null;
    let outlook_final = null;

    if(data.rating_scale_id != 7){
        outlook_proposed = document.getElementById("outlook_proposed").value;
        outlook_final = document.getElementById("outlook_final").value;
    }

    let year5action_proposed = null;
    let year5action_final = null;

    if(data.rating_scale_id == 7){
      year5action_proposed = document.getElementById("5yearaction_proposed").value;
      year5action_final = document.getElementById("5yearaction_final").value;
    }

    let rw1 = null;
    let rw2 = null;
    if(data.rating_scale_id !== 7){
      rw1 = document.getElementById("rw1").value;
      if(rw1 == 0){
        rw1 = "-";
      }
      rw2 = document.getElementById("rw2").value;
      if(rw2 == 0){
        rw2 = "-";
      }
  }
    const cf1 = document.getElementById("cf1").value;
    const cf2 = document.getElementById("cf2").value;
    let decision = document.getElementById("decision").value;
    if (decision == "Select") {
      decision = "-";
    }

    const Ownership = document.getElementById("Ownership").value;
    const Governance = document.getElementById("Governance").value;
    const Management = document.getElementById("Management").value;
    const BusinessRisk = document.getElementById("Business Risk").value;
    const FinancialRisk = document.getElementById("Financial Risk").value;
    const Other = document.getElementById("Other").value;
    const mdr = data.ttc;

    const res = await axios.post(`${url}/api/submit/rc_data`, {
      "opinion_id": Number(client_id), "rating_id": Number(og_rating_id), "analyst_id": Number(data.analyst_id), "managerid": Number(data.managerid),
      "rc_internal" : rc_internal_join, "rc_external_join": rc_external_join, "submit_by" : Number(id),
      "rc_guest": rc_guest_join,  "sector_study": sector_study_join,
      "methodology": methodology_join, "Date": rcDate, "LT_Action_Proposed": ltaction_proposed,
      "LT_Action_Final": ltaction_final, "L_Term_Proposed": lterm1, "L_Term_Final": lterm2,
      "L_Term_5yr_Proposed": lterm5_1, "L_Term_5yr_Final": lterm5_2, "yr5_action_proposed": year5action_proposed,
      "yr5_action_final": year5action_final,
      "ST_Action_Proposed": staction_proposed, "ST_Action_Final": staction_final, "S_Term_Proposed": sterm1,
      "S_Term_Final": sterm2, "Outlook_Proposed": outlook_proposed, "Outlook_Final": outlook_final,
      "rw_Proposed" : rw1, "rw_Final": rw2, "CF_Proposed": cf1, "CF_Final": cf2, "Decision": decision,
      "Ownership": Ownership, "Governance": Governance, "Management": Management, "BusinessRisk": BusinessRisk,
      "FinancialRisk": FinancialRisk, "Other": Other, "mdr": mdr, 'rc_chairman': rc_chairman
    });

    if (res.data.message == "RC Date is older than 5 days") {
      setalertopen(true);
      setAlertMessage(res.data.message);
      setalertType("error");
      document.getElementById('submit_load').hidden = true;
      document.getElementById('submit').hidden = false;
    }
    else{
      if(res.data.status == true){
        document.getElementById('submit_load').hidden = true;
        document.getElementById('submit').hidden = false;
        document.getElementById('modelclose').click();
        navigate(`/Ratings/RC_Cover/Approval/${client_id}/${og_rating_id}`);
      }
      else{
        setalertopen(true);
        setAlertMessage("Some error Occure while processing your Requiest");
        setalertType("error");
      }
    }
    console.log(res);
  };

  return (
    <div>
      <ScrollToTop />
      <div
        className="fs-4 fw-bold text-center mt-2"
        style={{ color: "#000078", fontFamily: "Kalinga" }}
      >
        Rating Committee Approval
      </div>
      <br />
      <div
          className="fs-5 fw-bold text-center mt-2"
          style={{ color: "#000078", fontFamily: "Kalinga" }}
        >
          {data && data.company_name}
        </div>

      <form
        className="card shadow col-11 mx-auto p-2 mb-5 border border-dark"
        onSubmit={RcSubmit}
      >
        
        <br />
        <div className="card shadow col-11 mx-auto p-2 border border-dark" style={{backgroundColor: "whitesmoke"}}>
          <div
            className="fs-4 text-center"
            style={{ color: "#000078", fontFamily: "Kalinga" }}
          >
            Rating Team & Members
          </div>
          <hr />
          <div className="row">
            <div className="col-lg-6 col-12 row">
              <div
                className="col-lg-4 col-5 fw-bold fs-6"
                style={{ color: "#000078", fontFamily: "Kalinga" }}
              >
                Team Lead
              </div>
              <div className="col-lg-7 col-6">
                <input
                  className="form-control"
                  value={data && data.managername}
                  disabled
                />
              </div>
            </div>
            <div className="col-lg-6 col-12 row mt-md-1 mt-sm-1 mt-lg-0 mt-1">
              <div
                className="col-lg-4 col-5 fw-bold fs-6"
                style={{ color: "#000078", fontFamily: "Kalinga" }}
              >
                Analyst
              </div>
              <div className="col-lg-7 col-6">
                <input
                  className="form-control"
                  value={data && data.analystname}
                  disabled
                />
              </div>
            </div>
            
            <div className="col-lg-6 col-12 row mt-1">
              <div
                className="col-lg-4 col-5 fw-bold fs-6"
                style={{ color: "#000078", fontFamily: "Kalinga" }}
              >
                RC Members (Internal)
              </div>
              <div className="col-lg-7 col-6">
                <FormControl className="custom-form-control" size="small">
                  <InputLabel id="demo-multiple-checkbox-label">
                    Select
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={RC_internalname}
                    onChange={RC_internalfunc}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {data &&
                      data.internal_rc_members &&
                      data.internal_rc_members.map((value) => (
                        <MenuItem
                          key={value.name}
                          value={value.name}
                          defaultValue={value.id}
                        >
                          <Checkbox
                            checked={RC_internalname.indexOf(value.name) > -1}
                          />
                          <ListItemText primary={value.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="col-lg-6 col-12 row mt-1">
              <div
                className="col-lg-4 col-5 fw-bold fs-6"
                style={{ color: "#000078", fontFamily: "Kalinga" }}
              >
                RC Members (External)
              </div>
              <div className="col-lg-7 col-6">
                <FormControl className="custom-form-control" size="small" >
                  <InputLabel id="demo-multiple-checkbox-label">
                    Select
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={RC_externalname}
                    onChange={RC_externalfunc}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                    inputProps={{
                      onInvalid: (e) =>
                        e.target.setCustomValidity("Please fill out RC Member (External) field"),
                      onInput: (e) => e.target.setCustomValidity(""),
                    }}
                  >
                    {data &&
                      data.external_rc_members &&
                      data.external_rc_members.map((value) => (
                        <MenuItem
                          key={value.name}
                          value={value.name}
                          defaultValue={value.id}
                        >
                          <Checkbox
                            checked={RC_externalname.indexOf(value.name) > -1}
                          />
                          <ListItemText primary={value.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-6 col-12 row mt-1">
            <div
              className="col-lg-4 col-5 fw-bold fs-6"
              style={{ color: "#000078", fontFamily: "Kalinga" }}
            >
              Guest Members
            </div>
            <div className="col-lg-7 col-6">
              <FormControl className="custom-form-control" size="small">
                <InputLabel id="demo-multiple-checkbox-label">
                  Select
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={RC_guestname}
                  onChange={RC_guestfunc}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {data &&
                    data.users &&
                    data.users.map((value) => (
                      <MenuItem
                        key={value.display_name}
                        value={value.display_name}
                      >
                        <Checkbox
                          checked={
                            RC_guestname.indexOf(value.display_name) > -1
                          }
                        />
                        <ListItemText primary={value.display_name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            </div>

            <div className="col-lg-6 col-12 row mt-1">
            <div
              className="col-lg-4 col-5 fw-bold fs-6"
              style={{ color: "#000078", fontFamily: "Kalinga" }}
            >
              RC Date
            </div>
            {data !== null && 
            <div className="col-lg-7 col-6">
                  <input
                    type="date"
                    className="form-control"
                    id="rcDate"
                    min={data.initiation_date}
                    required
                    onInvalid={(e) =>
                      e.target.setCustomValidity(
                        "Please fill out RC date field"
                      )
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                  />
            </div>}
          </div>
          {view_chairman == 'amanullah_chairman' && 
          <div className="col-lg-6 col-12 row mt-1">
            <div
              className="col-lg-4 col-5 fw-bold fs-6"
              style={{ color: "#000078", fontFamily: "Kalinga" }}
            >
              RC Chairperson
            </div>
            <div className="col-lg-7 col-6">
              <input className="form-control" disabled value={'Amanullah Khan'} />
            </div>
          </div>}
          {view_chairman == 'show' && 
          <div className="col-lg-6 col-12 row mt-1">
            <div
              className="col-lg-4 col-5 fw-bold fs-6"
              style={{ color: "#000078", fontFamily: "Kalinga" }}
            >
              RC Chairperson
            </div>
            <div className="col-lg-7 col-6">
            <select className="form-select" onChange={(e)=>{setrc_chairman(e.target.value)}} required>
              <option value={''}>Select</option>
              {
                chairmanoptions.map((value, index)=>{
                  return(
                    <option value={value.id}>{value.name}</option>
                  )
                })
              }
            </select>
            </div>
            
          </div>}
          </div>
        </div>

        <div
          className="fs-5 fw-bold text-center mt-2 mb-1"
          style={{ color: "#000078", fontFamily: "Kalinga" }}
        >
          Applicable Criteria & Related Research
        </div>
        <div className="card shadow col-11 mx-auto p-2 border border-dark" style={{backgroundColor: "whitesmoke"}}>
          <div
            className="fs-4 text-center"
            style={{ color: "#000078", fontFamily: "Kalinga" }}
          >
            Sector Study
          </div>
          <hr />
          <table className="table newtable">
            <tbody>
              <tr>
              {props.screenWidth > 631 && <td
                  className="col-lg-4 col-md-4 col-sm-5 col-6 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga" }}
                >
                  Sector Study
                </td>}
                <td className="col-lg-9 col-md-9 col-sm-9 col-12">
                  <div className="col-lg-10 col-md-10 col-sm-10 col-10">
                    <FormControl
                      className="custom-form-control2"
                      size="small"
                      sx={{ m: 1 }}
                    >
                      <InputLabel id="demo-multiple-checkbox-label">
                        Select
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={SectorStudyname}
                        onChange={sectorStudyfunc}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {data &&
                          data.sector_study &&
                          data.sector_study.map((value) => (
                            <MenuItem
                              key={value.name}
                              disabled={
                                data.sector_id[0] == value.sector_id
                                  ? true
                                  : false
                              }
                              value={value.name}
                            >
                              <Checkbox
                                checked={
                                  data.sector_id[0] == value.sector_id
                                    ? true
                                    : SectorStudyname.indexOf(value.name) > -1
                                }
                              />
                              <ListItemText
                                className="text-wrap"
                                primary={`${value.name} | ${Datefunc(
                                  value.date
                                )}`}
                              />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            className="fs-4 text-center"
            style={{ color: "#000078", fontFamily: "Kalinga" }}
          >
            Methodology
          </div>
          <hr />
          <table className="table newtable">
            <tbody>
              <tr>
              {props.screenWidth > 631 &&<td
                  className="fw-bold col-lg-4 col-md-4 col-sm-5 col-6"
                  style={{ color: "#000078", fontFamily: "Kalinga" }}
                >
                  Methodology
                </td>}
                <td className="col-lg-9 col-md-9 col-sm-9 col-12">
                  <div className="col-lg-10 col-md-10 col-sm-10 col-10">
                    <FormControl
                      className="custom-form-control2"
                      size="small"
                      sx={{ m: 1 }}
                      
                    >
                      <InputLabel id="demo-multiple-checkbox-label">
                        Select
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={Methodologyname}
                        onChange={Methodologyfunc}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                        inputProps={{
                          onInvalid: (e) =>
                            e.target.setCustomValidity("Please fill out Methodology field"),
                          onInput: (e) => e.target.setCustomValidity(""),
                        }}
                      >
                        {Methodologyorder.length > 0 &&
                          Methodologyorder.map((value) => (
                            <MenuItem key={value.name} value={value.name}>
                              <Checkbox
                                checked={
                                  Methodologyname.indexOf(value.name) > -1
                                }
                              />
                              <ListItemText
                                className="text-wrap"
                                primary={`${value.name} | ${Datefunc(
                                  value.date
                                )}`}
                              />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div
          className="card shadow col-11 mx-auto p-2 border border-dark"
          style={{ overflow: "scroll", backgroundColor: "whitesmoke" }}
        >
          <div
            className="fs-4 text-center"
            style={{ color: "#000078", fontFamily: "Kalinga" }}
          >
            Rating History
          </div>
          <hr />
          <table
            className="table newtable table-secondary"
            style={{ fontFamily: "Kalinga", border: "1px solid black", borderCollapse: "collapse" }}
          >
            <tbody>
              {data && data.rating_scale_id && data.rating_scale_id == 14 ? (
                <>
                  <tr>
                    <td className="col-2 fw-bold">Dissemination Date</td>
                    <td className="col-2 fw-bold">Security Grading</td>
                    <td className="col-2 fw-bold">Action</td>
                  </tr>
                </>
              ) : 
              data && data.rating_scale_id && data.rating_scale_id == 7 ? (
                <>
                 <tr>
                    <td className="col-1 fw-bold">Dissemination Date</td>
                    <td className="col-1 fw-bold">1 year</td>
                    <td className="col-1 fw-bold">3 year</td>
                    <td className="col-1 fw-bold">5 year</td>
                    <td className="col-1 fw-bold">1-Year Action</td>
                    <td className="col-1 fw-bold">3-Year Action</td>
                    <td className="col-1 fw-bold">5-Year Action</td>
                    <td className="col-1 fw-bold">Criticality Factor</td>
                    <td className="col-1 fw-bold">Decision</td>
                  </tr>
                </>
              ) :
              (
                <>
                  <tr>
                    <td className="col-2 fw-bold">Dissemination Date</td>
                    {data &&
                    data.rating_scale_id &&
                    (data.rating_scale_id == 1 || data.rating_scale_id == 9) ? (
                      <td className="col-2 fw-bold">Long Term Rating</td>
                    ) : data &&
                    data.rating_scale_id && data.rating_scale_id == 2 ? (<td className="col-2 fw-bold">IFS Rating</td>) : (
                      <td className="col-2 fw-bold">Rating</td>
                    )}
                    {data &&
                    data.rating_scale_id &&
                    (data.rating_scale_id == 1 || data.rating_scale_id == 9) ? (
                      <td className="col-2 fw-bold">Short Term Rating</td>
                    ) : (
                      null
                    )}
                    <td className="col-1 fw-bold">Outlook</td>
                    <td className="col-1 fw-bold">Action</td>
                    <td className="col-2 fw-bold">Criticality Factor</td>
                    <td className="col-1 fw-bold">Decision</td>
                    <td className="col-1 fw-bold">Rating Watch</td>
                  </tr>
                </>
              )}
              {data &&
                data.history &&
                data.history.map((value, index) => {
                  if (data.rating_scale_id == 14) {
                    return (
                      <tr>
                        <td className="col-2">
                          {DateFormat(value.Dissemination)}
                        </td>
                        <td className="col-2">{value.RatingLT}</td>
                        <td className="col-2">{value.RatingAction}</td>
                      </tr>
                    );
                  }
                  else if (data.rating_scale_id == 7) {
                    return(
                      <tr>
                        <td className="col-1">
                          {DateFormat(value.Dissemination)}
                        </td>
                        <td className="col-1">{value.RatingST}</td>
                        <td className="col-1">{value.RatingLT}</td>
                        <td className="col-1">
                            {value.Rating5yr !== null ? value.Rating5yr : "-"}
                        </td>
                        <td className="col-1">{value.st_action}</td>
                        <td className="col-1">{value.RatingAction}</td>
                        <td className="col-1">{value.yr5_action}</td>
                        <td className="col-1">{value.fcf}</td>
                        <td className="col-1">{value.rcd}</td>
                      </tr>
                    )
                  }
                  else if (data.rating_scale_id == 1 || data.rating_scale_id == 9){
                    return (
                      <tr>
                        <td className="col-2">
                          {DateFormat(value.Dissemination)}
                        </td>
                        <td className="col-2">{value.RatingLT}</td>
                        <td className="col-2">{value.RatingST}</td>
                        <td className="col-1">{value.Outlook}</td>
                        <td className="col-2">{value.RatingAction}</td>
                        <td className="col-1">{value.fcf}</td>
                        <td className="col-1">{value.rcd}</td>
                        <td className="col-1">{value.rw}</td>
                      </tr>
                    );
                  }
                  else{
                    return (
                      <tr>
                        <td className="col-2">
                          {DateFormat(value.Dissemination)}
                        </td>
                        <td className="col-2">{value.RatingLT}</td>
                        <td className="col-1">{value.Outlook}</td>
                        <td className="col-2">{value.RatingAction}</td>
                        <td className="col-1">{value.fcf}</td>
                        <td className="col-1">{value.rcd}</td>
                        <td className="col-1">{value.rw}</td>
                      </tr>
                    );
                  }
                })}
            </tbody>
          </table>
        </div>
        <br />
        <div className="card shadow col-11 mx-auto p-2 border border-dark" style={{backgroundColor: "whitesmoke"}}>
          <table className="table newtable">
            <tbody>
              <tr>
                <td
                  className="fw-bold"
                  style={{ color: "#000078", fontFamily: "Kalinga" }}
                >
                  Model Rating
                </td>
                <td>
                  <input value={data && data.ttc && data.ttc} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />

        <div className="card shadow col-11 mx-auto p-2 border border-dark" style={{ overflow: "auto", backgroundColor: "whitesmoke" }}>
          { data && data.rating_scale_id !== 7 ?
            <table
              className="table newtable"
              style={props.screenWidth < 500 ? { fontFamily: "Kalinga", width: "500px" } : { fontFamily: "Kalinga" }}
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Proposed</th>
                  <th>Final</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {data.rating_scale_id == 1 || data.rating_scale_id == 9 ?
                  <td className="fw-bold">Long Term Action</td> : <td className="fw-bold">Action</td>}
                  <td>
                    <select
                      className="form-select"
                      id="ltaction_proposed"
                      onChange={(e) => {
                        setlterm_id(e.target.value);
                        let select = document.getElementById("lterm1");
                        let selectsterm = document.getElementById("sterm1");
                        let selectpoutlook = document.getElementById("outlook_proposed");
                        let selectRW1 = document.getElementById("rw1");
                        let selectCF1 = document.getElementById("cf1");

                        if (e.target.value == 3) {
                          setP_sterm_state([]);
                          setaction_state(3);
                            if (ltermFintensity !== null) {
                              select.value = Flterm;
                            }
                            if (PSterm !== null) {
                              selectsterm.value = Number(FSterm);
                            }

                            if (Poutlook !== null) {
                              selectpoutlook.value = Foutlook;
                            }

                            if(data.rating_action !== null){
                              if (data.rating_action.final_rating_watch !== null) {
                                  if(data.rating_action.final_rating_watch == '-'){
                                    selectRW1.value = 'NO'
                                  }
                                  else{
                                    selectRW1.value = data.rating_action.final_rating_watch;
                                  }
                              }

                              if (
                                data.rating_action.final_criticality_factor !==
                                null
                              ) {
                                selectCF1.value =
                                  data.rating_action.final_criticality_factor;
                              }
                          }
                        } else if(e.target.value == 1 || e.target.value == 2){
                          setaction_state(null);
                          select.value = 0;
                        }
                        else{
                          setaction_state(null);
                        }
                      }}
                    >
                      {(r_updateType != "5" || (r_updateType == "5" && (data.rating_scale_id == 25 || data.rating_scale_id == 27))) && <option value={0}> {data.rating_scale_id == 1 || data.rating_scale_id == 9 ? 'Select Proposed Long Term Action' : 'Select Proposed Action'}</option>}
                      {data &&
                        data.actions &&
                        data.actions.map((value, index) => {
                          if(r_updateType == "5"){
                              if(data.rating_scale_id != 9 && data.rating_scale_id != 25 && data.rating_scale_id != 27 && value.title == 'Initial'){
                                return (
                                  <option id={value.id} value={value.id}>
                                    {value.title}
                                  </option>
                                );
                              }
                              else if((data.rating_scale_id == 25 || data.rating_scale_id == 27) && (value.title == 'Initial' || value.title == 'Preliminary')){
                                return (
                                  <option id={value.id} value={value.id}>
                                    {value.title}
                                  </option>
                                );
                              }
                              else if(data.rating_scale_id == 9 && value.title == 'Preliminary'){
                                return (
                                  <option id={value.id} value={value.id}>
                                    {value.title}
                                  </option>
                                );
                              }
                          }
                          else{
                            return (
                              <option id={value.id} value={value.id}>
                                {value.title}
                              </option>
                            );
                          }
                          
                        })}
                    </select>
                  </td>
                  
                  <td>
                    <select
                      className="form-select"
                      id="ltaction_final"
                      onChange={(e) => {
                        setlterm_id2(e.target.value);
                        let select = document.getElementById("lterm2");
                        let selectsterm = document.getElementById("sterm2");
                        let selectfoutlook = document.getElementById("outlook_final");
                        let selectRW2 = document.getElementById("rw2");
                        let selectCF2 = document.getElementById("cf2");
                        if (e.target.value == 3) {
                            setaction_state2(3);
                            if (ltermFintensity !== null) {
                              select.value = Flterm;
                            }
                            if (FSterm !== null) {
                              selectsterm.value = Number(FSterm);
                            }

                            if (Foutlook !== null) {
                              selectfoutlook.value = Foutlook;
                            }
                          if(data.rating_action !== null){  
                            if (data.rating_action.final_rating_watch !== null) {
                                if(data.rating_action.final_rating_watch == '-'){
                                  selectRW2.value = 'NO';
                                }
                                else{
                                  selectRW2.value = data.rating_action.final_rating_watch;
                                }
                            }
                            if (
                              data.rating_action.final_criticality_factor !== null
                            ) {
                              selectCF2.value =
                                data.rating_action.final_criticality_factor;
                            }
                          }
                        } else if(e.target.value == 1 || e.target.value == 2){
                          setaction_state2(null);
                          select.value = 0;
                        }
                        else{
                          setaction_state2(null);
                        }

                        if(e.target.value == 0){
                          setdes([{value: '-', option: '-'}, {value: 'Consensus', option: ' Consensus'}, {value: 'Majority', option: 'Majority'}])
                        }
                        else{
                          setdes([{value: 'Consensus', option: ' Consensus'}, {value: 'Majority', option: 'Majority'}])
                        }
                      }}
                    >
                      {r_updateType != "5" && <option value={0}> {data.rating_scale_id == 1 || data.rating_scale_id == 9 ? 'Select Final Long Term Action' : 'Select Final Action'}</option>}
                      {data &&
                        data.actions &&
                        data.actions.map((value, index) => {
                          if(r_updateType == "5"){
                            if(data.rating_scale_id != 9 && data.rating_scale_id != 25 && data.rating_scale_id != 27 && value.title == 'Initial'){
                              return (
                                <option id={value.id} value={value.id}>
                                  {value.title}
                                </option>
                              );
                            }
                            else if((data.rating_scale_id == 25 || data.rating_scale_id == 27) && (value.title == 'Initial' || value.title == 'Preliminary')){
                              return (
                                <option id={value.id} value={value.id}>
                                  {value.title}
                                </option>
                              );
                            }
                            else if(data.rating_scale_id == 9 && value.title == 'Preliminary'){
                              return (
                                <option id={value.id} value={value.id}>
                                  {value.title}
                                </option>
                              );
                            }
                        }
                        else{
                          return (
                            <option id={value.id} value={value.id}>
                              {value.title}
                            </option>
                          );
                        }
                        })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold">
                    {data && (data.rating_scale_id == 1 || data.rating_scale_id == 9) ? "Long Term Rating" : "Rating"}
                  </td>
                  <td>
                    <select className="form-select" id="lterm1" onChange={(e)=>{
                      if(data.rating_scale_id == 1){
                        for(let i in data.longterm){
                          if(data.longterm[i].id == e.target.value){
                            let strem = data.longterm[i].sterm_id;
                            let strem_array = [];
                            if(strem !== null){
                              strem_array = strem.split(",").map(Number);
                              document.getElementById("sterm1").value = strem_array[0];
                            }
                            else{
                              document.getElementById("sterm1").value = 0
                            }
                            setP_sterm_state(strem_array);
                          }
                        }
                      }

                    }}>
                      <option value={0} disabled={lterm_id == 3 ? true : false}> {data.rating_scale_id == 1 || data.rating_scale_id == 9 ? 'Select Proposed Long Term Rating' : 'Select Proposed Rating'}</option>
                      {data &&
                        data.longterm &&
                        data.longterm.map((value, index) => {
                          return (
                            <option
                              id={value.id}
                              value={value.id}
                              disabled={
                                lterm_id == 1 &&  data.rating_action !== null &&
                                value.intensity <= ltermFintensity
                                  ? true
                                  : lterm_id == 2 && data.rating_action !== null &&
                                    value.intensity >= ltermFintensity
                                  ? true
                                  : lterm_id == 3 && data.rating_action !== null &&
                                    ltermFintensity !== null &&
                                    value.intensity != ltermFintensity
                                  ? true
                                  : false
                              }
                              className="highlighted-option"
                            >
                              {value.title}
                            </option>
                          );
                        })}
                    </select>
                  </td>
                  <td>
                    <select className="form-select" id="lterm2" onChange={(e)=>{
                      if(data.rating_scale_id == 1){
                        for(let i in data.longterm){
                          if(data.longterm[i].id == e.target.value){
                            let strem = data.longterm[i].sterm_id;
                            let strem_array = [];
                            if(strem !== null){
                              strem_array = strem.split(",").map(Number);
                              document.getElementById("sterm2").value = strem_array[0];
                            }
                            else{
                              document.getElementById("sterm2").value = 0
                            }
                            setF_sterm_state(strem_array);
                          }
                        }
                      }
                    }}>
                      <option value={0}>{data.rating_scale_id == 1 || data.rating_scale_id == 9 ? 'Select Final Long Term Rating' : 'Select Final Rating'}</option>
                      {data &&
                        data.longterm &&
                        data.longterm.map((value, index) => {
                          return (
                            <option
                              id={value.id}
                              value={value.id}
                              disabled={
                                lterm_id2 == 1 && data.rating_action !== null &&
                                value.intensity <= ltermFintensity
                                  ? true
                                  : lterm_id2 == 2 && data.rating_action !== null &&
                                    value.intensity >= ltermFintensity
                                  ? true
                                  : lterm_id2 == 3 && data.rating_action !== null &&
                                    ltermFintensity !== null &&
                                    value.intensity != ltermFintensity
                                  ? true
                                  : false
                              }
                              className="highlighted-option"
                            >
                              {value.title}
                            </option>
                          );
                        })}
                    </select>
                  </td>
                </tr>
                {data && data.rating_scale_id == 7 && (
                  <tr>
                    <td className="fw-bold">5 Year</td>
                    <td>
                      <select className="form-select" id="5lterm1">
                        <option value={0}> Select Proposed 5 Year</option>
                        {data &&
                          data.longterm &&
                          data.longterm.map((value, index) => {
                            return (
                              <option
                                id={value.id}
                                value={value.id}
                                className="highlighted-option"
                              >
                                {value.title}
                              </option>
                            );
                          })}
                      </select>
                    </td>
                    <td>
                      <select className="form-select" id="5lterm2" >
                        <option value={0}> Select Final 5 Year</option>
                        {data &&
                          data.longterm &&
                          data.longterm.map((value, index) => {
                            return (
                              <option
                                id={value.id}
                                value={value.id}
                                className="highlighted-option"
                              >
                                {value.title}
                              </option>
                            );
                          })}
                      </select>
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="fw-bold">Outlook</td>
                  <td>
                    <select className="form-select" id="outlook_proposed">
                      <option value={0} disabled={lterm_id == 3 ? true : false}>Select Proposed Outlook</option>
                      {data &&
                        data.outlook &&
                        data.outlook.map((value, index) => {
                          return (
                            <option id={value.id} value={value.id}>
                              {value.title}
                            </option>
                          );
                        })}
                    </select>
                  </td>
                  <td>
                    <select className="form-select" id="outlook_final" >
                      <option value={0}>Select Final Outlook</option>
                      {data &&
                        data.outlook &&
                        data.outlook.map((value, index) => {
                          return (
                            <option id={value.id} value={value.id}>
                              {value.title}
                            </option>
                          );
                        })}
                    </select>
                  </td>
                </tr>

                <tr id="staction" hidden>
                  <td className="fw-bold">Short Term Action</td>
                  <td>
                    <select className="form-select" id="staction_proposed" defaultValue={r_updateType == "5" ? 4 : 0}>
                    {r_updateType != "5" && <option value={0}> Select Proposed Short Term Actions</option>}
                      {data &&
                        data.actions &&
                        data.actions.map((value, index) => {
                          if(r_updateType == "5"){
                            if(data.rating_scale_id != 9 && value.title == 'Initial'){
                              return (
                                <option id={value.id} value={value.id}>
                                  {value.title}
                                </option>
                              );
                            }
                            else if(data.rating_scale_id == 9 && value.title == 'Preliminary'){
                              return (
                                <option id={value.id} value={value.id}>
                                  {value.title}
                                </option>
                              );
                            }
                        }
                        else{
                          return (
                            <option id={value.id} value={value.id}>
                              {value.title}
                            </option>
                          );
                        }
                        })}
                    </select>
                  </td>
                  <td>
                    <select className="form-select" id="staction_final" defaultValue={r_updateType == "5" ? 4 : 0}>
                    {r_updateType != "5" && <option value={0}> Select Final Short Term Action</option> }
                      {data &&
                        data.actions &&
                        data.actions.map((value, index) => {
                          if(r_updateType == "5"){
                            if(data.rating_scale_id != 9 && value.title == 'Initial'){
                              return (
                                <option id={value.id} value={value.id}>
                                  {value.title}
                                </option>
                              );
                            }
                            else if(data.rating_scale_id == 9 && value.title == 'Preliminary'){
                              return (
                                <option id={value.id} value={value.id}>
                                  {value.title}
                                </option>
                              );
                            }
                        }
                        else{
                          return (
                            <option id={value.id} value={value.id}>
                              {value.title}
                            </option>
                          );
                        }
                        })}
                    </select>
                  </td>
                </tr>
                <tr id="sterm" hidden>
                  <td className="fw-bold">
                    {data && data.rating_scale_id == 7 ? "1 year" : "Short Term Rating"}
                  </td>
                  <td>
                    <select className="form-select" id="sterm1">
                      {data.rating_scale_id == 1 ? p_sterm_state.length == 0 && <option value={0} disabled={lterm_id == 3 ? true : false}>Select Proposed Short Term Rating</option> : <option value={0}>Select Proposed Short Term Rating</option>}
                      {data &&
                        data.shortterm &&
                        data.shortterm.map((value, index) => {
                                return (
                                  <option className="highlighted-option" id={value.id} value={value.id} disabled={data.rating_scale_id == 1 ? lterm_id !== 3 ? p_sterm_state.length > 0 && p_sterm_state.includes(value.id) ? false : true : true : false}>
                                    {value.title}
                                  </option>
                                );
                        }) 

                      }
                    </select>
                  </td>
                  <td>
                    <select className="form-select" id="sterm2">
                    {data.rating_scale_id == 1 ? f_sterm_state.length == 0 && <option value={0} disabled={lterm_id2 == 3 ? true : false}>Select Final Short Term Rating</option> : <option value={0}>Select Final Short Term Rating</option>}
                      {data &&
                        data.shortterm &&
                        data.shortterm.map((value, index) => {
                                return (
                                  <option className="highlighted-option" id={value.id} value={value.id} disabled={data.rating_scale_id == 1 ? lterm_id2 !== 3 ? f_sterm_state.length > 0 && f_sterm_state.includes(value.id) ? false : true : true : false}>
                                    {value.title}
                                  </option>
                                );
                        }) 

                      }
                    </select>
                  </td>
                </tr>
                <tr>
                <td className="fw-bold">Rating Watch</td>
                <td>
                  <select className="form-select" id="rw1">
                    <option value={0}> Select </option>
                    <option value={"YES"}> Yes </option>
                    <option value={"NO"}> No </option>
                  </select>
                </td>
                <td>
                  <select className="form-select" id="rw2" >
                    <option value={0}> Select </option>
                    <option value={"YES"}> Yes </option>
                    <option value={"NO"}> No </option>
                  </select>
                </td>
              </tr>
                <tr>
                  <td className="fw-bold">Criticality Factor</td>
                  <td>
                    <select className="form-select" id="cf1">
                      <option value={0}>Select Proposed CF</option>
                      {data &&
                        data.criticality_factor &&
                        data.criticality_factor.map((value, index) => {
                          return (
                            <option value={value.id}> {value.title} </option>
                          );
                        })}
                    </select>
                  </td>
                  <td>
                    <select className="form-select" id="cf2" >
                      <option value={0}>Select Final CF</option>
                      {data &&
                        data.criticality_factor &&
                        data.criticality_factor.map((value, index) => {
                          return (
                            <option value={value.id}> {value.title} </option>
                          );
                        })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold">Decision</td>
                  <td></td>
                  <td>
                    <select className="form-select" id="decision">
                      {
                        des.map((value, index)=>{
                          return(
                            <option value={value.value}> {value.option} </option>
                          )
                        })
                      }
                    </select>
                  </td>
                </tr>
              </tbody>
            </table> :
            <table
            className="table newtable"
            style={props.screenWidth < 500 ? { fontFamily: "Kalinga", width: "500px" } : { fontFamily: "Kalinga" }}
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Proposed</th>
                <th>Final</th>
              </tr>
            </thead>
            <tbody>
            <tr id="staction" hidden>
                <td className="fw-bold">1-Year Action</td>
                <td>
                  <select className="form-select" id="staction_proposed">
                  {r_updateType != "5" && <option value={0}> Select Proposed 1-Year Action</option>}
                    {data &&
                      data.actions &&
                      data.actions.map((value, index) => {
                        if(r_updateType == "5"){
                          if(value.title == 'Initial'){
                        return (
                          <option id={value.id} value={value.id}>
                            {value.title}
                          </option>
                        );}
                        }
                        else{
                          return (
                            <option id={value.id} value={value.id}>
                              {value.title}
                            </option>
                          );
                        }
                      })}
                  </select>
                </td>
                <td>
                  <select className="form-select" id="staction_final" onChange={(e)=>{
                    if(e.target.value == 0){
                      setdes([{value: '-', option: '-'}, {value: 'Consensus', option: ' Consensus'}, {value: 'Majority', option: 'Majority'}])
                    }
                    else{
                      setdes([{value: 'Consensus', option: ' Consensus'}, {value: 'Majority', option: 'Majority'}])
                    }
                  }}>
                  {r_updateType != "5" && <option value={0}> Select Final 1-Year Action</option>}
                    {data &&
                      data.actions &&
                      data.actions.map((value, index) => {
                        if(r_updateType == "5"){
                          if(value.title == 'Initial'){
                        return (
                          <option id={value.id} value={value.id}>
                            {value.title}
                          </option>
                        );}
                        }
                        else{
                          return (
                            <option id={value.id} value={value.id}>
                              {value.title}
                            </option>
                          );
                        }
                      })}
                  </select>
                </td>
              </tr>
              <tr>
                <td className="fw-bold">3-Year Action</td>
                <td>
                  <select
                    className="form-select"
                    id="ltaction_proposed"
                    defaultValue={r_updateType == "5" ? 4 : 0}
                    onChange={(e) => {
                      setlterm_id(e.target.value);
                    }}
                  >
                    {r_updateType != "5" && <option value={0}> Select Proposed 3-Year Action</option>}
                    {data &&
                      data.actions &&
                      data.actions.map((value, index) => {
                        if(r_updateType == "5"){
                            if(value.title == 'Initial'){
                              return (
                                <option id={value.id} value={value.id}>
                                  {value.title}
                                </option>
                              );
                            }
                        }
                        else{
                          return (
                            <option id={value.id} value={value.id}>
                              {value.title}
                            </option>
                          );
                        }
                        
                      })}
                  </select>
                </td>
                
                <td>
                  <select
                    className="form-select"
                    id="ltaction_final"
                    defaultValue={r_updateType == "5" ? 4 : 0}
                    onChange={(e) => {
                      setlterm_id2(e.target.value);
                    }}
                  >
                    {r_updateType != "5" && <option value={0}> Select Final 3-Year Action</option>}
                    {data &&
                      data.actions &&
                      data.actions.map((value, index) => {
                        if(r_updateType == "5"){
                          if(value.title == 'Initial'){
                            return (
                              <option id={value.id} value={value.id}>
                                {value.title}
                              </option>
                            );
                          }
                      }
                      else{
                        return (
                          <option id={value.id} value={value.id}>
                            {value.title}
                          </option>
                        );
                      }
                      })}
                  </select>
                </td>
              </tr>
              <tr>
                <td className="fw-bold">5-Year Action</td>
                <td>
                  <select
                    className="form-select"
                    id="5yearaction_proposed"
                    defaultValue={r_updateType == "5" ? 4 : 0}
                    onChange={(e) => {
                      setlterm_id(e.target.value);
                    }}
                  >
                    {r_updateType != "5" && <option value={0}> Select Proposed 5-Year Action</option>}
                    {data &&
                      data.actions &&
                      data.actions.map((value, index) => {
                        if(r_updateType == "5"){
                            if(value.title == 'Initial'){
                              return (
                                <option id={value.id} value={value.id}>
                                  {value.title}
                                </option>
                              );
                            }
                        }
                        else{
                          return (
                            <option id={value.id} value={value.id}>
                              {value.title}
                            </option>
                          );
                        }
                        
                      })}
                  </select>
                </td>
                
                <td>
                  <select
                    className="form-select"
                    id="5yearaction_final"
                    defaultValue={r_updateType == "5" ? 4 : 0}
                    onChange={(e) => {
                      setlterm_id2(e.target.value);
                    }}
                  >
                    {r_updateType != "5" && <option value={0}> Select Final 5-Year Action</option>}
                    {data &&
                      data.actions &&
                      data.actions.map((value, index) => {
                        if(r_updateType == "5"){
                          if(value.title == 'Initial'){
                            return (
                              <option id={value.id} value={value.id}>
                                {value.title}
                              </option>
                            );
                          }
                      }
                      else{
                        return (
                          <option id={value.id} value={value.id}>
                            {value.title}
                          </option>
                        );
                      }
                      })}
                  </select>
                </td>
              </tr>
              <tr id="sterm" hidden>
                <td className="fw-bold">
                  1 year
                </td>
                <td>
                  <select className="form-select" id="sterm1">
                    <option value={0}>Select Proposed 1-Year Rating</option>
                    {data &&
                      data.shortterm &&
                      data.shortterm.map((value, index) => {
                        return (
                          <option value={value.id}> {value.title} </option>
                        );
                      })}
                  </select>
                </td>
                <td>
                  <select className="form-select" id="sterm2" >
                    <option value={0}>Select Final 1-Year Rating</option>
                    {data &&
                      data.shortterm &&
                      data.shortterm.map((value, index) => {
                        return (
                          <option value={value.id}> {value.title} </option>
                        );
                      })}
                  </select>
                </td>
              </tr>
              <tr>
                <td className="fw-bold">
                  3 year
                </td>
                <td>
                  <select className="form-select" id="lterm1">
                    <option value={0}> Select Proposed 3-Year Rating</option>
                    {data &&
                      data.longterm &&
                      data.longterm.map((value, index) => {
                        return (
                          <option
                            id={value.id}
                            value={value.id}
                            disabled={
                              lterm_id == 1 &&  data.rating_action !== null &&
                              value.intensity <= ltermPintensity
                                ? true
                                : lterm_id == 2 && data.rating_action !== null &&
                                  value.intensity >= ltermPintensity
                                ? true
                                : lterm_id == 3 && data.rating_action !== null &&
                                  ltermPintensity !== null &&
                                  value.intensity != ltermPintensity
                                ? true
                                : false
                            }
                            className="highlighted-option"
                          >
                            {value.title}
                          </option>
                        );
                      })}
                  </select>
                </td>
                <td>
                  <select className="form-select" id="lterm2" >
                    <option value={0}> Select Final 3-Year Rating</option>
                    {data &&
                      data.longterm &&
                      data.longterm.map((value, index) => {
                        return (
                          <option
                            id={value.id}
                            value={value.id}
                            disabled={
                              lterm_id2 == 1 && data.rating_action !== null &&
                              value.intensity <= ltermFintensity
                                ? true
                                : lterm_id2 == 2 && data.rating_action !== null &&
                                  value.intensity >= ltermFintensity
                                ? true
                                : lterm_id2 == 3 && data.rating_action !== null &&
                                  ltermFintensity !== null &&
                                  value.intensity != ltermFintensity
                                ? true
                                : false
                            }
                            className="highlighted-option"
                          >
                            {value.title}
                          </option>
                        );
                      })}
                  </select>
                </td>
              </tr>

                <tr>
                  <td className="fw-bold">5 Year</td>
                  <td>
                    <select className="form-select" id="5lterm1">
                      <option value={0}> Select Proposed 5-Year Rating</option>
                      {data &&
                        data.longterm &&
                        data.longterm.map((value, index) => {
                          return (
                            <option
                              id={value.id}
                              value={value.id}
                              className="highlighted-option"
                            >
                              {value.title}
                            </option>
                          );
                        })}
                    </select>
                  </td>
                  <td>
                    <select className="form-select" id="5lterm2" >
                      <option value={0}> Select Final 5-Year Rating</option>
                      {data &&
                        data.longterm &&
                        data.longterm.map((value, index) => {
                          return (
                            <option
                              id={value.id}
                              value={value.id}
                              className="highlighted-option"
                            >
                              {value.title}
                            </option>
                          );
                        })}
                    </select>
                  </td>
                </tr>


              <tr>
                <td className="fw-bold">Criticality Factor</td>
                <td>
                  <select className="form-select" id="cf1">
                    <option value={0}>Select Proposed CF</option>
                    {data &&
                      data.criticality_factor &&
                      data.criticality_factor.map((value, index) => {
                        return (
                          <option value={value.id}> {value.title} </option>
                        );
                      })}
                  </select>
                </td>
                <td>
                  <select className="form-select" id="cf2" >
                    <option value={0}>Select Final CF</option>
                    {data &&
                      data.criticality_factor &&
                      data.criticality_factor.map((value, index) => {
                        return (
                          <option value={value.id}> {value.title} </option>
                        );
                      })}
                  </select>
                </td>
              </tr>
              <tr>
                <td className="fw-bold">Decision</td>
                <td></td>
                <td>
                  <select className="form-select" id="decision">
                    {
                      des.map((value, index)=>{
                        return(
                          <option value={value.value}> {value.option} </option>
                        )
                      })
                    }
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
          }
        </div>

        <div className="col-lg-2 col-md-2 col-sm-2 col-4 mx-auto mt-3">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            style={{ width: "100%" }}
          >
            Next
          </button>
        </div>
        <>
          <div
            className="modal fade mt-5"
            id="staticBackdrop"
            tabIndex="-1"
            aria-labelledby="staticBackdrop"
            aria-hidden="true"
            style={{ height: "90%" }}
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <>
                  <Box className="mx-auto" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                      <Alert
                        severity={alertType}
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setalertopen(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                        sx={{ mb: 2 }}
                      >
                        {AlertMessage}
                      </Alert>
                    </Collapse>
                  </Box>
                </>
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Feed Back!
                  </h5>
                </div>
                <div className="modal-body">
                  <div>
                    <label>Ownership:</label>
                  </div>
                  <div>
                    <textarea className="form-control" id="Ownership">
                      N/A
                    </textarea>
                  </div>
                  <div>
                    <label>Governance:</label>
                  </div>
                  <div>
                    <textarea className="form-control" id="Governance">
                      N/A
                    </textarea>
                  </div>
                  <div>
                    <label>Management:</label>
                  </div>
                  <div>
                    <textarea className="form-control" id="Management">
                      N/A
                    </textarea>
                  </div>
                  <div>
                    <label>Business Risk:</label>
                  </div>
                  <div>
                    <textarea className="form-control" id="Business Risk">
                      N/A
                    </textarea>
                  </div>
                  <div>
                    <label>Financial Risk:</label>
                  </div>
                  <div>
                    <textarea className="form-control" id="Financial Risk">
                      N/A
                    </textarea>
                  </div>
                  <div>
                    <label>Other:</label>
                  </div>
                  <div>
                    <textarea className="form-control" id="Other">
                      N/A
                    </textarea>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    id="modelclose"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    <div id="submit">Submit</div> <div id="submit_load" hidden> <CircularProgress size={15} /> </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
        <br />
      </form>
      <br />
      <br />
    </div>
  );
}
