import React, {useRef, useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import banner from '../../../Assets/Images/newBanner.png';
import facebook from '../../../Assets/Images/facebook.png';
import linkdin from '../../../Assets/Images/linkdin.png';
import white from '../../../Assets/Images/whitebg.jpg';
import { url } from '../../../Config';


export default function Regulator_email(props) {

    const [data, setdata] = useState([]);

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/get_Test_Mail`);
            const resp = res.data.data;
            setdata(resp);
        }

        func();
    },[])

    const getdate = ()=>{

        const currentDate = new Date();
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(currentDate);
        return formattedDate;
    }
  return (
    <div>
      <div className='mt-2 p-2 card shadow' style={{backgroundColor: "#e0e0e0", backgroundImage: `url(${white})`, backgroundSize: "cover"}}>
        <div className='row'>
            <div className='col-7 fs-4 mt-2 fw-bold text-end'>{getdate()}</div>
            <div className='col-5 d-flex justify-content-end'>
                <a href='https://www.linkedin.com/company/pakistan-credit-rating-agency-pacra-/'><img src={linkdin} style={{width: 50, height: 50}} /></a>
                <a href='https://www.facebook.com/pacra.pk/'><img src={facebook} style={{width: 50, height: 50}} /></a>
            </div>
        </div>
        <img src={banner} width="100%" height="329" />
      </div>
      {
        data.map((value, index)=>{
            return(
                <div className='mt-2 p-2 card shadow p-2' style={{backgroundColor: "#e0e0e0", backgroundImage: `url(${white})`, backgroundSize: "cover"}}>
                    <div>
                        {value.industry1}
                        <a className='ms-2' href={`http://www.pacra.com/sector_research/${value.ssfile}`} target='_blank'>(Sector Study)</a>
                    </div>
                    {
                        value.summery_report_has_upload !== null ?
                        <div>
                        {value.Entity}
                        <a className='ms-2' href={`http://www.pacra.com/summary_report/${value.SummaryReport}`} target='_blank'>(Rating Report)</a>
                        </div> : 
                        <div> {value.Entity} </div>
                    }
                    {
                        <div>
                            {value.rating_title}
                            {
                            value.PressRelease == null ? '-' :
                            <a className='ms-2' href={value.PressRelease} target='_blank'>(Press Release)</a>
                            }
                        </div>
                      
                    }
                    {
                        <div>
                            {value.Rating_Scale == 'Broker Management Rating' ?
                            <>
                            BM Rating: {value.RatingLT} | Outlook: {value.Outlook} <a className='ms-2' href={`http://www.pacra.com/client_history/${value.ID}`} target='_blank'>(Rating History)</a>
                            </> :
                            <>
                            Long-term: {value.RatingLT} | Short-term: {value.RatingST} | Outlook: {value.Outlook} <a className='ms-2' href={`http://www.pacra.com/client_history/${value.ID}`} target='_blank'>(Rating History)</a>
                            </>}
                        </div>
                    }
                </div>
            )
        })
      }
      <div className='mt-2 p-3' style={data.length > 0 ? {backgroundColor: '#000078'} : {bottom: 0, position: "fixed", zIndex: 999, width: "100%", backgroundColor: '#000078'}}>
        <div className='text-center' style={{color: 'white'}}>
            For more details visit <a href="http://www.pacra.com/" style={{color: 'white'}}>www.PACRA.com</a>
        </div>
        <div className='text-center fw-bold' style={{color: 'white'}}>
            Its All About Ratings
        </div>
        <div className='text-center fw-bold' style={{color: 'white'}}>
            FB1 Awami Complex Usman Block, New Garden Town Lahore
                +92 42 3586 9504 - 6
        </div>
      </div>
    </div>
  )
}
