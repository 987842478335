import React, { useState, useEffect } from "react";
import GetData from "../API/GetData";
import SideBar from "./Atoms/SideBar";
// import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Routs/Home/index";
import Ratings from "./Routs/Ratings";
import Research from "./Routs/Research";
import BusinessDevelopment from "./Routs/BusinessDevelopment";
import InputData from "./Routs/InputData";
import OpinionDetails from "./Routs/OpinionDetails/index";
import ClientInfo from "./Routs/ClientInfo";
import Opinion from "./Routs/Opinion";
import SearchOpinion from "./Routs/SearchOpinion";
import Reports from "./Routs/Reports";
import axios from "axios";
import PacVis from "./Routs/PacVis";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { height } from "@mui/system";
import opinion_data from "./Data/opinion_data.json";
import outstanding_data from "./Data/outstanding_data.json";
import Form from "./Routs/Forms/Form";
import ReportsTableComponent from "./Routs/Reports/ReportsTableComponent";
import Information from "./Routs/Ratings/Information";
import Assessment from "./Routs/Ratings/assessment";
import SiteVisit from "./Routs/Ratings/siteVisit";
import MM from "./Routs/Ratings/MM";
import RED from "./Routs/Ratings/RED";
import RC from "./Routs/Ratings/RC";
import RcApproval from "./Routs/Ratings/RC/RcApproval";
import PPL from "./Routs/Ratings/PPL";
import NL from "./Routs/Ratings/NL";
import Acceptence_undertaking from "./Routs/Ratings/undertaking/Acceptence_undertaking";
import AssessmentReport from "./Routs/Ratings/Assessment/report";
import PreviewAssessment from "./Routs/Ratings/Assessment/preview";
import RcCoverApproval from "./Routs/Ratings/RC/RcCoverApproval";
import EditRcCover from "./Routs/Ratings/RC/EditRcCover";
import Generateppl from "./Routs/Ratings/PPL/generateppl";
import Editppl from "./Routs/Ratings/PPL/editppl";
import RR_krd from "./Routs/Ratings/PPL/rr_krd";
import Bfr from "./Routs/Ratings/PPL/bfr";
import Perf_rank from "./Routs/Ratings/PPL/perf_rank";
import Edit_perf_rank from "./Routs/Ratings/PPL/edit_perf_rank";
import CloseInternally from "./Routs/Ratings/PPL/closeInternally";
import Editreview from "./Routs/Ratings/PPL/editreview";
import Editmodelview from "./Routs/Ratings/Assessment/editmodelview";
import bg from "../Assets/Images/greyModel.jpg"
import Coverpage from "./Routs/PDF/coverpage";
import GenerateNL from "./Routs/Ratings/NL/generateNL";
import EditNL from "./Routs/Ratings/NL/editNL";
import RR from "./Routs/Ratings/RR";
import WizPacGraph from "./Routs/Graphs/WizPacGraph";
import Test from "./Routs/Ratings/test";
import EditModule from "./Routs/Ratings/EditModule/index";
import Cob_to_ratings from "./Routs/ClientTransfer/cob_to_ratings";
import Rating_to_cob from "./Routs/ClientTransfer/rating_to_cob";
import OpinionTableOne from "./Routs/OpinionDetails/OpinionTableOne";
import Opinion_transfer from "./Routs/ClientTransfer/opinion_transfer";
import Opinion_assessment from "./Routs/ClientTransfer/opinion_assessment";
import Opinion_transferlog from "./Routs/ClientTransfer/opinion_transferlog";
import Cob_to_rating_log from "./Routs/ClientTransfer/cob_to_rating_log";
import Ratings_to_cob_log from "./Routs/ClientTransfer/ratings_to_cob_log";
import PolicyandGuidlines from "./Routs/Compliance/p&g";
import Create_policy from "./Routs/Compliance/create_policy";
import Update from "./Routs/Ratings/EditModule/update";
import Edit_policy from "./Routs/Compliance/edit_policy";
import AddApplicablitiy from "./Routs/Compliance/addApplicablitiy";
import AddCategory from "./Routs/Compliance/addCategory";
import RegulatoryAffairs from "./Routs/Compliance/regulatoryAffairs.js";
import Policy_history from "./Routs/Compliance/policy_history";
import Sponsors from "./Routs/Compliance/SECP/sponsors";
import Directors from "./Routs/Compliance/SECP/directors";
import Criteria from "./Routs/Compliance/SECP/criteria";
import Rcreport from "./Routs/Compliance/SECP/rcreport";
import Secp from "./Routs/Compliance/SECP/secp";
import Addsponders from "./Routs/Compliance/SECP/SponsersAndShareholder/addsponders";
import Editsponsers from "./Routs/Compliance/SECP/SponsersAndShareholder/editsponsers";
import Viewsponsers from "./Routs/Compliance/SECP/SponsersAndShareholder/viewsponsers";
import AddDirector from "./Routs/Compliance/SECP/Directors/addDirector.js";
import ViewDirectorDetail from "./Routs/Compliance/SECP/Directors/viewDirectorDetail.js";
import EditDirector from "./Routs/Compliance/SECP/Directors/editDirector.js";
import AddCriteria from "./Routs/Compliance/SECP/CriteriaGroup/addCriteria.js";
import ViewCriteria from "./Routs/Compliance/SECP/CriteriaGroup/viewCriteria.js";
import EditCriteria from "./Routs/Compliance/SECP/CriteriaGroup/editCriteria.js";
import AddRC_Members from "./Routs/Compliance/SECP/RCMembers/addRC_Members.js";
import ViewRC_member from "./Routs/Compliance/SECP/RCMembers/viewRC_member.js";
import EditRC_Members from "./Routs/Compliance/SECP/RCMembers/editRC_Members.js";
import AddSecpReport from "./Routs/Compliance/SECP/SecpQtrReport/addSecpReport.js";
import EditSecpReport from "./Routs/Compliance/SECP/SecpQtrReport/editSecpReport.js";
import Change_in_software from "./Routs/Compliance/change_in_software.js";
import ConsultanciesReceived from "./Routs/Reports/consultanciesReceived.js";
import Consultancy_received from "./Routs/Compliance/consultancy_received.js";
import HR_training from "./Routs/Compliance/hr_training.js";
import Complaints from "./Routs/Compliance/complaints.js";
import Add_complaints from "./Routs/Compliance/add_complaints.js";
import Cob_to_icu_rip from "./Routs/ClientTransfer/cob_to_icu_rip.js";
import RipLetter from "./Routs/ClientTransfer/ripLetter.js";
import BD_dashboard from "./Routs/BusinessDevelopment/bd_dashboard.js";
import Mandate_new from "./Routs/BusinessDevelopment/Mandate/mandate_new.js";
import Mandate_revise from "./Routs/BusinessDevelopment/Mandate/mandate_revise.js";
import Mandate_received from "./Routs/BusinessDevelopment/Mandate/mandate_received.js";
import Edit_clientmandateInfo from "./Routs/BusinessDevelopment/Mandate/edit_clientmandateInfo.js";
import Client_entry_index from "./Routs/BusinessDevelopment/Client/client_entry_index.js";
import Add_new_client from "./Routs/BusinessDevelopment/Client/add_new_client.js";
import Edit_client from "./Routs/BusinessDevelopment/Client/edit_client.js";
import Editgroup from "./Routs/BusinessDevelopment/Mandate/editgroup.js";
import CobInvites from "./Routs/Home/Invites/CobInvites.js";
import Email_dashboard from "./Routs/MIT/email_dashboard.js";
import Regulator_email from "./Routs/MIT/regulator_email.js";
import Buisness_email from "./Routs/MIT/buisness_email.js";
import Sector_study_MIT from "./Routs/MIT/sector_study_MIT.js";
import Mandate_reminder from "./Routs/MIT/mandate_reminder.js";
import News_letter from "./Routs/MIT/news_letter.js";
import Ratings_jcr from "./Routs/BusinessDevelopment/VisModule/Forms/ratings_jcr.js";
import Jcr_client from "./Routs/BusinessDevelopment/VisModule/Forms/jcr_client.js";
import Old_sr_jcr from "./Routs/BusinessDevelopment/VisModule/Forms/old_sr_jcr.js";
import VisDashboard from "./Routs/BusinessDevelopment/VisModule/Forms"
import SS_grid from "./Routs/MIT/ss_grid.js";
import BDGrids from "./Routs/BusinessDevelopment/Grids";
import Criteria_dashboard from "./Routs/Criteria/criteria_dashboard.js";
import EmployeeList from "./Routs/Login/EmployeeList.js";
import VizGrid from "./Routs/BusinessDevelopment/VisModule/Forms/Grids"
import Model_dashboard from "./Routs/Criteria/Assessment Model/model_dashboard.js";
import CreateModel from "./Routs/Criteria/Assessment Model/createModel.js";
import PostCreateModel from "./Routs/Criteria/Assessment Model/postCreateModel.js";
import AddDescToHeadings from "./Routs/Criteria/Assessment Model/addDescToHeadings.js";
import EditModels from "./Routs/Criteria/Assessment Model/editModel.js";
import ApproveFormat from "./Routs/Criteria/Assessment Model/approveFormat.js";
import Opmappingv2 from "./Routs/Criteria/Assessment Model/opmappingv2.js";
import MethodologyGrid from "./Routs/Criteria/Methodology/index.js";
import View_methodology from "./Routs/Criteria/Methodology/view_methodology.js";
import Add_new from "./Routs/Criteria/Methodology/add_new.js";
import Edit_methodolody from "./Routs/Criteria/Methodology/edit_methodolody.js";
import ApproveMapping from "./Routs/Criteria/Assessment Model/approveMapping.js";
import Sector_study_research from "./Routs/Research/sector_study_research.js";
import Add_sector_study from "./Routs/Research/add_sector_study.js";
import Edit_sector_study from "./Routs/Research/edit_sector_study.js";
import View_format from "./Routs/Criteria/Assessment Model/view_format.js";
import Classification_dashboard from "./Routs/Research/classification_dashboard.js";
import Add_industry from "./Routs/Research/add_industry.js";
import Add_sector from "./Routs/Research/add_sector.js";
import Edit_industry from "./Routs/Research/edit_industry.js";
import Edit_sector_industry from "./Routs/Research/edit_sector_industry.js";
import Pacra_publication from "./Routs/Research/pacra_publication.js";
import AddPublication from "./Routs/Research/addPublication.js";
import EditPublication from "./Routs/Research/editPublication.js";
import Update_industry from "./Routs/Research/update_industry.js";
import Edit_industry_grid from "./Routs/Research/edit_industry_grid.js";
import Assessment_dashboard from "./Routs/Criteria/Assessment Model/assessment_dashboard.js";
import Vis_history from "./Routs/BusinessDevelopment/VisModule/Forms/vis_history.js";
import View_demo from "./Routs/Criteria/Assessment Model/view_demo.js";
import Research_dashboard from "./Routs/Research/research_dashboard.js";
import Edit_initiation_date from "./Routs/Ratings/edit_initiation_date.js";
import Edit_client_details from "./Routs/Ratings/edit_client_details.js";
import Methodologygridindex from "./Routs/Home/methodologygrid.js";
import ExternalDashboard from "./Routs/ExternalRcMembers/externalDashboard.js";
import Opinionsearchresult from "./Routs/OpinionDetails/opinionsearchresult.js";
import Mandetes_to_upload from "./Routs/BusinessDevelopment/Mandate/mandetes_to_upload.js";
import Bypass_dd_list from "./Routs/Ratings/NL/bypass_dd_list.js";
import Rc_logs from "./Routs/Ratings/RC/Rc_logs.js";
import Rc_logs_view from "./Routs/Ratings/RC/Rc_logs_view.js";
import Request_support from "./Routs/Ratings/SwitchPortfolio/TeamLeadView/request_support.js";
import Redirecting from "./Routs/Ratings/Redirecting/redirecting.js";
import Support_approvals from "./Routs/Ratings/SwitchPortfolio/CRO/support_approvals.js";
import Analyst_list from "./Routs/Ratings/SwitchPortfolio/RM/analyst_list.js";
import Analyst_detail from "./Routs/Ratings/SwitchPortfolio/RM/analyst_detail.js";
import Rm_portfolio_list from "./Routs/Ratings/SwitchPortfolio/RM/rm_portfolio_list.js";
import Assign_support_analyst from "./Routs/Ratings/SwitchPortfolio/RM/assign_support_analyst.js";
import Approve_tl_support from "./Routs/Ratings/SwitchPortfolio/TeamLeadView/approve_tl_support.js";
import Revertback_analyst from "./Routs/Ratings/SwitchPortfolio/TeamLeadView/revertback_analyst.js";

// const opinion_data = require("./Data/opinion_data.json");
// const outstanding_data = require("./Data/outstanding_data.json");

const useStyles = makeStyles((theme) => ({
  containerWidth: {
    width: `calc(100% - 240px)`,
    marginTop: "142px",
  },
  containerWidthResponsive: {
    width: `calc(100% - 240px)`,
    marginTop: "53px",
  },
  otherRoutsWidth: {
    width: `calc(100% - 240px)`,
    marginTop: "53px",
  },
  otherRoutsWidthResponsive: {
    width: `calc(100% - 240px)`,
    marginTop: "53px",
  },
}));
function Dashboard() {
  const [open, setOpen] = useState(false);
  const [isResponsive, setisResponsive] = useState(true);
  const [compliance, setcompliance] = useState(false);
  const [OutstandingDataArray, setOutstandingDataArray] =
    useState(outstanding_data);
  const [InProcessDataArray, setInProcessDataArray] =
    useState(outstanding_data);
  const [UnFinishedDataArray, setUnFinishedDataArray] =
    useState(outstanding_data);
  const [InHandBookDataArray, setInHandBookDataArray] = useState(opinion_data);
  const [GoneBookDataArray, setGoneBookDataArray] = useState(outstanding_data);
  const [InitialDataArray, setInitialDataArray] = useState(outstanding_data);
  const [InCobDataArray, setInCobDataArray] = useState(outstanding_data);
  const [InMNADataArray, setInMNADataArray] = useState(outstanding_data);
  const [InICUDataArray, setICUDataArray] = useState(outstanding_data);
  const [InRIPDataArray, setRIPDataArray] = useState(outstanding_data);
  const [WithdrawDataArray, setWithdrawDataArray] = useState(outstanding_data);
  const [SectorDataArray, setSectorDataArray] = useState(outstanding_data);
  const [MethodologyDataArray, setMethodologyDataArray] = useState(outstanding_data);
  const [B_DDataArray, setB_DDataArray] = useState(outstanding_data);
  const [InstDataArray, setInstDataArray] = useState(outstanding_data);
  const [InstPDataArray, setInstPDataArray] = useState(outstanding_data);
  const [AddressBookDataArray, setAddressBookDataArray] =
    useState(outstanding_data);
  const [PvtRatingsDataArray, setPvtRatingsDataArray] =
    useState(outstanding_data);
  const [PolicyDataArray, setPolicyDataArray] = useState(outstanding_data);
  const [OtherCRADataArray, setOtherCRADataArray] = useState(outstanding_data);
  const [DeadlineRcArray, setDeadlineRcArray] = useState(outstanding_data);
  const [DeadlineDissArray, setDeadlineDissArray] = useState(outstanding_data);
  const [DeadlineDissArray2, setDeadlineDissArray2] = useState(outstanding_data);
  const [DeadlineIcArray, setDeadlineIcArray] = useState(outstanding_data);
  const [DeadlineFcArray, setDeadlineFcArray] = useState(outstanding_data);
  const [DoneRcArray, setDoneRcArray] = useState(outstanding_data);
  const [DoneDissArray, setDoneDissArray] = useState(outstanding_data);
  const [DoneIcArray, setDoneIcArray] = useState(outstanding_data);
  const [DoneFcArray, setDoneFcArray] = useState(outstanding_data);
  const [GroupArray, setGroupArray] = useState([]);
  const [ClientArray, setClientArray] = useState([]);
  const [OpinionArray, setOpinionArray] = useState([]);
  const [GoneGroupArray, setGoneGroupArray] = useState([]);
  const [GoneClientArray, setGoneClientArray] = useState([]);
  const [GoneOpinionArray, setGoneOpinionArray] = useState([]);
  const [Groupcount, setGroupCount] = useState(0);
  const [Clientcount, setClientCount] = useState(0);
  const [Opinioncount, setOpinionCount] = useState(0);
  const [counts, setcount] = useState(0);
  const [goneGroupcount, setgoneGroupCount] = useState(0);
  const [goneClientcount, setgoneClientCount] = useState(0);
  const [goneOpinioncount, setgoneOpinionCount] = useState(0);
  const [gonecounts, setgonecount] = useState(0);
  const [isGridsTab, setisGridsTab] = useState(true);
  const [isRatingDep, setisRatingDep] = useState(false);
  const [isResearchDep, setisResearchDep] = useState(false);
  const [isBusinessDep, setisBusinessDep] = useState(false);
  const [myinprocess, setmyinprocess] = useState([]);
  const depName = localStorage.getItem("depName");
  const location = useLocation();
  const [Opinions, setOpinions] = useState([]);
  const id = localStorage.getItem("userID");
  const rightsval = localStorage.getItem("rights");

  let rights = [];

  if(rightsval !== null){
    rights = rightsval.split(",");
  }
  const has_mit_right = rights.includes("28");


  for (let i in outstanding_data) {
    outstanding_data[i].user_id1 = localStorage.getItem("userID");
    outstanding_data[i].user_id2 = localStorage.getItem("userID");
    outstanding_data[i].user_id3 = localStorage.getItem("userID");
    outstanding_data[i].lead_rc_id = localStorage.getItem("userID");
  }

  function DateFormat(params) {
    if (params == null) {
      return "-";
    } else {
      const date = new Date(params);
      const yyyy = date.getFullYear();
      const yy = yyyy.toString();
      const y = yy.slice(2, 4);
      let mm = date.toLocaleString("default", { month: "short" });
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      return dd + "-" + mm + "-" + y;
    }
  }

  useEffect(()=>{
    let User = JSON.parse(localStorage.getItem('loggedIn'));
    const now = new Date();
    if(User !== null){
        if (now.getTime() > User.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.clear();
            window.open('/login', '_self');

        } 
    }
    else{
          localStorage.clear();
    }

  },[])
  
  useEffect(() => {
    GetData.OutstandingData().then((res) => {
      res = res.data.data;
      for(let i in res){
        res[i].Notification = DateFormat(res[i].Notification);
        res[i].mandate = `https://209.97.168.200/wizpacv1/w_q/revisedMandate/viewMandate.php?op_id=${res[i].Id}`;
      }
      
      setOutstandingDataArray(res);
    });
  }, []);
 
  // console.log(
  //   res.filter((element) => {
  //     return element.name !== null;
  //   }),
  //   "names"
  // )
  useEffect(() => {
    GetData.GetOpinion().then((res) => {
      res = res.data;
      var data = res.filter((element) => {
        return element.name !== null;
      });

      setOpinions(
        data.map((res, index) => ({
          Opinion: res.name,
          OpinionId: res.id,
          Index: index + 1
        }))
      );
    });
  }, []);

  useEffect(() => {
    console.log(location.pathname, "tabb");
    if (
      location.pathname == "/pacra-wide" ||
      location.pathname == "/Ratings" ||
      location.pathname == "/Research"
    ) {
      setisGridsTab(false);
    } else setisGridsTab(true);
  }, [location]);

  useEffect(() => {
    if (Number(depName) == 9) {
      setisRatingDep(true);
    }
  }, [isRatingDep]);

  useEffect(() => {
    if (Number(depName) == 16) {
      setisResearchDep(true);
    }
  }, [isResearchDep]);

  useEffect(() => {
    if (Number(depName) == 4) {
      setisBusinessDep(true);
    }
  }, [isBusinessDep]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  function useWindowSize() {
    const [size, setsize] = useState([window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setsize([window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [screenWidth] = useWindowSize();

  useEffect(() => {
    const screenWidth = window.screen.width;
    if (screenWidth < 770) {
      setisResponsive(false);
    }
  }, []);

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const classes = useStyles();

  useEffect(() => {
    var userType = localStorage.getItem("depName");

    if (userType == 15) {
      setcompliance(true);
    }
  }, []);

  const func = (params)=>{
    return params;
  }

  return (
    <>
      <div style={{ display: "flex"  , backgroundImage:`url(${bg})`}}>
        <div>
          <SideBar
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
            open={open}
            Opinions={Opinions}
          />
        </div>
        <div
          className={`${
            !isGridsTab
              ? screenWidth > 770
                ? classes.containerWidth
                : classes.containerWidthResponsive
              : screenWidth > 770
              ? classes.otherRoutsWidth
              : classes.otherRoutsWidthResponsive
          }`}
          style={{ flexGrow: 1 }}
        >
          <Routes>
            {(Number(id) != 48 && Number(id) != 297 && Number(id) != 298 && Number(id) != 344 && Number(id) != 372) ? <Route
              path="/pacra-wide"
              replace
              element={
                <Home
                  open={open}
                  screenWidth={screenWidth}
                  compliance={compliance}
                  DeadlineRc={DeadlineRcArray}
                  setDeadlineRc={setDeadlineRcArray}
                  DeadlineDiss={DeadlineDissArray}
                  DeadlineDiss2={DeadlineDissArray2}
                  setDeadlineDiss={setDeadlineDissArray}
                  setDeadlineDiss2={setDeadlineDissArray2}
                  DeadlineIc={DeadlineIcArray}
                  setDeadlineIc={setDeadlineIcArray}
                  DeadlineFc={DeadlineFcArray}
                  setDeadlineFc={setDeadlineFcArray}
                  DoneRc={DoneRcArray}
                  setDoneRc={setDoneRcArray}
                  DoneDiss={DoneDissArray}
                  setDoneDiss={setDoneDissArray}
                  DoneIc={DoneIcArray}
                  setDoneIc={setDoneIcArray}
                  DoneFc={DoneFcArray}
                  setDoneFc={setDoneFcArray}
                  UnFinishedDataArray={UnFinishedDataArray}
                  setUnFinishedDataArray={setUnFinishedDataArray}
                  OutstandingDataArray={OutstandingDataArray}
                  // setOutstandingDataArray={setOutstandingDataArray}
                  InProcessDataArray={InProcessDataArray}
                  setInProcessDataArray={setInProcessDataArray}
                  InitialDataArray={InitialDataArray}
                  setInitialDataArray={setInitialDataArray}
                  InMNADataArray={InMNADataArray}
                  setInMNADataArray={setInMNADataArray}
                  InCobDataArray={InCobDataArray}
                  setInCobDataArray={setInCobDataArray}
                  InICUDataArray={InICUDataArray}
                  setICUDataArray={setICUDataArray}
                  WithdrawDataArray={WithdrawDataArray}
                  setWithdrawDataArray={setWithdrawDataArray}
                  InRIPDataArray={InRIPDataArray}
                  setInRIPDataArray={setRIPDataArray}
                  SectorDataArray={SectorDataArray}
                  setSectorDataArray={setSectorDataArray}
                  MethodologyDataArray={MethodologyDataArray}
                  setMethodologyDataArray={setMethodologyDataArray}
                  InstDataArray={InstDataArray}
                  setInstDataArray={setInstDataArray}
                  InstPDataArray={InstPDataArray}
                  setInstPDataArray={setInstPDataArray}
                  PvtRatingsDataArray={PvtRatingsDataArray}
                  setPvtRatingsDataArray={setPvtRatingsDataArray}
                  PolicyDataArray={PolicyDataArray}
                  setPolicyDataArray={setPolicyDataArray}
                  OtherCRADataArray={OtherCRADataArray}
                  setOtherCRADataArray={setOtherCRADataArray}
                  InHandBookDataArray={InHandBookDataArray}
                  setInHandBookDataArray={setInHandBookDataArray}
                  GoneBookDataArray={GoneBookDataArray}
                  setGoneBookDataArray={setGoneBookDataArray}
                  AddressBookDataArray={AddressBookDataArray}
                  setAddressBookDataArray={setAddressBookDataArray}
                  counts={counts}
                  setcount={setcount}
                  Opinioncount={Opinioncount}
                  setOpinionCount={setOpinionCount}
                  Groupcount={Groupcount}
                  setGroupcount={setGroupCount}
                  Clientcount={Clientcount}
                  setClientcount={setClientCount}
                  gonecounts={gonecounts}
                  setgonecount={setgonecount}
                  goneGroupcount={goneGroupcount}
                  setgoneGroupCount={setgoneGroupCount}
                  goneClientcount={goneClientcount}
                  setgoneClientCount={setgoneClientCount}
                  goneOpinioncount={goneOpinioncount}
                  setgoneOpinionCount={setgoneOpinionCount}
                  GoneGroupArray={GoneGroupArray}
                  GoneClientArray={GoneClientArray}
                  GoneOpinionArray={GoneOpinionArray}
                  setGoneGroupArray={setGoneGroupArray}
                  setGoneClientArray={setGoneClientArray}
                  setGoneOpinionArray={setGoneOpinionArray}
                  GroupArray={GroupArray}
                  ClientArray={ClientArray}
                  OpinionArray={OpinionArray}
                  setGroupArray={setGroupArray}
                  setClientArray={setClientArray}
                  setOpinionArray={setOpinionArray}
                />
              }
            /> : 
            null}
            {isRatingDep || id == "42" || id == "12" || id == "226" ? (
                <Route
                  path="/Ratings"
                  element={
                    <Ratings
                      open={open}
                      screenWidth={screenWidth}
                      compliance={compliance}
                      DeadlineRc={DeadlineRcArray}
                      setDeadlineRc={setDeadlineRcArray}
                      DeadlineDiss={DeadlineDissArray}
                      setDeadlineDiss={setDeadlineDissArray}
                      DeadlineDiss2={DeadlineDissArray2}
                      setDeadlineDiss2={setDeadlineDissArray2}
                      DeadlineIc={DeadlineIcArray}
                      setDeadlineIc={setDeadlineIcArray}
                      DeadlineFc={DeadlineFcArray}
                      setDeadlineFc={setDeadlineFcArray}
                      DoneRc={DoneRcArray}
                      setDoneRc={setDoneRcArray}
                      DoneDiss={DoneDissArray}
                      setDoneDiss={setDoneDissArray}
                      DoneIc={DoneIcArray}
                      setDoneIc={setDoneIcArray}
                      DoneFc={DoneFcArray}
                      setDoneFc={setDoneFcArray}
                      UnFinishedDataArray={UnFinishedDataArray}
                      setUnFinishedDataArray={setUnFinishedDataArray}
                      InProcessDataArray={InProcessDataArray}
                      setInProcessDataArray={setInProcessDataArray}
                      OutstandingDataArray={OutstandingDataArray}
                      // setOutstandingDataArray={setOutstandingDataArray}
                      InitialDataArray={InitialDataArray}
                      setInitialDataArray={setInitialDataArray}
                      InMNADataArray={InMNADataArray}
                      setInMNADataArray={setInMNADataArray}
                      InCobDataArray={InCobDataArray}
                      setInCobDataArray={setInCobDataArray}
                      InICUDataArray={InICUDataArray}
                      setICUDataArray={setICUDataArray}
                      WithdrawDataArray={WithdrawDataArray}
                      setWithdrawDataArray={setWithdrawDataArray}
                      InRIPDataArray={InRIPDataArray}
                      setInRIPDataArray={setRIPDataArray}
                      InstDataArray={InstDataArray}
                      setInstDataArray={setInstDataArray}
                      InstPDataArray={InstPDataArray}
                      setInstPDataArray={setInstPDataArray}
                      PvtRatingsDataArray={PvtRatingsDataArray}
                      setPvtRatingsDataArray={setPvtRatingsDataArray}
                    />
                  }
                />
            ) : null}

            {(isResearchDep || id == '42') ? (
              <Route
                path="/Research"
                element={
                  <Research
                    open={open}
                    screenWidth={screenWidth}
                    SectorDataArray={SectorDataArray}
                    setSectorDataArray={setSectorDataArray}
                  />
                }
              />
            ) : null}
            {isBusinessDep ? (
              <Route
                path="/Business"
                element={
                  <BusinessDevelopment
                    open={open}
                    screenWidth={screenWidth}
                    B_DDataArray={B_DDataArray}
                    setB_DDataArray={setB_DDataArray}
                  />
                }
              />
            ) : null}

            <Route
                path="/edit-module"
                element={
                <EditModule screenWidth={screenWidth} open={open} />
                } />

            <Route
              path="/OpinionDetails/*"
              element={
                <OpinionDetails
                  open={open}
                  screenWidth={screenWidth}
                  Opinions={Opinions}
                />
              }
            />
            <Route
              path="/redirecting-to-wizpac/:clientId"
              element={
                <Redirecting
                  screenWidth={screenWidth}
                />
              }
            />
            <Route
              path="/FC/:id/:client_id/:og_rating_id"
              element={
                <Form
                  open={open}
                  screenWidth={screenWidth}
                  Opinions={Opinions}
                />
              }
            />

            <Route
              path="/Search"
              element={
                <SearchOpinion
                  open={open}
                  screenWidth={screenWidth}
                  Opinions={Opinions}
                />
              }
            />

            <Route
              path="/Reports"
              element={
                <Reports
                  open={open}
                  screenWidth={screenWidth}
                  SectorDataArray={SectorDataArray}
                  setSectorDataArray={setSectorDataArray}
                />
              }
            />
            <Route
              path="/Reports/:id"
              element={
                <ReportsTableComponent
                  open={open}
                  screenWidth={screenWidth}
                  Opinions={Opinions}
                />
              }
            />
            <Route
              path="/PacVis"
              element={
                <PacVis
                  open={open}
                  screenWidth={screenWidth}
                  SectorDataArray={SectorDataArray}
                  setSectorDataArray={setSectorDataArray}
                />
              }
            />

            <Route
              path="/ClientInfo"
              element={
                <ClientInfo
                  open={open}
                  screenWidth={screenWidth}
                  Opinions={Opinions}
                />
              }
            />

            <Route
              path="/Ratings/undertaking/*"
              element={
              <Acceptence_undertaking />
              }
            />

            <Route
              path="/Ratings/addTask/Information/*"
              element={
              <Information myinprocess={myinprocess} />
              }
            />

            <Route
              path="/Ratings/addTask/Assessment/*"
              element={
              <Assessment />
              }
            />

            <Route
              path="/Ratings/addTask/modelview/*"
              element={
              <AssessmentReport screenWidth={screenWidth} />
              }
            />

            <Route
              path="/Ratings/addTask/edit/modelview/*"
              element={
              <Editmodelview screenWidth={screenWidth} />
              }
            />

            <Route
              path="/Ratings/addTask/preview/assessment/:model_id/:client_id/:og_rating_id"
              element={
              <PreviewAssessment screenWidth={screenWidth} />
              }
            />

            <Route
              path="/Ratings/addTask/SiteVisit/*"
              element={
              <SiteVisit />
              }
            />

            <Route
              path="/Ratings/addTask/MM/*"
              element={
                <MM />
              }
            />
            
            <Route
              path="/Ratings/addTask/RED/*"
              element={
                <RED />
              }
            />

            <Route
              path="/Ratings/addTask/RC/*"
              element={
                <RC />
              }
            />

            <Route
              path="/Ratings/RC_Cover/Coverpageindex/:client_id/:og_rating_id"
              element={
                <RcApproval screenWidth={screenWidth} />
              }
            />

            <Route
              path="/Ratings/RC_Cover/Approval/:client_id/:og_rating_id"
              element={
                <RcCoverApproval screenWidth={screenWidth} />
              }
            />

            <Route
              path="/Ratings/RC_Cover/Edit/:client_id/:og_rating_id"
              element={
                <EditRcCover screenWidth={screenWidth} />
              }
            />
            <Route
              path="/Ratings/RC/logs/:client_id/:og_rating_id"
              element={
                <Rc_logs screenWidth={screenWidth} />
              }
            />
            <Route
              path="/rc_log_view"
              element={
                <Rc_logs_view screenWidth={screenWidth} />
              }
            />

            <Route
              path="/Ratings/addTask/PPL/*"
              element={
                <PPL />
              }
            />
            
            <Route
              path="/Ratings/generate_ppl/:client_id/:og_rating_id"
              element={
                <Generateppl screenWidth={screenWidth} />
              }
            />
            
            <Route
              path="/Ratings/edit_ppl/:client_id/:og_rating_id"
              element={
                <Editppl screenWidth={screenWidth} />
              }
            />

            <Route
              path="/Ratings/rr_krd/:client_id/:og_rating_id/:status/:r_id"
              element={
                <RR_krd />
              }
            />

            <Route
              path="/Ratings/bfr/:client_id/:og_rating_id"
              element={
                <Bfr />
              }
            />

            <Route
              path="/Ratings/perf_rank/:client_id/:og_rating_id"
              element={
                <Perf_rank />
              }
            />

            <Route
              path="/Ratings/perf_rank/edit/:client_id/:og_rating_id"
              element={
                <Edit_perf_rank />
              }
            />

            <Route
              path="/Ratings/closer_internally/:client_id/:og_rating_id"
              element={
                <CloseInternally screenWidth={screenWidth} />
              }
            />
            <Route
              path="/Ratings/closer_internally/edit/:client_id/:og_rating_id"
              element={
                <Editreview screenWidth={screenWidth}  />
              }
            />
            <Route
              path="/coverpage"
              element={
                <Coverpage />
              }
            />

            <Route
              path="/Ratings/addTask/NL/*"
              element={
                <NL />
              }
            />

            <Route
              path="/Ratings/generate_nl/:client_id/:og_rating_id"
              element={
                <GenerateNL screenWidth={screenWidth} />
              }
            />


            <Route
              path="/Ratings/edit_nl/:client_id/:og_rating_id"
              element={
                <EditNL screenWidth={screenWidth} />
              }
            />

            <Route
              path="/Ratings/addTask/RR/*"
              element={
                <RR />
              }
            />

            <Route
              path="/performanceData"
              element={
                <WizPacGraph />
              }
            />

            <Route
              path="/Ratings/addTask/Test/:client_id/:og_rating_id"
              element={
                <Test />
              }
            />
            <Route
              path="/cob-to-ratings"
              element={
                <Cob_to_ratings screenWidth={screenWidth} />
              }
            />
            <Route
              path="/ratings-to-cob"
              element={
                <Rating_to_cob screenWidth={screenWidth} />
              }
            />
            <Route
              path="/opinion-transfer"
              element={
                <Opinion_transfer screenWidth={screenWidth} />
              }
            />
            <Route
              path="/opinion-assignment"
              element={
                <Opinion_assessment screenWidth={screenWidth} />
              }
            />
            <Route
              path="/opinion-transfer-log"
              element={
                <Opinion_transferlog screenWidth={screenWidth} />
              }
            />
            <Route
              path="/cob-to-rating-log"
              element={
                <Cob_to_rating_log screenWidth={screenWidth} />
              }
            />
            <Route
              path="/ratings-to-cob-log"
              element={
                <Ratings_to_cob_log screenWidth={screenWidth} />
              }
            />
            <Route
              path="/cob_to_icu_rip"
              element={
                <Cob_to_icu_rip screenWidth={screenWidth} />
              }
            />
            <Route
              path="/p&g"
              element={
                <PolicyandGuidlines screenWidth={screenWidth} />
              }
            />
            <Route
              path="/create-policy"
              element={
                <Create_policy screenWidth={screenWidth} />
              }
            />
            <Route
              path="/edit-policy/:id"
              element={
                <Edit_policy screenWidth={screenWidth} />
              }
            />
            <Route
              path="/add_applicablitiy"
              element={
                <AddApplicablitiy screenWidth={screenWidth} />
              }
            />
            <Route
              path="/add_category"
              element={
                <AddCategory screenWidth={screenWidth} />
              }
            />
            <Route
              path="/regulatory-affairs"
              element={
                <RegulatoryAffairs screenWidth={screenWidth} />
              }
            />
            <Route
              path="/policy_history/:category_id"
              element={
                <Policy_history screenWidth={screenWidth} />
              }
            />
            <Route
              path="/secp-dashboard"
              element={
                <Sponsors screenWidth={screenWidth} />
              }
            />
            <Route
              path="/directors-view"
              element={
                <Directors screenWidth={screenWidth} />
              }
            />

            <Route
              path="/criteria-view"
              element={
                <Criteria screenWidth={screenWidth} />
              }
            />
            <Route
              path="/rcreport-view"
              element={
                <Rcreport screenWidth={screenWidth} />
              }
            />
            <Route
              path="/secp-view"
              element={
                <Secp screenWidth={screenWidth} />
              }
            />
            <Route
              path="/add-shareholder"
              element={
                <Addsponders screenWidth={screenWidth} />
              }
            />
            <Route
              path="/view-shareholder/:sponser_id"
              element={
                <Viewsponsers screenWidth={screenWidth} />
              }
            />
            <Route
              path="/edit-shareholder/:sponser_id"
              element={
                <Editsponsers screenWidth={screenWidth} />
              }
            />
            
            <Route
              path="/add-director"
              element={
                <AddDirector screenWidth={screenWidth} />
              }
            />
            <Route
              path="/view-director/:director_id"
              element={
                <ViewDirectorDetail screenWidth={screenWidth} />
              }
            />

            <Route
              path="/edit-director/:director_id"
              element={
                <EditDirector screenWidth={screenWidth} />
              }
            />

            <Route
              path="/add-criteria"
              element={
                <AddCriteria screenWidth={screenWidth} />
              }
            />
            <Route
              path="/view-criteria/:criteria_id"
              element={
                <ViewCriteria screenWidth={screenWidth} />
              }
            />

            <Route
              path="/edit-criteria/:criteria_id"
              element={
                <EditCriteria screenWidth={screenWidth} />
              }
            />

            <Route
              path="/add-rc-member"
              element={
                <AddRC_Members screenWidth={screenWidth} />
              }
            />
            <Route
              path="/view-rc-member/:rc_id"
              element={
                <ViewRC_member screenWidth={screenWidth} />
              }
            />

            <Route
              path="/edit-rc-member/:rc_id"
              element={
                <EditRC_Members screenWidth={screenWidth} />
              }
            />

            <Route
              path="/add-secp-report"
              element={
                <AddSecpReport screenWidth={screenWidth} />
              }
            />

            <Route
              path="/edit-secpt-report/:secp_id"
              element={
                <EditSecpReport screenWidth={screenWidth} />
              }
            />

            <Route
              path="/change-in-software"
              element={
                <Change_in_software screenWidth={screenWidth} />
              }
            />
            <Route
              path="/bd-consultancy"
              element={
                <Consultancy_received screenWidth={screenWidth} />
              }
            />
            <Route
              path="/hr-trainings"
              element={
                <HR_training screenWidth={screenWidth} />
              }
            />
            <Route
              path="/complaints"
              element={
                <Complaints screenWidth={screenWidth} />
              }
            />
            <Route
              path="/create-complaints"
              element={
                <Add_complaints screenWidth={screenWidth} />
              }
            />

            <Route
              path="/rip_letter/:client_id/:og_rating_id"
              element={
                <RipLetter screenWidth={screenWidth} />
              }
            />

            <Route
              path="/bd_dashboard"
              element={
                <BD_dashboard screenWidth={screenWidth} />
              }
            />
            <Route
              path="/mandate-new"
              element={
                <Mandate_new screenWidth={screenWidth} />
              }
            />
            <Route
              path="/mandate-revised"
              element={
                <Mandate_revise screenWidth={screenWidth} />
              }
            />
            <Route
              path="/mandate-received"
              element={
                <Mandate_received screenWidth={screenWidth} />
              }
            />
            <Route
              path="/client-mandate-info/:client_id"
              element={
                <Edit_clientmandateInfo screenWidth={screenWidth} />
              }
            />
            <Route
              path="/client-entry-view"
              element={
                <Client_entry_index screenWidth={screenWidth} />
              }
            />
            <Route
              path="/add-new-client"
              element={
                <Add_new_client screenWidth={screenWidth} />
              }
            />
            <Route
              path="/edit-client/:client_id"
              element={
                <Edit_client screenWidth={screenWidth} />
              }
            />
            <Route
              path="/edit-group"
              element={
                <Editgroup screenWidth={screenWidth} />
              }
            />
            <Route
              path="/cob-invites"
              element={
                <CobInvites screenWidth={screenWidth} />
              }
            />

            <Route
              path="/vis-module"
              element={
                <VisDashboard screenWidth={screenWidth} />
              }
            />
            <Route
              path="/vis-grids"
              element={
                <VizGrid screenWidth={screenWidth} />
              }
            />
            
            <Route
              path="/ratings-jcr"
              element={
                <Ratings_jcr screenWidth={screenWidth} />
              }
            />
            <Route
              path="/jcr-client"
              element={
                <Jcr_client screenWidth={screenWidth} />
              }
            />

            <Route
              path="/old-sr-jcr"
              element={
                <Old_sr_jcr screenWidth={screenWidth} />
              }
            />



            <Route
              path="/email-dashboard"
              element={
                <Email_dashboard screenWidth={screenWidth} />
              }
            />
            <Route
              path="/test-mail"
              element={
                <Regulator_email screenWidth={screenWidth} />
              }
            />
            <Route
              path="/bd-mail"
              element={
                <Buisness_email screenWidth={screenWidth} />
              }
            />
            <Route
              path="/sector-study-mit"
              element={
                <Sector_study_MIT screenWidth={screenWidth} />
              }
            />
            <Route
              path="/mandate-reminder"
              element={
                <Mandate_reminder screenWidth={screenWidth} />
              }
            />

            <Route
              path="/news-letter"
              element={
                <News_letter screenWidth={screenWidth} />
              }
            />

            <Route
              path="/ss-mit"
              element={
                <SS_grid screenWidth={screenWidth} />
              }
            />
            <Route
              path="/bd-grids"
              element={
                <BDGrids open={open}
                screenWidth={screenWidth}
                compliance={compliance}
                DeadlineRc={DeadlineRcArray}
                setDeadlineRc={setDeadlineRcArray}
                DeadlineDiss={DeadlineDissArray}
                DeadlineDiss2={DeadlineDissArray2}
                setDeadlineDiss={setDeadlineDissArray}
                setDeadlineDiss2={setDeadlineDissArray2}
                DeadlineIc={DeadlineIcArray}
                setDeadlineIc={setDeadlineIcArray}
                DeadlineFc={DeadlineFcArray}
                setDeadlineFc={setDeadlineFcArray}
                DoneRc={DoneRcArray}
                setDoneRc={setDoneRcArray}
                DoneDiss={DoneDissArray}
                setDoneDiss={setDoneDissArray}
                DoneIc={DoneIcArray}
                setDoneIc={setDoneIcArray}
                DoneFc={DoneFcArray}
                setDoneFc={setDoneFcArray}
                UnFinishedDataArray={UnFinishedDataArray}
                setUnFinishedDataArray={setUnFinishedDataArray}
                OutstandingDataArray={OutstandingDataArray}
                // setOutstandingDataArray={setOutstandingDataArray}
                InProcessDataArray={InProcessDataArray}
                setInProcessDataArray={setInProcessDataArray}
                InitialDataArray={InitialDataArray}
                setInitialDataArray={setInitialDataArray}
                InMNADataArray={InMNADataArray}
                setInMNADataArray={setInMNADataArray}
                InCobDataArray={InCobDataArray}
                setInCobDataArray={setInCobDataArray}
                InICUDataArray={InICUDataArray}
                setICUDataArray={setICUDataArray}
                WithdrawDataArray={WithdrawDataArray}
                setWithdrawDataArray={setWithdrawDataArray}
                InRIPDataArray={InRIPDataArray}
                setInRIPDataArray={setRIPDataArray}
                SectorDataArray={SectorDataArray}
                setSectorDataArray={setSectorDataArray}
                InstDataArray={InstDataArray}
                setInstDataArray={setInstDataArray}
                InstPDataArray={InstPDataArray}
                setInstPDataArray={setInstPDataArray}
                PvtRatingsDataArray={PvtRatingsDataArray}
                setPvtRatingsDataArray={setPvtRatingsDataArray}
                PolicyDataArray={PolicyDataArray}
                setPolicyDataArray={setPolicyDataArray}
                OtherCRADataArray={OtherCRADataArray}
                setOtherCRADataArray={setOtherCRADataArray}
                InHandBookDataArray={InHandBookDataArray}
                setInHandBookDataArray={setInHandBookDataArray}
                GoneBookDataArray={GoneBookDataArray}
                setGoneBookDataArray={setGoneBookDataArray}
                AddressBookDataArray={AddressBookDataArray}
                setAddressBookDataArray={setAddressBookDataArray}
                counts={counts}
                setcount={setcount}
                Opinioncount={Opinioncount}
                setOpinionCount={setOpinionCount}
                Groupcount={Groupcount}
                setGroupcount={setGroupCount}
                Clientcount={Clientcount}
                setClientcount={setClientCount}
                gonecounts={gonecounts}
                setgonecount={setgonecount}
                goneGroupcount={goneGroupcount}
                setgoneGroupCount={setgoneGroupCount}
                goneClientcount={goneClientcount}
                setgoneClientCount={setgoneClientCount}
                goneOpinioncount={goneOpinioncount}
                setgoneOpinionCount={setgoneOpinionCount}
                GoneGroupArray={GoneGroupArray}
                GoneClientArray={GoneClientArray}
                GoneOpinionArray={GoneOpinionArray}
                setGoneGroupArray={setGoneGroupArray}
                setGoneClientArray={setGoneClientArray}
                setGoneOpinionArray={setGoneOpinionArray}
                GroupArray={GroupArray}
                ClientArray={ClientArray}
                OpinionArray={OpinionArray}
                setGroupArray={setGroupArray}
                setClientArray={setClientArray}
                setOpinionArray={setOpinionArray} />
              }
            />

          {(has_mit_right == true || id == '469' || id == '379') && <Route
              path="/users-list"
              element={
                <EmployeeList screenWidth={screenWidth} />
              }
            />}

            <Route
              path="/criteria-dashboard"
              element={
                <Criteria_dashboard screenWidth={screenWidth} />
              }
            />
            <Route
              path="/model-dashboard"
              element={
                <Model_dashboard screenWidth={screenWidth} />
              }
            />
            <Route
              path="/create-model"
              element={
                <CreateModel screenWidth={screenWidth} />
              }
            />
            <Route
              path="/view_format/:id"
              element={
                <View_format screenWidth={screenWidth} />
              }
            />
            <Route
              path="/view-demo/:id"
              element={
                <View_demo screenWidth={screenWidth} />
              }
            />
            <Route
              path="/post-create-model/:id"
              element={
                <PostCreateModel screenWidth={screenWidth} />
              }
            />
            <Route
              path="/add-decription/:id"
              element={
                <AddDescToHeadings screenWidth={screenWidth} />
              }
            />
            <Route
              path="/assessment-dashboard"
              element={
                <Assessment_dashboard screenWidth={screenWidth} />
              }
            />
            <Route
              path="/edit-model/:id"
              element={
                <EditModels screenWidth={screenWidth} />
              }
            />
            <Route
              path="/approve-format"
              element={
                <ApproveFormat screenWidth={screenWidth} />
              }
            />
            <Route
              path="/op-mapping-v2"
              element={
                <Opmappingv2 screenWidth={screenWidth} />
              }
            />
            <Route
              path="/methodology-view"
              element={
                <MethodologyGrid screenWidth={screenWidth} />
              }
            />
            <Route
              path="/view-methodology/:m_id"
              element={
                <View_methodology screenWidth={screenWidth} />
              }
            />
            <Route
              path="/add-new"
              element={
                <Add_new screenWidth={screenWidth} />
              }
            />
            <Route
              path="/edit-methodology/:m_id"
              element={
                <Edit_methodolody screenWidth={screenWidth} />
              }
            />
            <Route
              path="/approve-mapping"
              element={
                <ApproveMapping screenWidth={screenWidth} />
              }
            />
            <Route
              path="/sector_study"
              element={
                <Sector_study_research screenWidth={screenWidth} />
              }
            />
            <Route
              path="/add-sector-study"
              element={
                <Add_sector_study screenWidth={screenWidth} />
              }
            />
            <Route
              path="/edit-sector-study/:ss_id"
              element={
                <Edit_sector_study screenWidth={screenWidth} />
              }
            />
            <Route
              path="/classification-dashboard"
              element={
                <Classification_dashboard screenWidth={screenWidth} />
              }
            />
            <Route
              path="/add-industry"
              element={
                <Add_industry screenWidth={screenWidth} />
              }
            />
            <Route
              path="/add-sector"
              element={
                <Add_sector screenWidth={screenWidth} />
              }
            />
            <Route
              path="/edit-industry/:id"
              element={
                <Edit_industry screenWidth={screenWidth} />
              }
            />
            <Route
              path="/edit-sector-industry/:id"
              element={
                <Edit_sector_industry screenWidth={screenWidth} />
              }
            />
            <Route
              path="/publications"
              element={
                <Pacra_publication screenWidth={screenWidth} />
              }
            />
            <Route
              path="/add-publications"
              element={
                <AddPublication screenWidth={screenWidth} />
              }
            />
            <Route
              path="/edit-publication/:pb_id"
              element={
                <EditPublication screenWidth={screenWidth} />
              }
            />
            <Route  
              path="/update-industry"
              element={
                <Update_industry screenWidth={screenWidth} />
              }
            />
            <Route
              path="/edit-industry2/:id"
              element={
                <Edit_industry_grid screenWidth={screenWidth} />
              }
            />
            <Route
              path="/research-dashboard"
              element={
                <Research_dashboard screenWidth={screenWidth} />
              }
            />

            <Route
              path="/vis-history/:id"
              element={
                <Vis_history screenWidth={screenWidth} />
              }
            />
            <Route
              path="/edit-initiation-date"
              element={
                <Edit_initiation_date screenWidth={screenWidth} />
              }
            />
            <Route
              path="/edit-client-details/:client_id"
              element={
                <Edit_client_details screenWidth={screenWidth} />
              }
            />
            <Route
              path="/methodology-grid-index"
              element={
                <Methodologygridindex screenWidth={screenWidth} />
              }
            />
            <Route
              path="/rating-dashboard-external"
              element={
                <ExternalDashboard screenWidth={screenWidth} />
              }
            />
            <Route
              path="/search-result/:search"
              element={
                <Opinionsearchresult screenWidth={screenWidth} />
              }
            />


            



            <Route
              path="/update-files"
              element={
                <Update screenWidth={screenWidth} />
              }
            />
            <Route
              path="/missing-files"
              element={
                <Mandetes_to_upload screenWidth={screenWidth} />
              }
            />
            <Route
              path="/dd-bypass-list"
              element={
                <Bypass_dd_list screenWidth={screenWidth} />
              }
            />
            {/* <Route path="/Opinion" element={<Opinion
              open={open}
              screenWidth={screenWidth}
              Opinions={Opinions}
            />} /> */}



            {/* Swicth Portfolio */}

            <Route
              path="/request-support-analyst"
              element={
                <Request_support screenWidth={screenWidth} />
              }
            />
            <Route
              path="/approve-support-analyst"
              element={
                <Support_approvals screenWidth={screenWidth} />
              }
            />
            <Route
              path="/analyst-list"
              element={
                <Analyst_list screenWidth={screenWidth} />
              }
            />
            <Route
              path="/analyst-detail/:analyst_id"
              element={
                <Analyst_detail screenWidth={screenWidth} />
              }
            />
            <Route
              path="/rm-portfolio-list"
              element={
                <Rm_portfolio_list screenWidth={screenWidth} />
              }
            />
            <Route
              path="/assign-support-analyst/:support_portfolio_id"
              element={
                <Assign_support_analyst screenWidth={screenWidth} />
              }
            />
            <Route
              path="/approve-tl-support"
              element={
                <Approve_tl_support screenWidth={screenWidth} />
              }
            />
            <Route
              path="/revert-back-analyst"
              element={
                <Revertback_analyst screenWidth={screenWidth} />
              }
            />


            {/* <Route path="/InputData" element={<InputData />} /> */}
            <Route
              path="*"
              element={
                depName == 9 ? (
                  <Navigate to="/Ratings" replace />
                ) : depName == 16 ? (
                  <Navigate to="/research-dashboard" replace />
                ) : depName == 31 ? (
                  <Navigate to="/bd_dashboard" replace />
                ) : depName == 8 ? (
                  <Navigate to="/criteria-dashboard" replace />
                )
                : Number(id) == 48 || Number(id) == 297 || Number(id) == 298 || Number(id) == 344 || Number(id) == 372 ? (
                  <Navigate to="/rating-dashboard-external" replace />
                )
                 : (
                  <Navigate to="/pacra-wide" replace />
                )
              }
            />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default Dashboard;