import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { url } from "../../../../Config";


export default function EditNL(props) {

    const { client_id, og_rating_id } = useParams();
    const Navigate = useNavigate();
    const id = localStorage.getItem("userID");
    const [data, setdata] = useState(null);
    const [NL_data, setNL_data] = useState(null);
    const [customers, setcustomers] = useState([]);
    const [withdraw_text, setwithdraw_text] = useState(null);
    const [reviewEndDate, setreviewEndDate] = useState(null);
    const [date, setdate] = useState(null);
    const [compiled_client_details, setcompiled_client_details] = useState(null);
    const [securtiy, setsecurity] = useState(null);
    const [alertopen, setalertopen] = useState(false);
    const [AlertMessage, setAlertMessage] = useState(null);
    const [alertType, setalertType] = useState("error");
    const [action, setaction] = useState(null);
    const [clientId, setclientId] = useState(null);

    const btnRef = useRef(null);


    function currentDate(props){
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }
    

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/geteditNLindex/${id}/${client_id}/${og_rating_id}`);
            const resp = res.data;
            if(resp.message == "NL is not done yet"){
                Navigate(`/Ratings/generate_nl/${client_id}/${og_rating_id}`);
            }
            else{
                if(resp.nldata.length > 0){
                    setNL_data(resp.nldata[0]);
                    setclientId(resp.client_id);
                    setwithdraw_text(resp.nldata[0].withdraw_text);
                    setdate(resp.nldata[0].date);
                    setreviewEndDate(resp.nldata[0].reviewEndDate)
                    setsecurity(Number(resp.nldata[0].security));
                }
                setdata(resp);
                const arr = [];
                arr.push(resp.customers.ceo);
                arr.push(resp.customers.liasonOne);
                arr.push(resp.customers.liasonTwo);
    
                for(let i in arr){
                    if(resp.nldata[0].to_name == `${arr[i][0]} ${arr[i][1]} ${arr[i][2]}`){
                        arr.splice(i, 1);
                    }
                }
                resp.customers = arr;
                setcustomers(arr);
                console.log(resp, "response");
                setcompiled_client_details(resp.compiled_client_details);
    
            }
            }

        func();
    },[])

    const editfunc = async(event)=>{
        event.preventDefault();
        setalertopen(false);
        let rw = "-"
        let fundCateg = null;
        let reviewEndDate = null;
        let ratingtype = null;
        if(data.latestRecords.length > 0){
         ratingtype = data.latestRecords[0].ratingType;
         fundCateg =  data.latestRecords[0].fundCateg;
         reviewEndDate =  data.latestRecords[0].reviewEndDate;
        }
        if(data.getRW.length > 0){
            rw = data.getRW[0].final_rating_watch;
        }

        if(document.getElementById("reviewEndDate") !== null && ratingtype == "Performance Ranking"){
            reviewEndDate = document.getElementById("reviewEndDate").value
        }
        

        const res = await axios.post(`${url}/api/updateNL`,
                {
                    "p_st" : data.PreviousShortTerm,
                    "p_lt" : data.PreviouatsLongTerm,
                    "to_name" : document.getElementById("client1").value,
                    "to_designation" : document.getElementById("designation1").value,
                    "company" : compiled_client_details.clientName,
                    "address" : compiled_client_details.city,
                    "opid" : data.opid,
                    "record_id" : data.record_id,
                    "longTermRating" : data.longTermRating,
                    "shortTermRating" : data.shortTermRating,
                    "ratingType" : data.ratingType,
                    "ratingAction" : data.ratingAction,
                    "fiveYrRating" : data.fiveYrRating,
                    "outLook" : data.outLook,
                    "opTitle" : data.opTitle,
                    "categ" : fundCateg,
                    "reviewEndDate" : reviewEndDate,
                    "confidential" : "Confidential",
                    "pplDate" : date,
                    "ppl_text1" : document.getElementById("textarea1").value,
                    "rw" : rw,
                    "y_truly" : "Yours truly",
                    "s_name" : document.getElementById("s_name").value,
                    "uh1" : "Jhangeer Hanif",
                    "s_designation" : 'CRO',
                    "security" : document.getElementById("security").value,
                    "dpr" : "1",
                    "rr" : "1"
            
                }
        )

        if(res.data.status == true && res.data.message == "Edit Done"){
            let NL = NL_data;
            NL.date = date;
            setNL_data(NL);
            setalertType("success");
            setAlertMessage("Successfully Updated");
            setalertopen(true);
        }
        else{
            setalertType("error");
            setAlertMessage("Some Error occure while Processing your request");
            setalertopen(true);
        }
    }

    const Approve = async ()=>{

        if(btnRef.current){
            btnRef.current.disabled = true;
            btnRef.current.innerHTML =  "<i class='fa-solid fa-spinner fa-spin fa-lg'></i>";
        }
        const res = await axios.post(`${url}/api/approveNl`,{
            "opinion_id" : data.opid,
            "record_id" : data.record_id,
            "action" : data.ratingAction,
            "ratingType" : data.ratingType,
        })
        if(res.data.message == "Please generate Final RR before NL Approval"){
            btnRef.current.disabled = false;
            btnRef.current.innerHTML =  "Approve";
            setalertType("error");
            setAlertMessage("Please generate Final RR before NL Approval");
            setalertopen(true);
        }
        else if(res.data.message == "NL Approved Successfully"){
            if(data.nldata[0].record_id !== "Performance Ranking" && data.nldata[0].record_id !== "Stability Rating"){
                if(btnRef.current){
                    btnRef.current.disabled = false;
                    btnRef.current.innerHTML =  "Approve";
                }
                document.getElementById("approve").hidden = true;
                document.getElementById("save").hidden = true;
                window.open(`https://209.97.168.200/pacrawizpackv3/public/api/generateReport/${client_id}/${og_rating_id}/nl`, '_blank');
            }
            setalertType("success");
            setAlertMessage("NL Approved Successfully");
            setalertopen(true);
            if(btnRef.current !== null){
                btnRef.current.disabled = false;
                btnRef.current.innerHTML =  "Approve";
            }
            document.getElementById("approve").hidden = true;
            document.getElementById("save").hidden = true;
        }
        else{
            if(btnRef.current){
                btnRef.current.disabled = false;
                btnRef.current.innerHTML =  "Approve";
            }
            setalertType("error");
            setAlertMessage("Some Error occure while Processing your request");
            setalertopen(true);
        }
    }

  return (
    <div style={{height: "90vh"}}>
      {data !== null ? 
      <>
       <div className="col-11 mx-auto mt-3">
            <button className="btn btn-primary" onClick={()=>{window.open(`/edit-client-details/${clientId}`, '_blank')}}>Edit Client</button>
        </div> 
        <form className='card shadow col-11 mx-auto p-2 border border-dark p-1 mb-3' style={{backgroundColor: "whitesmoke"}} onSubmit={editfunc}>
            <div className='row'>
                {props.screenWidth <= 576 && 
                <div className='col-12 text-wrap text-center fw-bold' style={{fontStyle : "12px"}}>
                {data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].opTitle : null : null}
                </div>}

                {props.screenWidth <= 576 && 
                <div className={'col-12 text-wrap text-center mb-1'} style={{fontSize : "10px"}}>
                    {data !== null ? data.latestRecords.length > 0 ? 
                    <>
                    {NL_data !== null ? NL_data.rating_type : null} - {NL_data !== null ? NL_data.opinion_type : null}
                    </>
                    : null : null}
                </div>}

                <div className={props.screenWidth >= 576 ? 'col-3' : 'col-12 mb-lg-0 mb-md-0 mb-sm-0 mb-1'}>
                    <select className='form-select' id="client1" onChange={(e)=>{
                        const value = e.target.value;
                        let desig = document.getElementById('designation1');
                        if(value == `${compiled_client_details.ceo[0]} ${compiled_client_details.ceo[1]} ${compiled_client_details.ceo[2]}`){
                            if(desig !== null){
                                desig.value = `${compiled_client_details.ceo[3]}`;
                            }
                        }
                        if(value == `${compiled_client_details.liasonOne[0]} ${compiled_client_details.liasonOne[1]} ${compiled_client_details.liasonOne[2]}`){
                            if(desig !== null){
                                desig.value = `${compiled_client_details.liasonOne[3]}`;                            
                            }
                        }
                        if(value == `${compiled_client_details.liasonTwo[0]} ${compiled_client_details.liasonTwo[1]} ${compiled_client_details.liasonTwo[2]}`){
                            if(desig !== null){
                                desig.value = `${compiled_client_details.liasonTwo[3]}`;
                            }
                        }
                    }}> {NL_data !== null ? <option value={NL_data.to_name}> {NL_data.to_name} </option> : null}
                        {
                            customers.map((value, index)=>{
                                return(
                                    <option value={`${value[0]} ${value[1]} ${value[2]}`}>{`${value[0]} ${value[1]} ${value[2]}`}</option>
                                );
                            })
                        }
                        
                    </select>
                </div>
                {props.screenWidth > 576 && <div className='col-6 text-wrap text-center fs-6 fw-bold'>
                    {data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].opTitle : null : null}
                </div>}

                {props.screenWidth > 576 && <div className='col-3'>
                    <input className='form-control' value={NL_data !== null ? NL_data.confidential : null} disabled />
                </div>}
            </div>
            <div className='row mt-1'>
                <div className={props.screenWidth > 576 ? 'col-3' : 'col-12 mb-1'}>
                    <select className='form-select' id="designation1">
                        {NL_data !== null ? <option value={NL_data.to_designation}> {NL_data.to_designation} </option> : null}
                        {
                            customers.map((value, index)=>{
                                return(
                                    <option value={value[3]}>{value[3]}</option>
                                );
                            })
                        }
                    </select>
                </div>
                {props.screenWidth > 576 && <div className='col-6 text-wrap text-center fs-6'>
                    {data !== null ? data.latestRecords.length > 0 ? 
                    <>
                    {NL_data !== null ? NL_data.rating_type : null} - {NL_data !== null ? NL_data.opinion_type : null}
                    </>
                    : null : null}
                </div>}
                <div className={props.screenWidth > 576 ? 'col-3' : 'col-12 mb-1'}>
                    <input className='form-control' type='date' id="nldate" min={data.initiation_date} value={date} onChange={(e)=>{setdate(e.target.value)}} required/>
                </div>
                {props.screenWidth <= 576 && <div className={'col-12'}>
                    <input className='form-control' value={'Confidential'} disabled />
                </div>}
            </div>
            <div className='col-lg-3 col-md-3 col-sm-6 col-12 mt-1'>
                <input className='form-control' value={data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].opTitle : null : null} disabled />
            </div>
            <div className='col-lg-3 col-md-3 col-sm-6 col-12 mt-1'>
                <input className='form-control' value={data !== null ? compiled_client_details.city : null} disabled />
            </div>
            <div className='mt-3'>
                <textarea className='form-control' id="textarea1" value={withdraw_text} onChange={(e)=>{setwithdraw_text(e.target.value)}} style={{height: "90px"}}/>
            </div>
            <div className='mt-3' style={{ overflow: "auto"}}>
                <table className='table table-secondary newtable'>
                    <tbody>
                    {   data !== null ?
                        data.latestRecords.length > 0 ?
                        data.latestRecords[0].ratingType == "Performance Ranking" ?
                        <>
                        <tr>
                            <td className='col-2 fw-bold text-center'>Opinion</td>
                            <td className='col-1 fw-bold text-center'>Category</td>
                            <td className='col-2 fw-bold text-center'>Opinion Type</td>
                            <td className='col-2 fw-bold text-center'>Action</td>
                            <td className='col-4 fw-bold text-center' colSpan="3">Rankings</td>
                            <td className='col-2 fw-bold text-center'>Review Period End</td>
                        </tr>
                        <tr>
                            <td className='col-2'></td>
                            <td className='col-1'></td>
                            <td className='col-2'></td>
                            <td className='col-2'></td>
                            <td className='col-2 fw-bold text-center'>1 Year</td>
                            <td className='col-2 fw-bold text-center'>3 Year</td>
                            <td className='col-2 fw-bold text-center'>5 Year</td>
                            <td className='col-2'></td>
                        </tr>
                        <tr>
                            <td className='col-2 text-center'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].opTitle : null : null}</td>
                            <td className='col-1 text-center'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].fundCateg : null : null}</td>
                            <td className='col-2 text-center'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].ratingType : null : null}</td>
                            <td className='col-2 text-center'>{data !== null ? data.ratingAction : null}</td>
                            <td className='col-2 text-center'>{data !== null ? data.shortTermRating : null}</td>
                            <td className='col-2 text-center'>{data !== null ? data.longTermRating : null}</td>
                            <td className='col-2 text-center'>{data !== null ? data.fiveYrRating : null}</td>
                            <td className='col-2'><input className="form-control" id="reviewEndDate" value={reviewEndDate} type="date"></input></td>
                        </tr>
                        </> :
                        data.latestRecords[0].ratingType == "Entity" || data.latestRecords[0].ratingType == "Debt Instrument" ?
                        <>
                        <tr>
                            <td className='col-2 fw-bold'>Opinion</td>
                            <td className='col-4 fw-bold text-center' colSpan="2">Ratings</td>
                            <td className='col-2 fw-bold'>Outlook</td>
                            <td className='col-2 fw-bold'>Action</td>
                            <td className='col-2 fw-bold'>Rating Watch</td>
                            <td className='col-2 fw-bold'>Opinion Type</td>
                        </tr>
                        <tr>
                            <td className='col-2'></td>
                            <td className='col-2 fw-bold text-center'>Long Term</td>
                            <td className='col-2 fw-bold text-center'>Short Term</td>
                            <td className='col-2'></td>
                            <td className='col-2'></td>
                            <td className='col-2'></td>
                            <td className='col-2'></td>
                        </tr>
                        <tr>
                            <td className='col-2'>{data !== null ? data.opTitle : null}</td>
                            <td className='col-2 text-center'>{data !== null ? data.longTermRating : null}</td>
                            <td className='col-2 text-center'>{data !== null ? data.shortTermRating : null}</td>
                            <td className='col-2'>{data !== null ? data.outLook : null}</td>
                            <td className='col-2'>{data !== null ? data.ratingAction : null}</td>
                            <td className='col-2'>{data !== null ? data.getRW.length > 0 ? data.getRW[0].final_rating_watch == "YES" || data.getRW[0].final_rating_watch == "Yes" ? "YES" : "-" : null : null}</td>
                            <td className='col-2'>{data !== null ? data.ringType : null}</td>
                        </tr>
                        </> :
                        <>
                        <tr>
                            <td className='col-2 fw-bold'>Opinion</td>
                            <td className='col-2 fw-bold text-center'>{data.latestRecords[0].ratingType == 'IFS' ? 'IFS Rating' : 'Rating'}</td>
                            <td className='col-2 fw-bold'>Outlook</td>
                            <td className='col-2 fw-bold'>Action</td>
                            <td className='col-2 fw-bold'>Rating Watch</td>
                            <td className='col-2 fw-bold'>Opinion Type</td>
                        </tr>
                        <tr>
                            <td className='col-2'>{data !== null ? data.opTitle : null}</td>
                            <td className='col-2 text-center'>{data !== null ? data.longTermRating : null}</td>
                            <td className='col-2'>{data !== null ? data.outLook : null}</td>
                            <td className='col-2'>{data !== null ? data.ratingAction : null}</td>
                            <td className='col-2'>{data !== null ? data.getRW.length > 0 ? data.getRW[0].final_rating_watch == "YES" || data.getRW[0].final_rating_watch == "Yes" ? "YES" : "-" : null : null}</td>
                            <td className='col-2'>{data !== null ? data.ringType : null}</td>
                        </tr>
                        </>     
                        : null : null
                    }
                    
                    </tbody>
                </table>
            </div>
            {data !== null ? <div className='mt-3 row'>
                <div className='col-lg-3 col-md-3 col-sm-3 col-12 mb-1'>
                    <input className='form-control' value={'Yours Truly'} disabled/>
                </div>
                <div className='col-6 col-md-6 col-sm-6 col-12 mb-1'>
                    <div className='col-8 col-md-8 col-sm-8 col-12 mx-auto'>
                    <select className='form-select' id="s_name">
                        <option value={data.lead_rc_id.length > 0 ? data.lead_rc_id[0] : null}>{data.signatory.length > 0 ? data.signatory[0].display_name : null}</option>
                    </select>
                    </div>
                </div>
                <div className='col-3 col-md-3 col-sm-3 col-12 mb-1'>
                    <select className='form-select' id="s_designation">
                        <option value={data.signatory_designation.length > 0 ? data.signatory_designation[0].title : null}>{data.signatory_designation.length > 0 ? data.signatory_designation[0].title : null}</option>
                    </select>
                </div>
            </div> : null}
            {data !== null ? 
            <div className='row mt-2'>
                <div className='col-lg-3 col-md-3 col-sm-3 col-12 mb-1'>
                    <select className='form-select' id="security" value={securtiy} onChange={(e)=>{setsecurity(e.target.value)}}>
                        {data.getsecurity.length > 0 ?
                        data.getsecurity.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.title}</option>
                            )
                        }) : null}
                    </select>
                </div>
            </div> : null}
            <div className="mt-2">
                Encl:
            </div>
            <div className='row mt-3'>
                <div className='col-lg-3 col-md-3 col-3 col-12'>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox value={"1"} id="draftpr" defaultChecked />}  label="Press Release" />
                    </FormGroup>
                </div>
                {data !== null ? data.ratingAction !== 'Withdraw' && <div className='col-lg-3 col-md-3 col-3 col-12'>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox value={"1"} id="rr" defaultChecked />}  label="Rating Report" />
                    </FormGroup>
                </div> : null}
            </div>
            <Box className="mx-auto mb-1" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                            severity={alertType}
                            action={
                                <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setalertopen(false);
                                }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {AlertMessage}
                        </Alert>
                    </Collapse>
            </Box>
            <div className="d-flex">
                <div className='mx-auto mt-3' style={{width: "fit-content"}}>
                    {NL_data !== null && data !== null ? NL_data.second_approve !== 'Approved' && data.can_approve_nl == 1 && NL_data.date == currentDate() ? <button className='btn btn-success me-1 btn-sm' type='button' ref={btnRef} id="approve" onClick={()=>{Approve()}}> Approve </button> : null : null}
                    {NL_data.second_approve !== 'Approved' && <button className='btn btn-primary btn-sm me-1' id="save" type='submit'> Update </button>}
                    <button className='btn btn-primary ms-1 btn-sm' type='button' id="approve" onClick={()=>{window.open(`/Ratings/nlview/${og_rating_id}`)}}>View</button>
                </div>
            </div>
      </form>
      </>
       : 
      (<div className="mt-5 mx-auto" style={{ width: "fit-content" }}>
      <div class="wrapper" id="loader2">
        <svg
          class="hourglass"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 120 206"
          preserveAspectRatio="none"
        >
          <path
            class="middle"
            d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"
          />
          <path
            class="outer"
            d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"
          />
        </svg>
      </div>
    </div>)}
    </div>
  )
}
