import React,{useEffect, useState, useRef} from 'react';
import { url } from '../../../../../Config';
import axios from 'axios';
import TableComponent from '../../../../Atoms/TableComponent';
import Skeleton from "@mui/material/Skeleton";
import VisibilityIcon from '@mui/icons-material/Visibility';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';


export default function Analyst_list(props) {

    const [data, setdata] = useState([]);
    const [rowstatus, setrowstatus] = useState();
    const [selectanalyst, setselectanalyst] = useState([]);
    const [bool, setbool] = useState(false);
    const [message, setmessage] = useState(null);
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const saveRef = useRef(null);
    const [sortConfig, setSortConfig] = useState({ key: 'rc_next_30_days', order: 'asc' });

    const sortData = (key, order) => {
      const sortedData = [...data].sort((a, b) => {
        if (order === 'asc') {
          return a[key] - b[key];
        } else {
          return b[key] - a[key];
        }
      });
      setdata(sortedData);
    };
  
    // Toggle sorting for any column
    const toggleSort = (key) => {
      const newSortOrder = sortConfig.key === key && sortConfig.order === 'asc' ? 'desc' : 'asc';
      setSortConfig({ key, order: newSortOrder });
      sortData(key, newSortOrder);
    };

    useEffect(()=>{

        const func = async ()=>{
            // setrowstatus(false);
            setmessage('Please wait while rows are loading');
            const res = await axios.get(`${url}/api/analyst_list`);
            const analysts = res.data;
            if(analysts.length == 0){
                setmessage('No Rows to Show');
                // setrowstatus(true)
              }
            else{
              setmessage(null);
            }
            setdata(analysts);
        }

        func();

    },[])

    const saveanalyst = async ()=>{
      setalertOpen(false);

      let arr = [];
      for(let i in selectanalyst){
        arr.push(selectanalyst[i].id);
      }

      localStorage.setItem('selected_analyst', `${arr}`);
      setalertType("success");
      setalertOpen(true);
      setalertmessage('Successfully Saved!');

    }


    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 120;
        }
      };
  
      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };
    
      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else if (params.value == "") {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
    
      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };
    
      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };
    
    // const columnDefs = [
    //     {
    //       headerName: "",
    //       field: "sNo",
    //       maxWidth: 30,
    //       filter: true,
    //       menuTabs: false,
    //       pinned: responsiveColumnPin(),
    //       hide: detailShow(),
    //       cellRenderer: "agGroupCellRenderer",
    //       suppressColumnsToolPanel: true,
    //       suppressFiltersToolPanel: true,
    //     },
    //     {
    //       headerName: "#",
    //       maxWidth: 50,
    //       // minWidth: 66,
    //       field: "sNo",
    //       sortable: true,
    //       filter: true,
    //       // filter: "agSetColumnFilter",
    //       headerComponentParams: {
    //         template:
    //           '<div class="ag-cell-label-container" role="presentation">' +
    //           '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
    //           '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
    //           '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
    //           '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
    //           '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
    //           '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
    //           '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
    //           '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
    //           "  </div>" +
    //           "</div>",
    //       },
    //       suppressFiltersToolPanel: true,
    //       menuTabs: false,
    //       cellRenderer: cellrandered,
    //       pinned: responsiveColumnPin(),
    //     },
    //     {
    //       headerName: "Analyst Name",
    //       minWidth: 250,
    //       field: "display_name",
    //       sortable: true,
    //       filter: "agSetColumnFilter",
    //       excelMode: "windows",
    //       cellRenderer: cellrander,
    //       tooltipField: "opinion_name",
    //       pinned: 'left',
    //     },
    //     {
    //       headerName: "Team Lead",
    //       minWidth: 150,
    //       field: "team_lead",
    //       sortable: true,
    //       hide: columnHide(),
    //       filter: "agSetColumnFilter",
    //       excelMode: "windows",
    //       cellRenderer: cellrander,
    //       tooltipField: "team_lead",
    //     },
    //     {
    //       headerName: "RCs Next 30 Days",
    //       minWidth: 150,
    //       field: "rc_next_30_days",
    //       sortable: true,
    //       hide: columnHide(),
    //       filter: "agSetColumnFilter",
    //       excelMode: "windows",
    //       cellRenderer: cellrander,
    //     },
    //     {
    //       headerName: "Dissemination Next 30 Days",
    //       minWidth: 150,
    //       field: "dissemination_30_days",
    //       sortable: true,
    //       hide: columnHide(),
    //       filter: "agSetColumnFilter",
    //       excelMode: "windows",
    //       cellRenderer: cellrander,
    //     },
    //     {
    //       headerName: "IC Next 30 Days",
    //       minWidth: 150,
    //       field: "ic_30_days",
    //       sortable: true,
    //       hide: columnHide(),
    //       filter: "agSetColumnFilter",
    //       excelMode: "windows",
    //       cellRenderer: cellrander,
    //     },
    //     {
    //       headerName: "FC Next 30 Days",
    //       minWidth: 150,
    //       field: "fc_30_days",
    //       sortable: true,
    //       hide: columnHide(),
    //       filter: "agSetColumnFilter",
    //       excelMode: "windows",
    //       cellRenderer: cellrander,
    //     },
    //     {
    //       headerName: "Detail",
    //       minWidth: 150,
    //       field: "id",
    //       sortable: true,
    //       filter: "agSetColumnFilter",
    //       excelMode: "windows",
    //       cellRenderer: (params)=>{
    //         return(
    //             <Tooltip title="View"><VisibilityIcon sx={{color: 'blue'}} onClick={()=>{window.open(`/analyst-detail/${params.value}`, '_blank')}} /></Tooltip>
    //         )
    //       },
    //     },
    //     {
    //       headerName: "Select",
    //       minWidth: 150,
    //       field: "id",
    //       sortable: true,
    //       filter: "agSetColumnFilter",
    //       excelMode: "windows",
    //       cellEditor: "agCheckboxCellEditor",
    //       // cellRenderer: 'agCheckboxCellRenderer',

    //       cellRenderer: (params)=>{
    //         return(
    //           <div className="form-check">
    //             <input className="form-check-input" type="checkbox" id={`checkbox${params.value}`} value='' onChange={(e)=>{
    //               let checkbox = document.getElementById(`checkbox${params.value}`);
    //               if(checkbox.checked == true){
    //                 const obj = {id: params.value, name: params.data.display_name}
    //                 const arr = selectanalyst;
    //                 arr.push(obj);
    //                 setselectanalyst(arr);
    //               }
    //               else{
    //                 let arr = selectanalyst;
    //                 for(let i in selectanalyst){
    //                   if(selectanalyst[i].id == params.value){
    //                     arr.splice(i,1);
    //                     setselectanalyst(arr);
    //                   }
    //                 }
    //               }

    //               let str = ""
    //               for(let i in selectanalyst){
    //                 str = str + `<span class="p-2 me-1" style="color: white; background: brown">${selectanalyst[i].name}</span>`
    //               }

    //             document.getElementById('analyst').innerHTML = str;

    //             if(selectanalyst.length > 0){
    //               saveRef.current.hidden = false;
    //             }
    //             else{
    //               saveRef.current.hidden = true;
    //             }
                  
    //             }} />
    //           </div>
    //         )
    //       },
    //     },
          
    //   ];
  
    const MobViewRender = (params) => (
          <h1 style={{ padding: "10px 20px" }}>
            <Table className="overflow-scroll responsiveTableFonts">
              <TableRow>
                <TableCell
                  variant="head"
                  className="fw-bolder responsiveTableFonts py-1"
                >
                  Team Lead
                </TableCell>
                <TableCell className="responsiveTableFonts py-1">
                  {params.data.team_lead_name}
                </TableCell>
              </TableRow>
            </Table>
          </h1>
        );

    // useEffect(()=>{
    //   setselectanalyst(selectanalyst)
    // },[bool])

  return (
    <>
      <div>
        {
          <>
          <Box className="mx-auto" sx={{ width: "98%" }}>
              <Collapse in={alertopen}>
                  <Alert
                  severity={alertType}
                  action={
                      <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                          setalertOpen(false);
                      }}
                      >
                      <CloseIcon fontSize="inherit" />
                      </IconButton>
                  }
                  sx={{ mb: 2 }}
                  >
                  {AlertMessage}
                  </Alert>
              </Collapse>
            </Box>
            <div className='fw-bold mt-1 ms-1 col-10 mb-2'>Selected Analyst:</div>
            <div className='mt-1 mb-2  ms-1 col-10' id='analyst'>
            </div>
            <div className='mt-1 mb-2  ms-1 col-10'>
              <button className='btn btn-primary' ref={saveRef} hidden={true} onClick={()=>{saveanalyst()}}>Save</button>
            </div>
          </>
        }
      </div>
      <div>
        {/* <TableComponent
                  Data={data}
                  rowstatus = {rowstatus}
                  screenWidth={props.screenWidth}
                  MobViewRender={MobViewRender}
                  columnDefs={columnDefs}
                  datefilters={datefilters}
                  selection = {true}
                  datefilter={false}
              /> */}
        <table className='table table-bordered border-dark'>
          <thead>
            <tr>
              <th>#</th>
              <th>Analyst Name</th>
              <th>Team Lead</th>
              <th onClick={() => toggleSort('rc_next_30_days')} style={{ cursor: 'pointer' }}>
                RC (next 30 days)
                {sortConfig.key === 'rc_next_30_days' && (sortConfig.order === 'asc' ? ' ↑' : ' ↓')}
              </th>
              <th onClick={() => toggleSort('dissemination_30_days')} style={{ cursor: 'pointer' }}>
                DD (next 30 days)
                {sortConfig.key === 'dissemination_30_days' && (sortConfig.order === 'asc' ? ' ↑' : ' ↓')}
              </th>
              <th onClick={() => toggleSort('ic_30_days')} style={{ cursor: 'pointer' }}>
                IC (next 30 days)
                {sortConfig.key === 'ic_30_days' && (sortConfig.order === 'asc' ? ' ↑' : ' ↓')}
              </th>
              <th onClick={() => toggleSort('fc_30_days')} style={{ cursor: 'pointer' }}>
                FC (next 30 days)
                {sortConfig.key === 'fc_30_days' && (sortConfig.order === 'asc' ? ' ↑' : ' ↓')}
              </th>
              <th>Detail</th>
              <th>Select</th>
            </tr>
          </thead>
          <tbody>
            {data.map((value, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{value.display_name}</td>
                <td>{value.team_lead}</td>
                <td>{value.rc_next_30_days}</td>
                <td>{value.dissemination_30_days}</td>
                <td>{value.ic_30_days}</td>
                <td>{value.fc_30_days}</td>
                <td>
                  <Tooltip title="View">
                    <VisibilityIcon
                      sx={{ color: 'blue' }}
                      onClick={() => {
                        window.open(`/analyst-detail/${value.id}`, '_blank');
                      }}
                    />
                  </Tooltip>
                </td>
                <td>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id={`checkbox${value.id}`}
                      value=''
                      onChange={(e) => {
                        let checkbox = document.getElementById(`checkbox${value.id}`);
                        if (checkbox.checked == true) {
                          const obj = { id: value.id, name: value.display_name };
                          const arr = selectanalyst;
                          arr.push(obj);
                          setselectanalyst(arr);
                        } else {
                          let arr = selectanalyst;
                          for (let i in selectanalyst) {
                            if (selectanalyst[i].id == value.id) {
                              arr.splice(i, 1);
                              setselectanalyst(arr);
                            }
                          }
                        }

                        let str = '';
                        for (let i in selectanalyst) {
                          str += `<span class="p-2 me-1" style="color: white; background: brown">${selectanalyst[i].name}</span>`;
                        }

                        document.getElementById('analyst').innerHTML = str;

                        if (selectanalyst.length > 0) {
                          saveRef.current.hidden = false;
                        } else {
                          saveRef.current.hidden = true;
                        }
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
          {
            <div className='text-centered fs-6 fw-bold'>
              {message}
            </div>
          }
        </table>
      </div>
    </>
  )
}
