import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import white from "../../../Assets/Images/whitebg.jpg";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import "../../../index.css";
import { url } from "../../../Config";
import { decryptParams } from '../encrypt_url';
import { useLocation } from 'react-router-dom';
import {encryptParams} from "../encrypt_url";

export default function PPL() {
  const location = useLocation();
  const pathname = location.pathname;
  const encryptedParams = pathname.substring('/Ratings/addTask/NL/'.length);
  // Decrypt the parameters
  const { client_id, og_rating_id } = decryptParams(encryptedParams);
  const isadmin = localStorage.getItem('admin');

  const Navigate = useNavigate();
  const [data, setdata] = useState({});
  const [ppldata, setppldata] = useState(null);
  const [alredyGenenatedPpl, setalredyGenenatedPpl] = useState([]);
  const [alredyGenenatednl, setalredyGenenatednl] = useState([]);
  const [alredyGenenatedRc, setalredyGenenatedRc] = useState([]);
  const [getPpls, setgetPpls] = useState([]);
  const [lastRC, setlastRC] = useState([]);
  const [alertopen, setalertopen] = useState(false);
  const [alertopen2, setalertopen2] = useState(false);
  const [AlertMessage, setAlertMessage] = useState(null);
  const [alertType, setalertType] = useState("error");
  const id = localStorage.getItem("userID")
  const [RR_files, setRR_files] = useState(null);
  const [PR_files, setPR_files] = useState(null);
  const [changeopinion, setchangeopinion] = useState(false);
  const [opinion, setopinion] = useState([]);
  const [bool, setbool] = useState(false);
  const processpage = "NL";
  const [checktask, setchecktask] = useState([]);
  const [reopen, setreopen] = useState(null);

  useEffect(()=>{
    if(id != "19" && id !== "42" && id !== "12"){
      const func = async ()=>{
        const res = await axios.get(`${url}/api/agreedinProcess/${id}`);
        const resp = res.data;
        const arr = [];
        for(let i in resp){
          if(resp[i].user_id == id || resp[i].lead_rc_id == id || resp[i].user_id1 == id){
            arr.push(resp[i]);
          }
        }
        setopinion(arr);
      }
      func();
    }
    else{
      const func = async ()=>{
        const resp2 = await axios.get(
          `${url}/api/myinprocess/${id}`
        );
        const data2 = resp2.data;
        const arr2 = [];
        for(let i in data2){
          if(data2[i].user_id == id || data2[i].lead_rc_id == id || data2[i].user_id1 == id || id == "42" || id == "12"){
              arr2.push(data2[i]);
          }
        } 
        setopinion(arr2);
      }

      func();
    }

  },[]);


  useEffect(() => {
      setppldata(null);
      const func = async () => {
      const resp = await axios.get(`${url}/api/addTask/FC/${client_id}/${og_rating_id}`);
      const res = resp.data;
      const response = res[0];
      // const res2 = resp2.data;
      if(response.edit_module_tasks !== null){

          let arr = response.edit_module_tasks.split(',');
          setchecktask(arr);

          }
      else{
            setchecktask([]);
          }
            
      setreopen(response.reopen);
      setdata(res[0]);

      const getpplapi = await axios.get(`${url}/api/ppl_data/${id}/${client_id}/${og_rating_id}`)
      const getppl = getpplapi.data;
      setppldata(getppl);
      setalredyGenenatedPpl(getppl.alredyGenenatedPpl);
      setalredyGenenatednl(getppl.alredyGenenatednl);
      setalredyGenenatedRc(getppl.alredyGenenatedRc);
      setgetPpls(getppl.getPpls);
      setlastRC(getppl.lastRC);
    };
    func();
  }, [changeopinion]);

  useEffect(()=>{
      setppldata(null);
      const func = async ()=>{
      const getpplapi = await axios.get(`${url}/api/ppl_data/${id}/${client_id}/${og_rating_id}`)
      const getppl = getpplapi.data;
      setppldata(getppl);
      setalredyGenenatedPpl(getppl.alredyGenenatedPpl);
      setalredyGenenatednl(getppl.alredyGenenatednl);
      setalredyGenenatedRc(getppl.alredyGenenatedRc);
      setgetPpls(getppl.getPpls);
      setlastRC(getppl.lastRC);
    }
    func();
  },[bool, changeopinion])

  const uploadRRreport = async (event)=>{
    event.preventDefault();
    const fileData = new FormData();
    fileData.append("file", RR_files);
    fileData.append("report", "RR");
    fileData.append("recordId", og_rating_id);
    const res = await axios.post(`${url}/api/uploadPRandRR`,
    fileData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  )
      if(res.data.status == true){
        setalertType("success");
        setAlertMessage("File Uploaded Successfully");
        setalertopen2(true);
      }
    else{
        setalertType("error");
        setAlertMessage("Some Error occure while Uploading File");
        setalertopen2(true);
    }
  }
  
  const uploadPRreport = async (event)=>{
    event.preventDefault();
    const fileData = new FormData();
    fileData.append("file", PR_files);
    fileData.append("report", "PR");
    fileData.append("recordId", og_rating_id);
    const res = await axios.post(`${url}/api/uploadPRandRR`,
    fileData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  )
  if(res.data.status == true){
    setalertType("success");
    setAlertMessage("File Uploaded Successfully");
    setalertopen2(true);
}
else{
    setalertType("error");
    setAlertMessage("Some Error occure while Uploading File");
    setalertopen2(true);
  }
  }

  const generateReport = async()=>{
    try {
      document.getElementById("gn").hidden = true;
      document.getElementById("generateprogress").hidden = false;
      const res = await axios.get(`https://209.97.168.200/pacrawizpackv3/public/api/generateReport/${client_id}/${og_rating_id}/nl`);
      if(res.status == 200){
        document.getElementById("gn").hidden = false;
      document.getElementById("generateprogress").hidden = true;
        window.open(`https://209.97.168.200/pacrawizpackv3/public/api/generateReport/${client_id}/${og_rating_id}/nl`, '_blank');
      }
    } catch (error) {
      document.getElementById("gn").hidden = false;
      document.getElementById("generateprogress").hidden = true;
      setalertType("error");
      setAlertMessage(error.response.data.message);
      setalertopen(true);
    }
  }


  return (
    <div style={{height: "90vh"}}>
      <div
        className="fs-3 fw-bold text-center mt-2"
        style={{ color: "#000078", fontFamily: "Kalinga" }}
      >
        Notification Letter
      </div>
      <div
        className="card m-2 shadow"
        style={{
          backgroundColor: "#e0e0e0",
          backgroundImage: `url(${white})`,
          backgroundSize: "cover",
        }}
      >
        <div className="row p-2">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw" }}
                >
                  CRO
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <input
                  className="form-control"
                  disabled
                  type="text"
                  defaultValue={data && data.lead_name}
                ></input>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw"  }}
                >
                  Team Lead
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 ms-lg-3 ms-md-3">
                <input
                  className="form-control"
                  disabled
                  type="text"
                  defaultValue={data && data.manager_name}
                ></input>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw"  }}
                >
                  {" "}
                  Analyst{" "}
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <input
                  className="form-control"
                  disabled
                  type="text"
                  defaultValue={data && data.username}
                ></input>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw"  }}
                >
                  {" "}
                  Opinion{" "}
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 ms-lg-3 ms-md-3">
                <select className="form-select" value={`${client_id},${og_rating_id}`} onChange={(e)=>{
                  const val = e.target.value;
                  const arr = val.split(",");
                  const client_id = arr[0];
                  const og_rating_id = arr[1];
                  const encryptedParams = encryptParams({client_id, og_rating_id});
                  Navigate(`/Ratings/addTask/NL/${encryptedParams}`);
                  setchangeopinion(!changeopinion);
                }}>
                  {
                    opinion.map((value, index)=>{
                      return(
                        <option value={`${value.Id},${value.record_id}`}>{value.Entity}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw"  }}
                >
                  {" "}
                  Rating Update Type{" "}
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <input
                  className="form-control"
                  disabled
                  type="text"
                  defaultValue={data && data.ratingUpdateType_title}
                ></input>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw"  }}
                >
                  Process
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 ms-lg-3 ms-md-3">
              <select className="form-select" defaultValue={processpage} onChange={(e)=>{
                const encryptedParams = encryptParams({client_id, og_rating_id})
                Navigate(`/Ratings/addTask/${e.target.value}/${encryptedParams}`);
              }}>
                <option value={"Information"}>Information</option>
                <option value={"Assessment"}>Assessment</option>
                <option value={"SiteVisit"}>Site Visit</option>
                <option value={"MM"}>MM</option>
                <option value={"RC"}>RC</option>
                <option value={"PPL"}>PPL</option>
                <option value={"NL"}>NL</option>
                <option value={"RR"}>RR</option>
                <option value={"RED"}>RED</option>
              </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isadmin == 'true' &&
      <div className="text-center">
            <b>Record id:</b> {og_rating_id} <b>Opinion id:</b> {client_id}
      </div>}
      <div className="mx-auto mt-2 mb-2" style={{width: "fit-content"}} onClick={()=>{setbool(!bool);}}>
        <i class="fa fa-refresh fa-2x" aria-hidden="true"></i>
      </div>
      {(reopen == 'Open' && checktask.includes('NL') == true) ?
        <div>
          {ppldata !== null ? <> 
          <div className="card m-2 shadow p-2">
            {ppldata !== null ? ppldata.financialsFile[0].ppl_date == null && <Box className="mx-auto" sx={{ width: "98%" }}>
                        <Collapse in={true}>
                          <Alert
                            severity={'error'}
                            sx={{ mb: 2 }}
                          >
                            {'* PPL approval is required for NL'}
                          </Alert>
                        </Collapse>
              </Box> : null}
            {ppldata !== null ? ppldata.bool3 == true && ppldata.financialsFile[0].notification_date== null &&
                <Box className="mx-auto" sx={{ width: "98%" }}>
                        <Collapse in={true}>
                          <Alert
                            severity={'error'}
                            sx={{ mb: 2 }}
                          >
                            {<>
                            {ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 ? <>Final Rating Report is not updated. Please press <b class="blink_text">Generate Report</b></> : 
                            ppldata.financialsFile[0].summary_report == null || ppldata.financialsFile[0].summary_report == "" ?
                            <> Rating Report is not uploaded. Upload Rating Report from RR section. </> : <> Rating Report that you have uploaded is not updated. You're recommended to Upload Rating Report from RR section to update it. </>}
                            </>}
                          </Alert>
                        </Collapse>
              </Box> : null}
              <Box className="mx-auto mt-1 mb-1" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                            severity={alertType}
                            action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setalertopen(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            sx={{ mb: 2 }}
                          >
                            {AlertMessage}
                        </Alert>
                    </Collapse>
              </Box>
            <div className="row">
              {
                ppldata !== null ?
                ppldata.financialsFile.length > 0 ?
                ppldata.financialsFile[0].ppl_date !== null ?
                alredyGenenatednl.length == 0 ?
                <>
                <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary" type="button" onClick={()=>{Navigate(`/Ratings/generate_nl/${client_id}/${og_rating_id}`)}}>Generate NL</button></div> 
                </>
                :
                alredyGenenatednl[0].second_approve !== "Approved" ? 
                <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary" type="button" onClick={()=>{Navigate(`/Ratings/edit_nl/${client_id}/${og_rating_id}`)}}>Edit NL</button> </div> : null : null : null : null
              }
              {
                ppldata !== null ? alredyGenenatednl.length > 0 ? ppldata.bool == true ? 
                <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary" type="button" onClick={()=>{window.open(`/Ratings/nlview/${og_rating_id}`)}}>NL</button> </div> : 
                <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary" type="button" onClick={()=>{window.open(`https://209.97.168.200/wizpacv1/w_q/nl/view_final_V2.php?id=${client_id}&record_id=${og_rating_id}`, '_blank')}}>NL</button> </div> : null : null
              }
              {
                ppldata !== null ? ppldata.opDetails.length > 0 ?
                ppldata.opDetails[0].rating_scale_id != 7 ? 
                <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary" type="button" onClick={()=>{window.open(`${url}/api/finalPr/${og_rating_id}`, '_blank')}}>Final PR</button> </div> : null : null : null
              }
              {
                ppldata !== null ? ppldata.opDetails.length > 0 ?
                ppldata.opDetails[0].rating_scale_id == 7 ? 
                <> <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{window.open(`${url}/api/finalPr/${og_rating_id}`, '_blank')}}>Final PR</button> </div>
                {ppldata.bool2 !== false ? 
                <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{window.open(`https://209.97.168.200/wizpacv1/rm/${ppldata.financialsFile[0].summary_report}`)}}>Final RR</button> </div> :
                <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{window.open(`https://209.97.168.200/pacrawizpackv3/storage/app/${ppldata.financialsFile[0].summary_report}`)}}>Final RR</button> </div> 
                }
                <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdropRR">Upload RR</button> </div> </> : null : null : null
              }
              {
                ppldata !== null ? alredyGenenatednl.length > 0 ? alredyGenenatednl[0].second_approve !== "Approved" ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{generateReport()}}><div id="gn">Generate Report</div> <div id="generateprogress" hidden>Please Wait...</div></button> </div> : null
                : ppldata.opDetails[0].rating_scale_id != 7 ? 
                <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{generateReport()}}><div id="gn">Generate Report</div> <div id="generateprogress" hidden>Please Wait...</div></button> </div> : null : null
              }
              {
              ppldata !== null ? alredyGenenatednl.length > 0 ? alredyGenenatednl[0].second_approve == "Approved" ?
              ppldata.bool2 !== false ? <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{window.open(`https://209.97.168.200/wizpacv1/rm/${ppldata.financialsFile[0].summary_report}`)}}>Final RR</button></div> :
              <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{window.open(`https://209.97.168.200/pacrawizpackv3/storage/app/${ppldata.financialsFile[0].summary_report}`)}}>Final RR</button> </div> : null :null : null
              }

            </div>
          </div>
          <div className="modal fade mt-5" id="staticBackdropRR" tabIndex="-1" aria-labelledby="staticBackdrop" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">Upload RR</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <Box className="mx-auto mb-1" sx={{ width: "98%" }}>
                        <Collapse in={alertopen2}>
                            <Alert
                                severity={alertType}
                                action={
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setalertopen2(false);
                                      }}
                                    >
                                      <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                  }
                                sx={{ mb: 2 }}
                              >
                                {AlertMessage}
                            </Alert>
                        </Collapse>
                  </Box>
                  <form onSubmit={uploadRRreport}>
                    <div className="col-10 mx-auto">
                      <input className="form-control" type="file" onChange={(e)=>{
                        const file = e.target.files[0];
                        setRR_files(file);
                      }} required />
                    </div>
                    <div className="mt-3 mx-auto" style={{width: "fit-content"}}>
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade mt-5" id="staticBackdropPR" tabIndex="-1" aria-labelledby="staticBackdrop" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">Upload PR</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <Box className="mx-auto mb-1" sx={{ width: "98%" }}>
                        <Collapse in={alertopen2}>
                            <Alert
                                severity={alertType}
                                action={
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setalertopen2(false);
                                      }}
                                    >
                                      <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                  }
                                sx={{ mb: 2 }}
                              >
                                {AlertMessage}
                            </Alert>
                        </Collapse>
                  </Box>
                  <form onSubmit={uploadPRreport}>
                    <div className="col-10 mx-auto">
                      <input className="form-control" type="file" onChange={(e)=>{
                        const file = e.target.files[0];
                        setPR_files(file);
                      }} required />
                    </div>
                    <div className="mt-3 mx-auto" style={{width: "fit-content"}}>
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div> </> :
          <div className="card m-2 shadow p-2"><div className="mx-auto"> <CircularProgress /> </div> </div>
          }
        </div> :
        reopen == null ?
        <div>
          {ppldata !== null ? <> 
          <div className="card m-2 shadow p-2">
            {ppldata !== null ? ppldata.financialsFile[0].ppl_date == null && <Box className="mx-auto" sx={{ width: "98%" }}>
                        <Collapse in={true}>
                          <Alert
                            severity={'error'}
                            sx={{ mb: 2 }}
                          >
                            {'* PPL approval is required for NL'}
                          </Alert>
                        </Collapse>
              </Box> : null}
            {ppldata !== null ? ppldata.bool3 == true && ppldata.financialsFile[0].notification_date== null &&
                <Box className="mx-auto" sx={{ width: "98%" }}>
                        <Collapse in={true}>
                          <Alert
                            severity={'error'}
                            sx={{ mb: 2 }}
                          >
                            {<>
                            {ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 ? <>Final Rating Report is not updated. Please press <b class="blink_text">Generate Report</b></> : 
                            ppldata.financialsFile[0].summary_report == null || ppldata.financialsFile[0].summary_report == "" ?
                            <> Rating Report is not uploaded. Upload Rating Report from RR section. </> : <> Rating Report that you have uploaded is not updated. You're recommended to Upload Rating Report from RR section to update it. </>}
                            </>}
                          </Alert>
                        </Collapse>
              </Box> : null}
              <Box className="mx-auto mt-1 mb-1" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                            severity={alertType}
                            action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setalertopen(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            sx={{ mb: 2 }}
                          >
                            {AlertMessage}
                        </Alert>
                    </Collapse>
              </Box>
            <div className="row">
              {
                ppldata !== null ?
                ppldata.financialsFile.length > 0 ?
                ppldata.financialsFile[0].ppl_date !== null ?
                alredyGenenatednl.length == 0 ?
                <>
                <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary" type="button" onClick={()=>{Navigate(`/Ratings/generate_nl/${client_id}/${og_rating_id}`)}}>Generate NL</button></div> 
                </>
                :
                alredyGenenatednl[0].second_approve !== "Approved" ? 
                <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary" type="button" onClick={()=>{Navigate(`/Ratings/edit_nl/${client_id}/${og_rating_id}`)}}>Edit NL</button> </div> : null : null : null : null
              }
              {
                ppldata !== null ? alredyGenenatednl.length > 0 ? ppldata.bool == true ? 
                <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary" type="button" onClick={()=>{window.open(`/Ratings/nlview/${og_rating_id}`)}}>NL</button> </div> : 
                <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary" type="button" onClick={()=>{window.open(`https://209.97.168.200/wizpacv1/w_q/nl/view_final_V2.php?id=${client_id}&record_id=${og_rating_id}`, '_blank')}}>NL</button> </div> : null : null
              }
              {
                ppldata !== null ? ppldata.opDetails.length > 0 ?
                ppldata.opDetails[0].rating_scale_id != 7 ? 
                <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary" type="button" onClick={()=>{window.open(`${url}/api/finalPr/${og_rating_id}`, '_blank')}}>Final PR</button> </div> : null : null : null
              }
              {
                ppldata !== null ? ppldata.opDetails.length > 0 ?
                ppldata.opDetails[0].rating_scale_id == 7 ? 
                <> <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{window.open(`${url}/api/finalPr/${og_rating_id}`, '_blank')}}>Final PR</button> </div>
                {ppldata.bool2 !== false ? 
                <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{window.open(`https://209.97.168.200/wizpacv1/rm/${ppldata.financialsFile[0].summary_report}`)}}>Final RR</button> </div> :
                <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{window.open(`https://209.97.168.200/pacrawizpackv3/storage/app/${ppldata.financialsFile[0].summary_report}`)}}>Final RR</button> </div> 
                }
                <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdropRR">Upload RR</button> </div> </> : null : null : null
              }
              {
                ppldata !== null ? alredyGenenatednl.length > 0 ? alredyGenenatednl[0].second_approve !== "Approved" ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{generateReport()}}><div id="gn">Generate Report</div> <div id="generateprogress" hidden>Please Wait...</div></button> </div> : null
                : ppldata.opDetails[0].rating_scale_id != 7 ? 
                <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{generateReport()}}><div id="gn">Generate Report</div> <div id="generateprogress" hidden>Please Wait...</div></button> </div> : null : null
              }
              {
              ppldata !== null ? alredyGenenatednl.length > 0 ? alredyGenenatednl[0].second_approve == "Approved" ?
              ppldata.bool2 !== false ? <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{window.open(`https://209.97.168.200/wizpacv1/rm/${ppldata.financialsFile[0].summary_report}`)}}>Final RR</button></div> :
              <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{window.open(`https://209.97.168.200/pacrawizpackv3/storage/app/${ppldata.financialsFile[0].summary_report}`)}}>Final RR</button> </div> : null :null : null
              }

            </div>
          </div>
          <div className="modal fade mt-5" id="staticBackdropRR" tabIndex="-1" aria-labelledby="staticBackdrop" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">Upload RR</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <Box className="mx-auto mb-1" sx={{ width: "98%" }}>
                        <Collapse in={alertopen2}>
                            <Alert
                                severity={alertType}
                                action={
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setalertopen2(false);
                                      }}
                                    >
                                      <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                  }
                                sx={{ mb: 2 }}
                              >
                                {AlertMessage}
                            </Alert>
                        </Collapse>
                  </Box>
                  <form onSubmit={uploadRRreport}>
                    <div className="col-10 mx-auto">
                      <input className="form-control" type="file" onChange={(e)=>{
                        const file = e.target.files[0];
                        setRR_files(file);
                      }} required />
                    </div>
                    <div className="mt-3 mx-auto" style={{width: "fit-content"}}>
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade mt-5" id="staticBackdropPR" tabIndex="-1" aria-labelledby="staticBackdrop" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">Upload PR</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <Box className="mx-auto mb-1" sx={{ width: "98%" }}>
                        <Collapse in={alertopen2}>
                            <Alert
                                severity={alertType}
                                action={
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setalertopen2(false);
                                      }}
                                    >
                                      <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                  }
                                sx={{ mb: 2 }}
                              >
                                {AlertMessage}
                            </Alert>
                        </Collapse>
                  </Box>
                  <form onSubmit={uploadPRreport}>
                    <div className="col-10 mx-auto">
                      <input className="form-control" type="file" onChange={(e)=>{
                        const file = e.target.files[0];
                        setPR_files(file);
                      }} required />
                    </div>
                    <div className="mt-3 mx-auto" style={{width: "fit-content"}}>
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div> </> :
          <div className="card m-2 shadow p-2"><div className="mx-auto"> <CircularProgress /> </div> </div>
          }
        </div> :
        (reopen == 'Open' && checktask.includes('NL') == false) ?
          <div className="mt-3 fw-4 text-center"> Not allowed to Edit after File Closure</div> :
          <div>
          {ppldata !== null ? <> 
          <div className="card m-2 shadow p-2">
            {ppldata !== null ? ppldata.financialsFile[0].ppl_date == null && <Box className="mx-auto" sx={{ width: "98%" }}>
                        <Collapse in={true}>
                          <Alert
                            severity={'error'}
                            sx={{ mb: 2 }}
                          >
                            {'* PPL approval is required for NL'}
                          </Alert>
                        </Collapse>
              </Box> : null}
            {ppldata !== null ? ppldata.bool3 == true && ppldata.financialsFile[0].notification_date== null &&
                <Box className="mx-auto" sx={{ width: "98%" }}>
                        <Collapse in={true}>
                          <Alert
                            severity={'error'}
                            sx={{ mb: 2 }}
                          >
                            {<>
                            {ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 ? <>Final Rating Report is not updated. Please press <b class="blink_text">Generate Report</b></> : 
                            ppldata.financialsFile[0].summary_report == null || ppldata.financialsFile[0].summary_report == "" ?
                            <> Rating Report is not uploaded. Upload Rating Report from RR section. </> : <> Rating Report that you have uploaded is not updated. You're recommended to Upload Rating Report from RR section to update it. </>}
                            </>}
                          </Alert>
                        </Collapse>
              </Box> : null}
              <Box className="mx-auto mt-1 mb-1" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                            severity={alertType}
                            action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setalertopen(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            sx={{ mb: 2 }}
                          >
                            {AlertMessage}
                        </Alert>
                    </Collapse>
              </Box>
            <div className="row">
              {
                ppldata !== null ?
                ppldata.financialsFile.length > 0 ?
                ppldata.financialsFile[0].ppl_date !== null ?
                alredyGenenatednl.length == 0 ?
                <>
                <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary" type="button" onClick={()=>{Navigate(`/Ratings/generate_nl/${client_id}/${og_rating_id}`)}}>Generate NL</button></div> 
                </>
                :
                alredyGenenatednl[0].second_approve !== "Approved" ? 
                <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary" type="button" onClick={()=>{Navigate(`/Ratings/edit_nl/${client_id}/${og_rating_id}`)}}>Edit NL</button> </div> : null : null : null : null
              }
              {
                ppldata !== null ? alredyGenenatednl.length > 0 ? ppldata.bool == true ? 
                <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary" type="button" onClick={()=>{window.open(`/Ratings/nlview/${og_rating_id}`)}}>NL</button> </div> : 
                <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary" type="button" onClick={()=>{window.open(`https://209.97.168.200/wizpacv1/w_q/nl/view_final_V2.php?id=${client_id}&record_id=${og_rating_id}`, '_blank')}}>NL</button> </div> : null : null
              }
              {
                ppldata !== null ? ppldata.opDetails.length > 0 ?
                ppldata.opDetails[0].rating_scale_id != 7 ? 
                <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary" type="button" onClick={()=>{window.open(`${url}/api/finalPr/${og_rating_id}`, '_blank')}}>Final PR</button> </div> : null : null : null
              }
              {
                ppldata !== null ? ppldata.opDetails.length > 0 ?
                ppldata.opDetails[0].rating_scale_id == 7 ? 
                <> <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{window.open(`${url}/api/finalPr/${og_rating_id}`, '_blank')}}>Final PR</button> </div>
                {ppldata.bool2 !== false ? 
                <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{window.open(`https://209.97.168.200/wizpacv1/rm/${ppldata.financialsFile[0].summary_report}`)}}>Final RR</button> </div> :
                <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{window.open(`https://209.97.168.200/pacrawizpackv3/storage/app/${ppldata.financialsFile[0].summary_report}`)}}>Final RR</button> </div> 
                }
                <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdropRR">Upload RR</button> </div> </> : null : null : null
              }
              {
                ppldata !== null ? alredyGenenatednl.length > 0 ? alredyGenenatednl[0].second_approve !== "Approved" ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{generateReport()}}><div id="gn">Generate Report</div> <div id="generateprogress" hidden>Please Wait...</div></button> </div> : null
                : ppldata.opDetails[0].rating_scale_id != 7 ? 
                <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{generateReport()}}><div id="gn">Generate Report</div> <div id="generateprogress" hidden>Please Wait...</div></button> </div> : null : null
              }
              {
              ppldata !== null ? alredyGenenatednl.length > 0 ? alredyGenenatednl[0].second_approve == "Approved" ?
              ppldata.bool2 !== false ? <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{window.open(`https://209.97.168.200/wizpacv1/rm/${ppldata.financialsFile[0].summary_report}`)}}>Final RR</button></div> :
              <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary" type="button" onClick={()=>{window.open(`https://209.97.168.200/pacrawizpackv3/storage/app/${ppldata.financialsFile[0].summary_report}`)}}>Final RR</button> </div> : null :null : null
              }

            </div>
          </div>
          <div className="modal fade mt-5" id="staticBackdropRR" tabIndex="-1" aria-labelledby="staticBackdrop" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">Upload RR</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <Box className="mx-auto mb-1" sx={{ width: "98%" }}>
                        <Collapse in={alertopen2}>
                            <Alert
                                severity={alertType}
                                action={
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setalertopen2(false);
                                      }}
                                    >
                                      <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                  }
                                sx={{ mb: 2 }}
                              >
                                {AlertMessage}
                            </Alert>
                        </Collapse>
                  </Box>
                  <form onSubmit={uploadRRreport}>
                    <div className="col-10 mx-auto">
                      <input className="form-control" type="file" onChange={(e)=>{
                        const file = e.target.files[0];
                        setRR_files(file);
                      }} required />
                    </div>
                    <div className="mt-3 mx-auto" style={{width: "fit-content"}}>
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade mt-5" id="staticBackdropPR" tabIndex="-1" aria-labelledby="staticBackdrop" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">Upload PR</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <Box className="mx-auto mb-1" sx={{ width: "98%" }}>
                        <Collapse in={alertopen2}>
                            <Alert
                                severity={alertType}
                                action={
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setalertopen2(false);
                                      }}
                                    >
                                      <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                  }
                                sx={{ mb: 2 }}
                              >
                                {AlertMessage}
                            </Alert>
                        </Collapse>
                  </Box>
                  <form onSubmit={uploadPRreport}>
                    <div className="col-10 mx-auto">
                      <input className="form-control" type="file" onChange={(e)=>{
                        const file = e.target.files[0];
                        setPR_files(file);
                      }} required />
                    </div>
                    <div className="mt-3 mx-auto" style={{width: "fit-content"}}>
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div> </> :
          <div className="card m-2 shadow p-2"><div className="mx-auto"> <CircularProgress /> </div> </div>
          }
          </div>
      }

     
    </div>
  );
}
