import React, {useEffect, useState} from 'react'
import PaginationReport from './paginationReport';
import StandardReport from './standardReport';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from "react-router-dom";
import white from '../../../../Assets/Images/whitebg.jpg'
import "../../../../index.css"
import CircularProgress from "@mui/material/CircularProgress";
import { url } from '../../../../Config';
import { decryptParams } from '../../encrypt_url';
import { useLocation } from 'react-router-dom';
import { encryptParams } from '../../encrypt_url';


export default function AssessmentReport(props) {
    const location = useLocation();
    const pathname = location.pathname;
    const encryptedParams = pathname.substring('/Ratings/addTask/modelview/'.length);
    const { model_id, clientid, og_rating, rating_scale } = decryptParams(encryptedParams);
    // const { model_id, client_id, og_rating_id } = useParams();
    const Navigate = useNavigate();
    const id = localStorage.getItem("userID");
    const adminid = localStorage.getItem("admin_userID");
    const [data, setdata] = useState([]);
    const [name, setname] = useState();
    const [init_date, setinit_date] = useState(null);




    useEffect(()=>{
      const func = async ()=>{
        const res = await axios.get(`${url}/api/getAssessmentIndex/${model_id}/${og_rating}`);
        const resp = res.data;
        if(resp.message == "Assessment Already done"){
          const encryptedParams = encryptParams({model_id, clientid, og_rating, rating_scale});
          Navigate(`/Ratings/addTask/edit/modelview/${encryptedParams}`);
        }
        else{
          setdata(resp.headings);
          setname(resp.name);
          setinit_date(resp.initiation_date);
        }
      }
      func();
    },[])

    const save = async ()=> {
      document.getElementById('load').hidden = false;
      document.getElementById('generate').hidden = true;
      document.getElementById('genebtn').disabled = true;
      const obj = {};
      for(let i in data){
        for(let j in data[i].subheadings){
            if(data[i].subheadings[j].name !== "na" && data[i].subheadings[j].name !== "NA"){
              const originalString = `${data[i].subheadings[j].name}`;
              const replacedString = originalString.replace(/\s+/g, '_');
              obj[replacedString] = document.getElementById(`${data[i].subheadings[j].name}`).value
              obj[`${replacedString}new`] = {};
              if(rating_scale == 'Entity'){
                obj[`${replacedString}new`].text = document.getElementById(`${data[i].subheadings[j].name}`).value;
                if(document.getElementById(`image${data[i].subheadings[j].name}`) !== null){
                  obj[`${replacedString}new`].image = document.getElementById(`image${data[i].subheadings[j].name}`).files[0];
                }
                else{
                  obj[`${replacedString}new`].image = null;
                }
                if(document.getElementById(`imagetext${data[i].subheadings[j].name}`) !== null){
                  obj[`${replacedString}new`].image_desc = document.getElementById(`imagetext${data[i].subheadings[j].name}`).value;
                }
                else{
                  obj[`${replacedString}new`].image_desc = null;
                }
              }
              else{
                obj[`${replacedString}new`].text = document.getElementById(`${data[i].subheadings[j].name}`).value;
                obj[`${replacedString}new`].image = null;
                obj[`${replacedString}new`].image_desc = null;
              }
            }
            else{
              const originalString1 = `${data[i].name}`;
              const replacedString1 = originalString1.replace(/\s+/g, '_');
              const originalString2 = `${data[i].subheadings[j].name}`;
              const replacedString2 = originalString2.replace(/\s+/g, '_');
              obj[`${replacedString2}_${replacedString1}`] = document.getElementById(`${data[i].subheadings[j].name}_${data[i].name}`).value
              obj[`${replacedString2}_${replacedString1}new`] = {};
              if(rating_scale == 'Entity'){
                obj[`${replacedString2}_${replacedString1}new`].text = document.getElementById(`${data[i].subheadings[j].name}_${data[i].name}`).value
                if(document.getElementById(`image${data[i].subheadings[j].name}_${data[i].name}`) !== null){
                  obj[`${replacedString2}_${replacedString1}new`].image = document.getElementById(`image${data[i].subheadings[j].name}_${data[i].name}`).files[0];
                }
                else{
                  obj[`${replacedString2}_${replacedString1}new`].image = null;
                }

                if(document.getElementById(`imagetext${data[i].subheadings[j].name}_${data[i].name}`) !== null){
                  obj[`${replacedString2}_${replacedString1}new`].image_desc = document.getElementById(`imagetext${data[i].subheadings[j].name}_${data[i].name}`).value;
                }
                else{
                  obj[`${replacedString2}_${replacedString1}new`].image_desc = null;
                }
                
              }
              else{
                obj[`${replacedString2}_${replacedString1}new`].text = document.getElementById(`${data[i].subheadings[j].name}_${data[i].name}`).value
                obj[`${replacedString2}_${replacedString1}new`].image = null;
                obj[`${replacedString2}_${replacedString1}new`].image_desc = null;
              }
            }
        }
      }
      obj["model_id"] = model_id;
      obj["opinion_id"] = clientid;
      obj["record_id"] = og_rating;
      obj["user_id"] = id;
      obj["modelnumber"] = model_id;
      obj["adminid"] = adminid;



      try {
        const response = await axios.post(`${url}/api/generateassessmentpdf`, obj,{
          headers: {
          "Content-Type": "multipart/form-data",
          },
          responseType: 'blob',

      });
      // console.log(response.data);
      // return;
        if(response.status == 200){
          if(rating_scale == 'Entity' && adminid == "341"){
            document.getElementById('load').hidden = true;
            document.getElementById('generate').hidden = false;
            const encryptedParams = encryptParams({model_id, clientid, og_rating, rating_scale});
  
            window.open(`${url}/api/view_assessment/${model_id}/${clientid}/${og_rating}`)
            Navigate(`/Ratings/addTask/edit/modelview/${encryptedParams}`);
  
          }
          else{
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(pdfBlob);
        
            const a = document.createElement('a');
            a.href = pdfUrl;
            a.target = '_blank';
            a.click();
        
            URL.revokeObjectURL(pdfUrl);
            document.getElementById('load').hidden = true;
            document.getElementById('generate').hidden = false;
            const encryptedParams = encryptParams({model_id, clientid, og_rating, rating_scale});
  
            Navigate(`/Ratings/addTask/edit/modelview/${encryptedParams}`);
          }
        }
        else{
          const encryptedParams = encryptParams({model_id, clientid, og_rating, rating_scale});
          Navigate(`/Ratings/addTask/edit/modelview/${encryptedParams}`);
        }
         
          
          
      } catch (error) {
        document.getElementById('load').hidden = true;
        document.getElementById('generate').hidden = false;
        document.getElementById('genebtn').disabled = false;
        console.error('Error fetching the data:', error);
      }

    }

  return (
    <div>
      <div className='fs-4 fw-bold text-center mt-2' style={{color:"#000078", fontFamily: "Kalinga"}}>
      Format: {name}
      </div> <br/>
      <div className=''>
        {
          data.map((outervalue, outerindex)=>{
            return(
              <div className='card shadow col-11 mx-auto p-3 mb-2' style={{backgroundColor: "#e0e0e0", backgroundImage: `url(${white})`, backgroundSize: "cover", fontFamily: "Kalinga"}}>
                <div className='ms-1 fs-5 mb-3'> <b>{outervalue.name}</b></div>
                  {
                    outervalue.subheadings.map((value, index)=>{
                      return(
                        <>
                        {value.name !== "na" && value.name !== "NA" ? <div className=' ms-1 fw-bold'>{value.name}</div> : null}
                        {value.desc !== null && <div className='ms-1 me-1'>{value.desc}</div>}
                        <div className='ms-1 me-1'> { value.name !== "na" && value.name !== "NA" ? <textarea className='form-control' id={`${value.name}`} rows={3}></textarea> : <textarea className='form-control' id={`${value.name}_${outervalue.name}`} rows={3}></textarea>}</div>
                        {(rating_scale == "Entity" && adminid == "341") && <div className='ms-1 me-1'> { value.name !== "na" && value.name !== "NA" ? <input className='form-control' type='file' id={`image${value.name}`} rows={3} accept="image/*"></input> : <input className='form-control' type='file' id={`image${value.name}_${outervalue.name}`} accept="image/*"></input>}</div>}
                        {(rating_scale == "Entity" && adminid == "341") && <div className='ms-1 me-1'> { value.name !== "na" && value.name !== "NA" ?
                         <>Image Discription: <input className='form-control' id={`imagetext${value.name}`} /></> : <>Image Discription: <input className='form-control' id={`imagetext${value.name}_${outervalue.name}`} /></>}</div>}
                        <br/>
                        </>
                      );
                    })
                  }
              </div>
            );
          })
        }
        <div className='mx-auto mt-2' style={{width: "fit-content"}}>
          <button className='button btn btn-success' id='genebtn' onClick={()=>{save()}}><div id='generate'>Generate Report</div><div id='load' hidden>Please Wait...</div></button>
        </div>
        <br/>
        </div>
      
    </div>
  )
}
