import React, {useEffect, useState, useRef} from 'react'
import PaginationReport from './paginationReport';
import StandardReport from './standardReport';
import axios from 'axios';
import { useParams } from "react-router-dom";
import white from '../../../../Assets/Images/whitebg.jpg'
import "../../../../index.css"
import CircularProgress from "@mui/material/CircularProgress";
import { url } from '../../../../Config';
import { decryptParams } from '../../encrypt_url';
import { useLocation } from 'react-router-dom';
import { encryptParams } from '../../encrypt_url';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from "sweetalert2";



export default function Editmodelview() {

    const location = useLocation();
    const pathname = location.pathname;
    const encryptedParams = pathname.substring('/Ratings/addTask/edit/modelview/'.length);
    const { model_id, clientid, og_rating, rating_scale } = decryptParams(encryptedParams);
    // const { model_id, client_id, og_rating_id } = useParams();
    const id = localStorage.getItem("userID");
    const adminid = localStorage.getItem("admin_userID");
    const [data, setdata] = useState([]);
    const [name, setname] = useState();
    const [bool, setbool] = useState(false);
    const [init_date, setinit_date] = useState(null);
    const btn = useRef();


    useEffect(()=>{
        setdata([]);
        document.getElementById('load').hidden = false;
        document.getElementById('generate').hidden = true;
        document.getElementById('btnupdate').disabled = true;
        const func = async ()=>{
          const res = await axios.get(`${url}/api/editassessmentindex/${model_id}/${clientid}/${og_rating}`);
          const resp = res.data;
          setdata(resp.headings);
          setname(resp.name);
          setinit_date(resp.initiation_date);
          if(btn.current){
            btn.current.disabled = false;
          }
          document.getElementById('load').hidden = true;
          document.getElementById('generate').hidden = false;
          document.getElementById('btnupdate').disabled = false;
          
        }

        func();
      },[bool])

      const update = async ()=> {
        document.getElementById('load').hidden = false;
        document.getElementById('generate').hidden = true;
        document.getElementById('btnupdate').disabled = true;
        const obj = {};
        const arr = [];
        for(let i in data){
          
          for(let j in data[i].subheading_data){
                const originalString = `${data[i].subheading_data[j].sub_heading_name}`;
                const replacedString = originalString.replace(/\s+/g, '_');  
                obj[replacedString] = document.getElementById(`${data[i].subheading_data[j].sub_heading_name}`).value
                obj[`${replacedString}new`] = {};

                if(rating_scale == 'Entity'){
                  obj[`${replacedString}new`].text = document.getElementById(`${data[i].subheading_data[j].sub_heading_name}`).value;

                  if(document.getElementById(`image${data[i].subheading_data[j].id}`) !== null){
                    obj[`${replacedString}new`].image = document.getElementById(`image${data[i].subheading_data[j].id}`).files[0];
                  }
                  else{
                    obj[`${replacedString}new`].image = null;
                  }
                  if(document.getElementById(`imagetext${data[i].subheading_data[j].id}`) !== null){
                    obj[`${replacedString}new`].image_desc = document.getElementById(`imagetext${data[i].subheading_data[j].id}`).value;
                  }
                  else{
                    obj[`${replacedString}new`].image_desc = null;
                  }
                }
                else{
                  obj[`${replacedString}new`].text = document.getElementById(`${data[i].subheading_data[j].sub_heading_name}`).value;
                  obj[`${replacedString}new`].image = null;
                  obj[`${replacedString}new`].image_desc = null;

                }
          }
        }


        obj["model_id"] = model_id;
        obj["opinion_id"] = clientid;
        obj["record_id"] = og_rating;
        obj["user_id"] = id;
        obj["modelnumber"] = model_id;
        obj["adminid"] = adminid;
        
  
        try {
          const response = await axios.post(`${url}/api/updateassessment`, obj, {
            headers: {
              "Content-Type": "multipart/form-data",
              },
              // responseType: 'blob',
          });
        

            if(response.status == 200){
              setbool(!bool);
              if(rating_scale == 'Entity' && adminid == "341"){
                window.open(`${url}/api/view_assessment/${model_id}/${clientid}/${og_rating}`);
                document.getElementById('load').hidden = true;
                document.getElementById('generate').hidden = false;
                document.getElementById('btnupdate').disabled = false;
              }
              else{
                const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
            
                const a = document.createElement('a');
                a.href = pdfUrl;
                a.target = '_blank';
                a.click();
            
                URL.revokeObjectURL(pdfUrl);
                document.getElementById('load').hidden = true;
                document.getElementById('generate').hidden = false;
                document.getElementById('btnupdate').disabled = false;
              }

            }
            else{
              document.getElementById('load').hidden = true;
              document.getElementById('generate').hidden = false;
              document.getElementById('btnupdate').disabled = false;

            }            
            
        } catch (error) {
          document.getElementById('btnupdate').disabled = false;
          document.getElementById('load').hidden = true;
          document.getElementById('generate').hidden = false;
          console.error('Error fetching the data:', error);

        }
  
      }

      const deletefunc = async (image_id)=>{

        Swal.fire({ title: 'Do you want to delete this image?', 
          showDenyButton: false, showCancelButton: true, confirmButtonText: 'Delete'})
          .then(async(result) => {
            if (result.isConfirmed) {
              const res = await axios.post(`${url}/api/delete_assessment_img`,{
                'id': image_id
              });
              const resp = res.data;
              if(resp.status == true){
                setbool(!bool);
                alert('Successfully Deleted');
              }
              else{
                alert(resp.message);
              }
            }
          })

      }

  return (
    <div style={{height: "90vh"}}>
            <div className='fs-4 fw-bold text-center mt-2' style={{color:"#000078", fontFamily: "Kalinga"}}>
      Format: {name}
      </div> <br/>
      <div className=''>
        {
          data.map((outervalue, outerindex)=>{
            return(
              <>
                <div className='col-11 mb-2 card shadow p-3 mx-auto' style={{backgroundColor: "#e0e0e0", backgroundImage: `url(${white})`, backgroundSize: "cover", fontFamily: "Kalinga"}}>
                  <div className='ms-1 fs-5 mb-3'> <b>{outervalue.name}</b></div>
                {
                  outervalue.subheading_data.map((value, index)=>{
                    return(
                      <>
                      <div className='ms-1 fw-bold'>{value.sub_heading_name}</div>
                      <div className='ms-1 me-1'> 
                        <textarea className='form-control' id={`${value.sub_heading_name}`}>{value.data}</textarea>
                      </div>
                      {(rating_scale == "Entity" && adminid == "341") &&
                      <div className='col-6 mt-1 ms-1'> 
                        <input className='form-control' type='file' id={`image${value.id}`} rows={3} accept="image/*"></input>
                      </div>}
                      {(rating_scale == "Entity" && adminid == "341") && 
                      // <>Uploaded Image: <img src={`${url}/api/getImage/${data.image_path}`} style={{wdith: '50px', height: '50px'}}  />
                      // </>
                      value.image !== null &&
                      <div className='mt-1 ms-1'>Uploaded Image: <a className='me-2' href={`${url}/api/getassessmentimg/${value.image}`} target='_blank'>{value.image}</a> <DeleteIcon style={{color: 'red'}} onClick={()=>{deletefunc(value.image_id)}} />
                      </div>
                      }

                      {(rating_scale == "Entity" && adminid == "341") && 
                        <div className='col-6 mt-1 ms-1'>
                          Image Discription: <input className='form-control' defaultValue={value.description} id={`imagetext${value.id}`} />
                        </div> 
                      }
                      <br/>
                      </>
                    );
                  })
                }
                </div>
              </>
            );
          })
        }
        <div className='mx-auto mt-2' style={{width: "fit-content"}}>
          <button className='button btn btn-success' id='btnupdate' disabled={true} ref={btn}><div id='generate' onClick={()=>{update()}}>Update</div><div id='load'  hidden>Please Wait...</div></button>
        </div>
        <br />
        </div>
    </div>
  )
}
